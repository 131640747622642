import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import { error_api_call, post_api_call } from '../../actions/action-creators/common';
import { alertResponseMessage } from '../../actions/action-creators/response';
import { LoaderMedium } from '../loader';

const PortPublicBtn = (props) => {
    const [isPublic, setIsPublic] = useState(props.isPublic);
    const [isProcess, setIsProcess] = useState(false);

    const updatePortVisibility = async(e, state) => {
        try{
            e.preventDefault();
            var consent = state === false ? window.confirm("Are you sure you want to make your Portfolio Private?") : true;
            if (consent){
                setIsProcess(true);
                const apiData = await post_api_call(`port/action/visibility`, {visibility: state});
                if(apiData.status === 200){
                    setIsPublic(state);
                    alertResponseMessage({alert_type: 1, message: "Request Processed Successfully"});
                    //Return to asked function
                    if(props.updateVisibility){
                        props.updateVisibility(state);
                    }
                }else{
                    setIsPublic(state === true ? false : true);
                }
                setIsProcess(false);
            }
        }catch(error){
            setIsProcess(false);
            console.error(error);
            setIsPublic(state === true ? false : true);
            alertResponseMessage({alert_type: 2, message: "Error Processing your Request! Please try after some times"});
            error_api_call({error: `component/portfolio/button|updatePortVisibility|${e}`});
        }
    }

    return (
        <>
            <div className="d-flex align-items-center">
            {isProcess && (
                <div className="me-2">
                    <LoaderMedium size={20} />
                </div>
            )}
            <span>Private &nbsp;</span>
            <Form.Check
                type="switch"
                id="portfolio-switch"
                // label={isProcess ? 'Processing your Request' : `Make My Portfolio ${isPublic === true ? 'Private' : 'Public'}`}
                className="align-items-center d-flex"
                checked={isPublic}
                onChange={(e) => updatePortVisibility(e, e.target.checked)}
                disabled={isProcess}
            />
            <span>&nbsp; Public</span>
            </div>
            {/* <div className="fs-12p text-black-50 text-center">
                <span>Portfolio</span>
            </div> */}
        </>
    );
  }

export {
    PortPublicBtn
};