import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/team.css';

function TermsCondition(){
    SetMetaData("Terms Of Use", "", "");

    return (
        <main id="main">
        <section className="team-sec pt-0">
            <div className="container-fluid container-lg pe-lg-5 ps-lg-5">
                <div className="section-header section-header-1">
                <h1 className="title">Terms Of Use</h1>
                </div>
                <div className="row justify-content-center team-detail privacy-detail terms-detail m-1">
                    <div className="col-md-10 col-12 col-sm-12 card">
                        <div className="p-2 mt-3">
                            <h2>USER AGREEMENT:</h2>
                            <div className="mb-3">
                                <p>IFSPD.EU and LeadCap Knowledge Solutions Pvt Ltd (hereinafter referred to as “Company”, “us”, “we” or “our”) mission is to enable students to engage in higher order continuous capacity enhancement programs, track and showcase their growth, achievements and skills and gain access to exclusive international avenues. We also enable institutions like schools to track and analyze data of their students who use our Services.</p>
                            </div>
                            <div>
                                <ol>
                                    <li>
                                        <h2>Introduction</h2>
                                        <ol>
                                            <li value={1.1}>
                                                <h3>Contract</h3>
                                                <p>When you use our Services you agree to all of these terms. Your use of our Services is also subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and store your personal information.</p>
                                                <p>By creating a IFSPD.eu/PORT account or accessing or using our Services, you are agreeing to enter into a legally binding contract with the Company (even if you are using third party credentials or using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not create an account or access or otherwise use any of our Services. If you wish to terminate this Contract at any time, you can do so by closing your account and no longer accessing or using our Services.</p>
                                                <h3>Services</h3>
                                                <p>This Contract applies to IFSPD.eu, IFSPD.eu/PORT, IFSPD.eu/HUB, IFSPD.eu/SS, and other IFSPD branded sites, apps, communications and services offered by IFSPD (“Services”), including off-site Services, such as our Global Sustainability Councils (Glosco).</p>
                                                <p>You are entering into this Contract with IFSPD.EU and LeadCap Knowledge Solutions Pvt Ltd (also referred to as “we” and “us”).</p>
                                                <p>As a Visitor or Member of our Services, the collection, use, and sharing of your personal data is subject to our Privacy Policy, and updates. You acknowledge and have read our Privacy Policy.</p>
                                            </li>
                                            <li value={1.2}>
                                                <h3>Members and Visitors</h3>
                                                <p className="mb-0">This Contract applies to Members and Visitors.</p>
                                                <p>When you register and join our Services, you become a “Member”. If you have chosen not to register for our Services, you may access certain features as a “Visitor.</p>
                                            </li>
                                            <li value={1.3}>
                                                <h3>Changes</h3>
                                                <p>We may modify this Contract, our Privacy Policy and our Cookie Policy from time to time. If required by applicable law or we make material changes to this Contract, we will provide you notice through our Services, or by other means. If you object to any of these changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h2>Obligations</h2>
                                        <ol>
                                            <li value={2.1}>
                                                <h3>Service Eligibility</h3>
                                                <p>To use the Services, you agree that you will only have one Port account, which must be in your real name; and you are not already restricted by Ifpsd.eu from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others, except you are registering on behalf of your child or children.</p>
                                            </li>
                                            <li value={2.2}>
                                                <h3>Your Account</h3>
                                                <p>Members are account holders. You agree to: (1) protect against wrongful access to your account (e.g., use a strong password and keep it confidential); (2) not share or transfer your account or any part of it (e.g., sell or transfer the personal data of others by transferring your connections); and (3) follow the law. You are responsible for anything that happens through your account unless you close it or report misuse.</p>
                                                <p>As between you and others (including your school), your account belongs to you. However, if the Services were purchased by another party for you to use (e.g., school), the party paying for such Service has the right to control access to and get reports on your use of such paid Service.</p>
                                            </li>
                                            <li value={2.3}>
                                                <h3>Payment</h3>
                                                <p className="mb-0">You’ll honor your payment obligations and you are okay with us storing your payment information. You understand that there may be fees and taxes that are added to our prices. If you buy any of our paid Services, you agree to pay us the applicable fees and taxes and you agree to the additional terms specific to the paid Services. Failure to pay these fees will result in the termination of your paid Services. Also, you agree that:</p>
                                                <p>Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g., exchange rates).</p>
                                                <p>If you opt for any of the products/services provided on a paid basis, you understand that no money paid to the Company shall be refundable at any point in time even if none or part of the services have been availed.</p>
                                                <p>If due to a Force Majeure Event (as defined hereinbelow), we have to discontinue a certain service that you had availed as a part of your package; we are under no obligation to refund the fee that you had paid for that particular service or provide any additional service or professional support in lieu of the service discontinued. ‘Force Majeure Event’ is an event that is beyond our control, making the performance of our obligations/services inadvisable, commercially impracticable, illegal, or impossible.</p>
                                                <p>We may store and continue billing your payment method (e.g., credit card), even after it has expired, to avoid interruptions in your paid Services and to use it to pay for other Services you may buy. You may update or change your payment method.</p>
                                                <p>If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date. Learn how to cancel or suspend your paid subscription Services.</p>
                                                <p>We may modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.</p>
                                                <p>We may calculate taxes payable by you based on the billing information that you provide us. We may also charge an extra payment gateway or platform fee, based on how you are renewing your subscription.</p>
                                            </li> 
                                            <li value={2.4}>
                                                <h3>Notices and Messages</h3>
                                                <p>You’re okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.</p>
                                                <p>You agree that we will provide notices and messages to you in the following ways: (1) within the Services or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.</p>
                                            </li>
                                            <li value={2.5}>
                                                <h3>Sharing</h3>
                                                <p>Our Services allow sharing of information (including content) in many ways, such as through your profile, posts, articles, group posts, links to news articles, opportunity postings, messages, and channels. Depending on the feature and choices you make, information that you share may be seen by other Members, Visitors, or others (on or off of the Services).</p>
                                                <p>To the extent that laws allow this, we are not obligated to publish any content or other information on our Services and can remove it with or without notice.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h2>Rights and Limits</h2>
                                        <ol>
                                            <li value={3.1}>
                                                <h3>Non-Exclusive License</h3>
                                                <p className="mb-0">You grant the Company and our affiliates the following non-exclusive license to the content and other information you provide (e.g., profile, share, post, upload, and/or otherwise submit) to our Services:</p>
                                                <p>A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publicly perform and display, host, and process your content and other information without any further consent, notice and/or compensation to you or others.</p>
                                                <p>While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type, and removing or adding labels or metadata), we will take steps to avoid materially modifying the meaning of your expression in content you share with others.</p>
                                                <p>You and the Company agree that we may access, store, process, and use any information (including content and/or personal data) that you provide in accordance with the terms of the Privacy Policy.</p>
                                                <p>By submitting suggestions or other feedback regarding our Services, you agree that we can use and share (but does not have to) such feedback for any purpose without compensation to you.</p>
                                                <p>You promise to only provide content and other information that you have the right to share and that your PORT profile will be truthful.</p>
                                                <p>You agree to only provide content and other information that does not violate the law or anyone’s rights (including intellectual property rights). You have choices about how much information to provide on your profile but also agree that the profile information you provide will be truthful. The Company may be required by law to remove certain content and other information in certain countries.</p>
                                            </li>
                                            <li value={3.2}>
                                                <h3>Service Availability</h3>
                                                <p>We may change, suspend or discontinue any of our Services. We may also limit the availability of features, content and other information so that they are not available to all Visitors or Members (e.g., by country or by subscription access).</p>
                                                <p>We don’t promise to store or show (or keep showing) any information (including content) that you’ve shared. The Company’s services are not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or other information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</p>
                                            </li>
                                            <li value={3.3}>
                                                <h3>Other Content, Sites and Apps</h3>
                                                <p>Others may offer their own products and services through our Services, and we aren’t responsible for those third-party activities.</p>
                                                <p><b>Others’ Content:</b> By using the Services, you may encounter content or other information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive, or otherwise harmful. You agree that we are not responsible for content or other information made available through or within the Services by others, including Members. While we apply automated tools to review much of the content and other information presented in the Services, we cannot always prevent misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that others may share inaccurate or misleading information about you or your organization, and that you or your organization may be mistakenly associated with content about others, for example, when we let connections and followers know you or your organization were mentioned in the news.</p>
                                                <p><b>Others’ Products and Services:</b> We may help connect you to other Members (e.g., Members using Services or schools and institutions) who offer you opportunities (on behalf of themselves, their organizations, or others) such as offers to become a candidate for opportunities or offers to purchase products or services. You acknowledge that the Company does not perform these offered services, employ those who perform these services, or provide these offered products.</p>
                                                <p>You further acknowledge that the Company does not supervise, direct, control, or monitor Members in the making of these offers, or in their providing you with work, delivering products or performing services, and you agree that (1) We are not responsible for these offers, or performance or procurement of them, (2) We do not endorse any particular Member’s offers, and (3) We are not an agent or employment agency on behalf of any Member offering opportunities, employment or other work, products or services. With respect to employment or other work, the Company does not make employment or hiring decisions on behalf of Members offering opportunities and does not have such authority from Members or organizations using our products.</p>
                                                <p><b>Others’ Events:</b> Similarly, the Company may help you register for and/or attend opportunities and events organized by Members and connect with other Members who are attendees at such events. You agree that (1) We are not responsible for the conduct of any of the Members or other attendees at such events, (2) We do not endorse any particular event listed on our Services, (3) We do not review and/or vet any of these events or speakers, and (4) you will adhere to the terms and conditions that apply to such events.</p>
                                            </li>
                                            <li value={3.4}>
                                                <h3>Limits</h3>
                                                <p>The Company reserves the right to limit your use of the Services, including the number of your connections and your ability to contact other Members. We reserve the right to restrict, suspend, or terminate your account if you breach this Contract or the law or are misusing the Services (e.g., violating any of the Dos and Don’ts or Community Policies).</p>
                                                <p>We can also remove any content or other information you shared if we believe it violates our Community Policies or Dos and Don’ts or otherwise violates this Contract.</p>
                                            </li>
                                            <li value={3.5}>
                                                <h3>Intellectual Property Rights</h3>
                                                <p>The Company reserves all of its intellectual property rights in the Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. All trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks.</p>
                                            </li>
                                            <li value={3.6}>
                                                <h3>Recommendations and Automated Processing</h3>
                                                <p>We use the data and other information that you provide and that we have about Members and content on the Services to make recommendations for connections, content, ads, and features that may be useful to you. We use that data and other information to recommend and to present information to you in an order that may be more relevant for you. For example, that data and information may be used to recommend opportunities to you and you to institutions and to organize content in your feed in order to optimize your experience and use of the Services. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.</p>
                                                <p><b>AI Features:</b> By using the Services, you may interact with features we offer that automate content generation for you and for seeking advices about your educational, career, and personal choices and predicaments. The content that is generated might be inaccurate, incomplete, delayed, misleading or not suitable for your purposes. Please review and edit such content before sharing with others. Like all content you share on our Services, you are responsible for ensuring it complies with our Community Policies, including not sharing misleading information.</p>
                                                <p>The Services may include content automatically generated and shared using tools offered by us or others. Like all content and other information on our Services, regardless of whether it's labeled as created by “AI”, be sure to carefully review before relying on it.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <b>Key Terms</b>
                                    <li>
                                        <h2>Disclaimer and Limit of Liability</h2>
                                        <ol>
                                            <li value={4.1}>
                                                <h3>No Warranty</h3>
                                                <p>THE COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
                                            </li>
                                            <li value={4.2}>
                                                <h3>Exclusion of Liability</h3>
                                                <p>TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS WE HAVE ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), THE COMPANY, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.</p>
                                                <p>THE COMPANY AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO THE COMPANY FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $100.</p>
                                            </li>
                                            <li value={4.3}>
                                                <h3>Basis of the Bargain; Exclusions</h3>
                                                <p>The limitations of liability in this Section 4 are part of the basis of the bargain between you and the Company and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if the Company or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.</p>
                                                <p>THESE LIMITATIONS OF LIABILITY DO NOT APPLY TO LIABILITY FOR DEATH OR PERSONAL INJURY OR FOR FRAUD, GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT, OR IN CASES OF NEGLIGENCE, WHERE A MATERIAL OBLIGATION HAS BEEN BREACHED. A MATERIAL OBLIGATION BEING AN OBLIGATION WHICH FORMS A PREREQUISITE TO OUR DELIVERY OF SERVICES AND ON WHICH YOU MAY REASONABLY RELY, BUT ONLY TO THE EXTENT THAT THE DAMAGES WERE DIRECTLY CAUSED BY THE BREACH AND WERE FORESEEABLE UPON CONCLUSION OF THIS CONTRACT AND TO THE EXTENT THAT THEY ARE TYPICAL IN THE CONTEXT OF THIS CONTRACT.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h2>Termination</h2>
                                        <p>Both you and the Company may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services.</p>
                                    </li>
                                    <li>
                                        <h2>Governing Law and Dispute Resolution</h2>
                                        <p>In the unlikely event we end up in a legal dispute, depending on where you live, you and the Company agree to resolve it in Bangalore courts using the laws of India.</p>
                                    </li>
                                    <li>
                                        <h2>General Terms</h2>
                                        <p>Here are some important details about the Contract.</p>
                                        <p>If a court with authority over this Contract finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.</p>
                                        <p>This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.</p>
                                        <p>If we don't act to enforce a breach of this Contract, that does not mean that the Company has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that the Company may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.</p>
                                        <p>You agree that the only way to provide us legal notice is at the email address provided in Section 10.</p>
                                    </li>
                                    <li>
                                        <h2>“Dos and Don’ts”</h2>
                                        <p>This list of “Dos and Don’ts” along with our Community Policies limits what you can and cannot do on our Services, unless otherwise explicitly permitted by the Company in a separate writing (e.g., through a research agreement).</p>
                                        <ol>
                                            <li value={8.1}>
                                                <h3>Dos</h3>
                                                <p className="mb-0">You agree that you will:</p>
                                                <p>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, laws governing the content shared, and other applicable laws and regulatory requirements;</p>
                                                <p>Provide accurate contact and identity information to us and keep it updated;</p>
                                                <p>Use your real name on your profile; and</p>
                                                <p>Use the Services in a professional manner.</p>
                                            </li>
                                            <li value={8.2}>
                                                <h3>Don’ts</h3>
                                                <p className="mb-0">You agree that you will not:</p>
                                                <p>Create a false identity on PORT, misrepresent your identity, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another’s account (such as sharing log-in credentials or copying cookies);</p>
                                                <p>Develop, support or use software, devices, scripts, robots or any other means or processes (such as crawlers, browser plugins and add-ons or any other technology) to scrape or copy the Services, including profiles and other data from the Services;</p>
                                                <p>Override any security feature or bypass or circumvent any access controls or use limits of the Services (such as search results, profiles, or videos);</p>
                                                <p>Copy, use, display or distribute any information (including content) obtained from the Services, whether directly or through third parties (such as search tools or data aggregators or brokers), without the consent of the content owner;</p>
                                                <p>Disclose information that you do not have the consent to disclose such as confidential information of others (including your employer);</p>
                                                <p>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;</p>
                                                <p>Violate the intellectual property or other rights of IFSPD.eu, including, without limitation, (i) copying or distributing our learning videos or other materials, (ii) copying or distributing our technology, unless it is released under open source licenses; or (iii) using the word “IFSPD.eu” or our logos in any business name, email, or URL except as provided in the Brand Guidelines;</p>
                                                <p>Post (or otherwise share) anything that contains software viruses, worms, or any other harmful code;</p>
                                                <p>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;</p>
                                                <p>Imply or state that you are affiliated with or endorsed by IFSPD.eu without our express consent (e.g., representing yourself as an accredited IFSPD.eu trainer);</p>
                                                <p>Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without IFSPD.eu’s consent;</p>
                                                <p>Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without IFSPD.eu’s consent;</p>
                                                <p>Use bots or other unauthorized automated methods to access the Services, add or download contacts, send or redirect messages, create, comment on, like, share, or re-share posts, or otherwise drive inauthentic engagement;</p>
                                                <p>Engage in “framing”, “mirroring”, or otherwise simulating the appearance or function of the Services;</p>
                                                <p>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);</p>
                                                <p>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, manipulating algorithms);</p>
                                                <p>Violate the Professional Community Policies, certain third party terms where applicable, or any additional terms concerning a specific Service that are provided when you sign up for or start using such Service;</p>
                                                <p>Use our Services to do anything that is unlawful, misleading, discriminatory, or fraudulent; and/or</p>
                                                <p>Misuse our reporting or appeals process, including by submitting duplicative, fraudulent or unfounded reports, complaints or appeals.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h2>Complaints Regarding Content</h2>
                                        <p>We ask that you report content and other information that you believe violates your rights (including intellectual property rights), our Community Policies or otherwise violates this Contract or the law. To the extent we can under law, we may remove or restrict access to content, features, services, or information, including if we believe that it’s reasonably necessary to avoid harm to the Company or others, violates the law or is reasonably necessary to prevent misuse of our Services. We reserve the right to take action against serious violations of this Contract, including by implementing account restrictions for significant violations.</p>
                                        <p>We respect the intellectual property rights of others. We require that information shared by Members be accurate and not in violation of the intellectual property rights or other rights of third parties. We provide a policy and process for complaints concerning content shared, and/or trademarks used, by our Members.</p>
                                    </li>
                                    <li>
                                        <h2>How To Contact Us</h2>
                                        <p>For general inquiries, or wish to report any violation of these policies, or for legal notices or service of process, you may write us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} title="Director Email Address">{process.env.REACT_APP_SUPPORT_EMAIL}</a>.</p>
                                    </li>
                                </ol>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
    )    
}

export default TermsCondition;