import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LoaderMedium } from '../../loader';
import {HubDetailComp} from '../../hub/detail';
import HubAppluButton from '../../hub/apply-button';

function ModalHubInfo(props) {
    
    return (
      <> 
        <Modal show={props.isShow||false} onHide={(e) => props.toggleModal(e, false)} size="lg" className="mod-custom team-detail portfolio-edit hub-modal" backdrop="static" enforceFocus={false}>
            <Modal.Header closeButton>
                <Modal.Title>Opportunity Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-md-12 col-12 col-sm-12">
                    {props.loader
                        ?
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                        :
                        <HubDetailComp
                            data={props.data}
                        />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <button className="btn btn-secondary" onClick={(e) => props.toggleModal(e, false)}>Close</button>
                {(props.data.apply_link && props.data.expiry_days > 0) && (
                    // <a href={props.data.apply_link} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">Apply Now</button></a>
                    <HubAppluButton
                        apply_link={props.data.apply_link}
                        url={props.data.url}
                    />
                )}
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalHubInfo;