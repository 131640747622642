const EvaPortButton = (props) => {
    return (
        <>
        {!props.isEvaProcess
        ?
            <button className="btn btn-primary me-2 mb-2 mb-sm-0 me-sm-4" type="submit" onClick={(e) => props.askEvaHelp(e, props.modKey)}>Ask EVA</button>
        :
            <button className="btn btn-primary bg-color-blue me-2 mb-2 mb-sm-0 me-sm-4" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Ask EVA</button>
        }
        </>
    );
  };
  
  export  {EvaPortButton};
  