import React from 'react';
import { useSelector } from 'react-redux';
// import { Redirect } from 'react-router-dom';

const AuthCheck = (WrappedComponent) => {
  const windowPath = window.location.pathname;
  const AuthHOC = (props) => {
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    var userReduxData = useSelector(state => state.auth);
    userReduxData = (localStorage.getItem("token") && userReduxData && userReduxData.user_info) ? JSON.parse(userReduxData.user_info) : null;
    // if (!isAuthenticated) {
    //   return <Redirect to="/login" />;
    // }
    // props.is_logged = userReduxData ? true : false;
    // props.logged_user_info = userReduxData;
    return <WrappedComponent
                {...props}
                is_logged={userReduxData ? true : false}
                logged_user_info={userReduxData}
                window_path={windowPath}
            />;
  };

  return AuthHOC;
};

export default AuthCheck;