import moment from "moment";

const tagline = "If required, you may add a line or two to describe the opportunity";
const formDescription = {
  Headline: "Maximum 15 words, that will capture who you are.",
  LifeExpNew: "Capture moments, no matter how small, reflect on their impact, and how you'll apply the lessons. Updating this section at least once a week will track your growth. These experiences, both big and small, provide valuable leverage in college applications and interviews, as committees value rich personal insights.",
  Profile: "In 3 to 5 short sentences by filling the following boxes",
};
//Headline
const fieldNewHeadline =  {placeholderData: ["Passionate Sustainability Champion and History Enthusiast",
                                            "Class Topper, Ace Debater and Hobby Baker",
                                            "Top Percentile in Science and Coding. Swimming Regional Champion"
                                          ]};
//Profile
const fieldNewProfile = [
    {label: "Your name, grade and school", required: true, type: "textarea", name: "short_description", placeholderData: ["Jane Doe. 10th grade. Bucharest Global School.", "John Doe. 8th grade. International Links School."], count: 250},
    {label: "Your academic and extracurricular achievements, leadership, awards, research and any internships", required: false, type: "textarea", name: "basic_achievement", placeholderData: ["Ace debater. Won prizes in several interschool competitions. Passionate about sustainable development. Has conducted a primary research on the topic ‘Improving Sustainable Living in Romanian cities’.", "Top 1% percentile in school for Mathematics and Science. Selected for the first cohort of Glosco in India. Awarded the Ace Speaker and Ace Chair badges. Completed 3 months of internship in an NGO on ‘Empowering village women’."], count: 250},
    {label: "Your passion and future goal", required: true, type: "textarea", name: "basic_passion", placeholderData: ["Working in an NGO dedicated to sustainable practices.", "Coding enthusiast with a future goal to create a successful start-up."], count: 250},
  ];
//New Entry Form Data
const fieldNewEdu = [
      {colMd: "12", label: "How do you classify this", required: true, type: "other_input", name: "edu_classify_id", other_name: "classify_name", placeholderData: ['Select Type']},
      {colMd: "12", label: "Subject/Course/Stream", required: true, type: "other_input", name: "edu_subject_id", other_name: "subject_name", placeholderData: ['Select Subject/Course/Stream']},
      {colMd: "12", label: "Any Achievement", required: false, type: "rank_input", name: "edu_rank_id", placeholderData: ['Select Rank/Achievement'], tagline: "Certificate upload required, if selected other than 'None' (size: <=1MB, Filetype: image/pdf)", other_name: "rank_doc_name", file_type: "all"},
      {colMd: "12", label: "Institution Name", required: true, type: "text", name: "institute_name", placeholderData: ["Yokohama High School", "IFSPD Europe"], tagline, count: 150},
      {colMd: "12", label: "Program Name", required: true, type: "text", name: "program_name", placeholderData: ["A Level", "Presidential Fellow"], tagline, count: 100},
      {colMd: "12", label: "Key Achievement or Grade, if any", required: false, type: "text", name: "achievement", placeholderData: ["Distinction. Among top 1 percentile", "Overall best performer in Cohort 10"], count: 250},
      {colMd: "3", label: "Start Year", required: true, type: "year", name: "start_year", placeholderData: ["2022"]},
      {colMd: "3", label: "End Year", required: true, type: "year_present", name: "end_year", placeholderData: ["2023"]},
    ];
  const fieldValuesNewEdu = {
    edu_classify_id: '',
    classify_name: '',
    edu_subject_id: '',
    subject_name: '',
    edu_rank_id: '',
    rank_doc_name: '',
    institute_name: '',
    program_name: '',
    achievement: '',
    start_year : '',
    end_year: '',
    is_present: false
  };
  const fieldValidationNewEdu = {
            edu_classify_id: 'required||How do you classify this',
            // classify_name: '',
            edu_subject_id: 'required||Subject/Course/Stream',
            // subject_name: '',
            edu_rank_id: 'optional||Any Achievement',
            institute_name: `required|max:150`,
            program_name: `required|max:100`,
            achievement: `optional|max:250`,
            start_year: `required`
          };
  //General Details Validation
  const fieldEditGen = [
    {colMd: "6", label: "First Name", required: true, type: "text", name: "first_name"},
    {colMd: "6", label: "Last Name", required: true, type: "text", name: "last_name"},
    {colMd: "3", label: "Date of Birth", required: true, type: "date", name: "dob", max: moment(new Date()).subtract(5, 'years').format("YYYY-MM-DD")},
    {colMd: "9", label: "Languages Known", required: false, type: "text", name: "language"},
  ];
  const fieldValuesGen = {
    first_name: '',
    last_name: '',
    // country_id: '',
    // school_name_id: '',
    // cohort_name_id: '',
    dob: '',
    // email: '',
    // phone_code_id: '',
    // mobile_number: '',
    language: '',
    logo: ''
  };
  const fieldValidationGen  = {
                      first_name: 'required|max:100',
                      last_name: 'optional|max:100',
                      // school_name_id: 'required||School Name',
                      // cohort_name_id: 'required||Glosco Cohort Name',
                      // country_id: 'required||Country',
                      dob: 'required||Date of Birth',
                      // email: 'required|email|max:150',
                      // phone_code_id: 'required||Mobile Country Code',
                      // mobile_number: 'required|digit:|min:6|max:15',
                      language: 'optional|max:250||Languages Known'
                  };
  //School
  const fieldSchoolCommn = {colMd: "6", label: "Institution Type", required: true, type: "select", name: "institute_type_id"};
  const fieldEditSchool = {
    0: [fieldSchoolCommn],//Other
    1: [fieldSchoolCommn,//School
        {colMd: "12", label: "School", required: true, type: "other_input", name: "school_id", other_name: "school_name"},
        {colMd: "12", label: "Branch", required: false, type: "other_input", name: "branch_id", other_name: "branch_name"},
        {colMd: "6", label: "Class", required: true, type: "select", name: "class_id"},
      ],
    2: [fieldSchoolCommn,//College
        {colMd: "12", label: "College Name", required: true, type: "text", name: "college_name"},
        {colMd: "12", label: "Course", required: true, type: "other_input", name: "course_id", other_name: "course_name"},
      ],
    3: [fieldSchoolCommn,//Org.
      {colMd: "12", label: "Organisation Name", required: true, type: "text", name: "org_name"},
    ]
  };
  const fieldValuesSchool = {
    institute_type_id: '',
    school_id: '',
    school_name: '',
    branch_id: '',
    branch_name: '',
    class_id: '',
    logo: '',
    college_name: '',
    course_id: '',
    course_name: '',
    org_name: ''
  };
  const fieldValidShlCommn = {institute_type_id: 'required||Institution Name'};
  const fieldValidationShl  = {
    0: {
      ...fieldValidShlCommn
    },
    1: {
      ...fieldValidShlCommn,
      school_id: 'required||School',
      // school_name: 'optional|max:100',
      branch_id: 'optional||Branch',
      // branch_name: 'optional|max:100',
      class_id: 'required||Class',
    },
    2: {
      ...fieldValidShlCommn,
      college_name: 'required|max:100',
      course_id: 'required||Course',
      // course_name: 'optional|max:100',
    },
    3: {
      ...fieldValidShlCommn,
      org_name: 'required|max:100||Organisation Name'
    }
  };
  //Passion
  const fieldPassion = [
                  {passion_id: ''},
                  {passion_id: ''},
                  {passion_id: ''}
                ];
  //Headline
  const fieldValidationHead  = {
                          position: 'required|max:150||Who you are',
                        };
  //Desc
  const fieldValuesDesc = {
    short_description: '',
    basic_passion: '',
    basic_achievement: '',
  };
  const fieldValidationDesc = {
      short_description: 'required|max:250||Your name, grade and school',
      basic_achievement: 'optional|max:250||Your academic and extracurricular achievements, leadership, awards, research and any internships',
      basic_passion: 'required|max:250||Your passion and future goal',
  };
  //Achievement
  const fieldAchieve = [
      {id: 1, level: 'Beginner', year: '', indi_award: '', team_award: '', disabled: false},
      {id: 2, level: 'Foundation', year: '', indi_award: '', team_award: '', disabled: true},
      {id: 3, level: 'Intermediate', year: '', indi_award: '', team_award: '', disabled: true},
      {id: 4, level: 'Advanced', year: '', indi_award: '', team_award: '', disabled: true},
      {id: 5, level: 'Mastery', year: '', indi_award: '', team_award: '', disabled: true},
      {id: 6, level: 'Grand Mastery', year: '', indi_award: '', team_award: '', disabled: true},
    ];
  //Achievement:Add
  const fieldNewAchieve = [
    {label: "Session", required: true, type: "select", name: "session_id"},
    {label: "What was your role in this council?", required: true, type: "select", name: "role_id"},
    {label: "Did you win any award for the LAST COUNCIL?", required: true, type: "select", name: "award_id", is_role_required: true, session_ids: [4,5,6,7,8,9,12,13,14]},
    {label: "Did your team win any award?", required: true, type: "select", name: "is_team_award", is_role_required: true, session_ids: [12, 14]},
    // {label: "Team Award", required: true, type: "select", name: "team_award_id"},
    {label: "Cohort Number", required: true, type: "cohort_number", name: "cohort_number", session_ids: [13]},
    {label: "Year of completion", required: true, type: "year", name: "year", session_ids: [13]},
    {label: "How interesting was this council?", required: true, type: "rating", name: "rating"},
    {label: "What new learning or skill or insight did you gain from this Council?", required: true, type: "textarea", name: "learning"},
  ];
  // const fieldAchieve = {
  //   1: {id: 1, level: 'Beginner', year: '', indi_award: '', team_award: '', disabled: false},
  //   2: {id: 2, level: 'Foundation', year: '', indi_award: '', team_award: '', disabled: true},
  //   3: {id: 3, level: 'Intermediate', year: '', indi_award: '', team_award: '', disabled: true},
  //   4: {id: 4, level: 'Advanced', year: '', indi_award: '', team_award: '', disabled: true},
  //   5: {id: 5, level: 'Mastery', year: '', indi_award: '', team_award: '', disabled: true},
  //   6: {id: 6, level: 'Grand Mastery', year: '', indi_award: '', team_award: '', disabled: true},
  // };
  //Experience
  const fieldNewExp = [
    {colMd: "12", label: "How do you classify this", required: true, type: "other_input", name: "exp_classify_id", other_name: "exp_classify_name", placeholderData: ['Select Type']},
    {colMd: "6", label: "Individual/Team", required: true, type: "select", name: "exp_work_type_id", placeholderData: ['Select Individual/Team']},
    {colMd: "12", label: "Institution/Firm Type", required: true, type: "other_input", name: "exp_company_type_id", other_name: "exp_firm_name", placeholderData: ['Institution/Firm Type']},
    {colMd: "12", label: "Designation", required: true, type: "textarea", name: "designation", placeholderData: ["Trainee", "Intern", "Volunteer", "Passion Project"], tagline, count: 150},
    {colMd: "12", label: "Organization Name or Passion Project Name", required: true, type: "textarea", name: "organization", placeholderData: ["Local Retail Store", "Sustain Global", "Next Door Construction Site", "Embroidery on old clothes"], tagline, count: 100},
    {colMd: "12", label: "1 or 2 Key Responsibilities or Achievements or Learnings", required: false, type: "textarea", name: "achievement", placeholderData: ["Enabled easier stock taking by arranging the SKUs in a specific order", "Collected and analysed data from 100 respondents. Top performer amongst 5 interns","Tutored 3 children of next door construction workers every weekend for 1 year. Enabled them to read basic English during this time", "Created intricate embroidery on old discarded clothes and gifted them to relatives"], count: 250},
    {colMd: "3", label: "Start Date", required: true, type: "month", name: "start_year", placeholderData: ["April 2023-May 2023"]},
    {colMd: "3", label: "End Date", required: true, type: "month_present", name: "end_year", placeholderData: ["May 2023"]},
    ];
  const fieldValuesNewExp = {
    exp_classify_id: '',
    exp_work_type_id: '',
    exp_company_type_id: '',
    exp_classify_name: '',
    exp_firm_name: '',
    designation: '',
    organization: '',
    achievement: '',
    start_year : '',
    end_year: '',
    is_present: false
  };
  const fieldValidationNewExp = {
    exp_classify_id: 'required||How do you classify this',
    exp_work_type_id: 'required||Individual/Team',
    exp_company_type_id: 'required||Institution/Firm Type',
    designation: `required|max:150`,
    organization: `required|max:100||Organization Name or Passion Project Name`,
    achievement: `optional|max:250||1 or 2 Key Responsibilities or Achievements or Learnings`,
    start_year: `required||Start Date`
  };
  //Research
  const fieldNewResearch = [
    {colMd: "12", label: "How do you classify this", required: true, type: "other_input", name: "research_classify_id", other_name: "research_classify_name", placeholderData: ['Select Type']},
    {colMd: "6", label: "Individual/Team", required: true, type: "select", name: "research_work_type_id", placeholderData: ['Select Individual/Team']},
    {colMd: "12", label: "Subject/Domain", required: true, type: "other_input", name: "research_subject_id", other_name: "research_subject_name", placeholderData: ['Subject/Domain']},
    {colMd: "6", label: "Zone/Area", required: true, type: "select", name: "research_com_level_id", placeholderData: ['Select Zone/Area']},
    {colMd: "12", label: "Any Achievement", required: false, type: "rank_input", name: "research_rank_id", placeholderData: ['Select Rank/Achievement'], tagline: "Certificate upload required, if selected other than 'None' (size: <=1MB, Filetype: image/pdf)", other_name: "rank_doc_name", file_type: "all"},
    {colMd: "12", label: "Project Title", required: true, type: "textarea", name: "title", placeholderData: ["Crown Jewel", "Best Practices in Sustainability", "Key Fashion for Today"], tagline, count: 150},
    {colMd: "12", label: "Where was it published", required: true, type: "textarea", name: "domain", placeholderData: ["School Year Book", "www.medium.com", "Youtube"], tagline, count: 100},
    {colMd: "12", label: "Link", required: false, type: "textarea", name: "link", placeholderData: ["xyzz.com/xyz", "", "xyzz.com/xyz-xyz"], count: 250},
    {colMd: "12", label: "Any Key Achievement or Accolade or Learning?", required: false, type: "textarea", name: "achievement", placeholderData: ["Got 100 views and 20 comments in 3 months of publication", "None", "Got 500 views and 20 comments in 3 months of release"], count: 250},
    {colMd: "12", label: "Year of publishing", required: true, type: "year", name: "year", placeholderData: ["2020"]},
    ];
  const fieldValuesNewResearch = {
    research_classify_id: '',
    research_work_type_id: '',
    research_subject_id: '',
    research_com_level_id: '',
    research_rank_id: '',
    research_classify_name: '',
    research_subject_name: '',
    rank_doc_name: '',
    title: '',
    domain: '',
    link: '',
    achievement: '',
    year : ''
  };
  const fieldValidationNewResearch = {
    research_classify_id: 'required||How do you classify this',
    research_work_type_id: 'required||Individual/Team',
    research_subject_id: 'required||Subject/Domain',
    research_com_level_id: 'required||Zone/Area',
    research_rank_id: 'optional||Any Achievement',
    title: 'required|max:150||Project Title',
    domain: 'required|max:100||Where was it published',
    link: 'optional|max:250||Link',
    achievement: 'optional|max:250||Any Key Achievement or Accolade or Learning?',
    year : 'required||Year of publishing'
  };
  //Award
  const fieldNewAward = [
    {colMd: "6", label: "How do you classify this", required: true, type: "select", name: "award_classify_id", placeholderData: ['Select Type']},
    {colMd: "12", label: "Field/Domain", required: true, type: "other_input", name: "award_domain_id", other_name: "award_domain_name", placeholderData: ['Field/Domain']},
    {colMd: "6", label: "Zone/Area", required: true, type: "select", name: "award_level_id", placeholderData: ['Select Zone/Area']},
    {colMd: "12", label: "Any Achievement", required: false, type: "rank_input", name: "award_rank_id", placeholderData: ['Select Rank/Achievement'], tagline: "Certificate upload required, if selected other than 'None' (size: <=1MB, Filetype: image/pdf)", other_name: "rank_doc_name", file_type: "all"},
    {colMd: "12", label: "Institution Name", required: true, type: "textarea", name: "institute_name", placeholderData: ["Regional Sustainability Centre", "Global Links School"], tagline, count: 100},
    {colMd: "12", label: "Name of the Award or Position", required: true, type: "textarea", name: "award_name", placeholderData: ["Youth Ambassador", "Best Outgoing Student"], tagline, count: 100},
    {colMd: "12", label: "Why was this awarded to you?", required: false, type: "textarea", name: "why_awarded", placeholderData: ["Overall best performance", "For creating an awareness about sustainability and for promoting sustainability practices amongst youngsters in the region"], count: 250},
    {colMd: "12", label: "Year", required: true, type: "year", name: "year", placeholderData: ["2023"]},
    ];
  const fieldValuesNewAward = {
    award_classify_id: '',
    award_domain_id: '',
    award_level_id: '',
    award_rank_id: '',
    award_domain_name: '',
    rank_doc_name: '',
    institute_name: '',
    award_name: '',
    why_awarded: '',
    year : ''
  };
  const fieldValidationNewAward = {
    award_classify_id: 'required||How do you classify this',
    award_domain_id: 'required||Field/Domain',
    award_level_id: 'required||Zone/Area',
    award_rank_id: 'optional||Any Achievement',
    institute_name: 'required|max:100',
    award_name: 'required|max:100||Name of the Award or Position',
    why_awarded: 'optional|max:250||Why was this awarded to you?',
    year : 'required'
  };
  //Sport
  const fieldNewSport = [
    {colMd: "12", label: "How do you classify this", required: true, type: "other_input", name: "sport_classify_id", other_name: "sport_classify_name", placeholderData: ['Select Type']},
    {colMd: "6", label: "Individual/Team", required: true, type: "select", name: "sport_work_type_id", placeholderData: ['Select Individual/Team']},
    {colMd: "12", label: "Field/Category/Domain", required: true, type: "other_input", name: "sport_domain_id", other_name: "sport_domain_name", placeholderData: ['Field/Category/Domain']},
    {colMd: "6", label: "Zone/Area", required: true, type: "select", name: "sport_level_id", placeholderData: ['Select Zone/Area']},    
    {colMd: "12", label: "Any Achievement", required: false, type: "rank_input", name: "sport_rank_id", placeholderData: ['Select Rank/Achievement'], tagline: "Certificate upload required, if selected other than 'None' (size: <=1MB, Filetype: image/pdf)", other_name: "rank_doc_name", file_type: "all"},
    {colMd: "12", label: "Competition Name", required: true, type: "textarea", name: "name", placeholderData: ["Football", "Caricaturing"], tagline, count: 150},
    {colMd: "12", label: "Where did you participate?", required: true, type: "textarea", name: "venue", placeholderData: ["School junior team", "School annual sports"], tagline, count: 100},
    {colMd: "12", label: "Any Key Achievement or Accolade or Learning?", required: false, type: "textarea", name: "achievement", placeholderData: ["Played as key striker. Scored 3 goals in the season", "Amongst the top 5 across all divisions."], count: 250},
    {colMd: "3", label: "Start Year", required: true, type: "year", name: "start_year", placeholderData: ["2020"]},
    {colMd: "3", label: "End Year", required: true, type: "year_present", name: "end_year", placeholderData: ["2024"]},
    ];
  const fieldValuesNewSport = {
    sport_classify_id: '',
    sport_work_type_id: '',
    sport_domain_id: '',
    sport_level_id: '',
    sport_rank_id: '',
    sport_classify_name: '',
    sport_domain_name: '',
    rank_doc_name: '',
    name: '',
    venue: '',
    achievement: '',
    start_year : '',
    end_year: '',
    is_present: false
  };
  const fieldValidationNewSport = {
    sport_classify_id: 'required||How do you classify this',
    sport_work_type_id: 'required||Individual/Team',
    sport_domain_id: 'required||Field/Category/Domain',
    sport_level_id: 'required||Zone/Area',
    sport_rank_id: 'optional||Any Achievement',
    name: 'required|max:150||Competition Name',
    venue: 'required|max:100||Where did you participate?',
    achievement: 'optional|max:250||Any Key Achievement or Accolade or Learning?',
    start_year: `required||Start Year`
  };
  // Life Experience
  const fieldEditLifeExp = [
    {colMd: "12", label: "Details of this experience or defining moment? Including when and where.", required: true, type: "textarea", name: "experience", count: 500, placeholderData: ["A classmate from a poorer background was often ignored. One day, during a group activity, I was struggling with a project, and he stepped in to help with his unique perspective.", "During a school science fair, I faced unexpected technical issues with my project while presenting, leading to a complete breakdown."]},
    {colMd: "12", label: "How did this experience or defining moment change your life?", required: true, type: "textarea", name: "impact", count: 500, placeholderData: ["This experience taught me not to judge people by their circumstances. It showed me that everyone has value and talents, regardless of their background.", "This moment taught me that failure is part of learning and that I shouldn’t fear making mistakes in front of others."]},
    {colMd: "12", label: "How will you apply these learnings?", required: false, type: "textarea", name: "learning", count: 500, placeholderData: ["I now make an effort to be inclusive, and not to judge others based on some external factors. I actively support and encourage others, ensuring no one feels left out.", "I now embrace challenges with a positive attitude, understanding that each setback is an opportunity to learn and improve in future endeavours."]},
    // {colMd: "12", label: "Experience Themes", required: false, type: "multi-select", name: "theme_id", placeholderData: ["Resilience: in the face of a challenge", "Curiosity: in exploring something new", "Leadership: by guiding others"], tagline: 'Click and select a maximum of 2 experience themes that best represents the core lesson or personal growth you experienced. These themes help you reflect on the key qualities that shaped your experience.'},
    // {colMd: "12", label: "Emotion", required: false, type: "multi-select", name: "emotion_id", placeholderData: ["Excitement: from trying something new", "Pride: in accomplishing something", "Anxious: about results of a competition"], tagline: "Choose maximum of 2 emotions that best captures how you felt during the experience. Selecting an emotion helps you reflect on your feelings and personal growth in that moment."},
    {colMd: "12", label: "Experience Themes", required: false, type: "multi-select", name: "theme_id", placeholderData: ["Resilience:", "Curiosity", "Leadership"], tagline: 'Click and select a maximum of 2 experience themes that best represents the core lesson or personal growth you experienced. These themes help you reflect on the key qualities that shaped your experience.'},
    {colMd: "12", label: "Emotion", required: false, type: "multi-select", name: "emotion_id", placeholderData: ["Excitement", "Pride", "Anxious"], tagline: "Choose maximum of 2 emotions that best captures how you felt during the experience. Selecting an emotion helps you reflect on your feelings and personal growth in that moment."},
    ];
    const fieldNewLifeExp = [
      {colMd: "5", label: "Normal Update/Competition Entry", required: true, type: "select", name: "competition_id", placeholderData: ['Select Entry Type']},
      ...fieldEditLifeExp
    ];
  const fieldValuesNewLifeExp = {
    experience: '',
    impact: '',
    learning: '',
    theme_id: [],
    emotion_id: [],
    // competition_id: {id: 1, label: 'Normal Portfolio Submission'}
    competition_id: ''
  };
  const fieldValidationEditLifeExp = {
    experience: 'required|max:500||Details of this experience or defining moment?',
    impact: 'required|max:500||How did this experience or defining moment change your life?',
    learning: 'optional|max:500||How will you apply these learnings?',
    // theme_id: 'optional||Experience Themes',
    // emotion_id: 'optional||Emotion',
  };
  const fieldValidationNewLifeExp = {
    competition_id: 'required||Normal Update/Competition Entry',
    ...fieldValidationEditLifeExp
  };
  //Social
  const fieldNewSocial = [
    {colMd: "12", label: "Name of the Social Start-up and SDGs", required: true, type: "text", name: "name", count: 150, placeholderData: ["Save Water. SDG6 and SDG14."], tagline},
    {colMd: "12", label: "Problem definition and KPI", required: true, type: "textarea", name: "kpi", count: 250, placeholderData: ["Lakes are being landfilled for building concrete structures. Reach out to 100 community members to sign on a complaint to be submitted to the local government."]},
    {colMd: "12", label: "Key Responsibility/ Achievement", required: true, type: "textarea", name: "achievement", count: 250, placeholderData: ["Was able to collect signatures from 200 community members, double that of KPI. Submitted a complaint to the zonal government office."]},
    {colMd: "12", label: "Key Learnings for Life", required: true, type: "textarea", name: "learning", count: 250, placeholderData: ["I learnt that most community members would choose not to act though they are getting directly affected by a problem. But once a leader takes up initiative, they are more than glad to join the efforts."]},
    {colMd: "3", label: "Date", required: true, type: "month", name: "date", placeholderData: ["April 2023"]},
  ];
  const fieldValuesNewSocial = {
    name: '',
    kpi: '',
    achievement: '',
    learning: '',
    date: ''
  };
  const fieldValidationNewSocial = {
    name: 'required|max:150||Name of the Social Start-up and SDGs',
    kpi: 'required|max:250||Problem definition and KPI',
    achievement: 'required|max:250||Key Responsibility/ Achievement',
    learning: 'required|max:250||Key Learnings for Life',
    date: 'required'
  };
  //Recommend
  const fieldRecom = [
    {colMd: "12", label: "Name of the referee", required: true, type: "text", name: "name"},
    {colMd: "12", label: "Email of the referee", required: true, type: "email", name: "email"},
    {colMd: "12", label: "How does this referee know you?", required: true, type: "text", name: "relation"},
  ];
  const fieldValuesRecom = {
    name: '',
    email: '',
    relation: '',
  };
  const fieldValidationRecom = {
    name: 'required|max:150||Name of the referee',
    email: 'required|email|max:150||Email of the referee',
    relation: 'required|max:100||How does this referee know you?',
  };
  //Referee
  const fieldReferee = [
    {colMd: "12", label: "Name of the recommender", required: true, type: "text", name: "name"},
    {colMd: "12", label: "Designation and Organisation/Institution of the recommender", required: true, type: "text", name: "designation"},
    {colMd: "12", label: "How have you known or your relationship with the applicant", required: true, type: "text", name: "relationship"},
    {colMd: "12", label: "How long have you known the applicant", required: true, type: "text", name: "duration"},
    {colMd: "12", label: "Applicant’s personal qualities such as integrity, perseverance, leadership, and team skills etc.", required: true, type: "textarea", name: "quality", count: 250},
    {colMd: "12", label: "Observations of how the applicant interacts with others and handles challenges", required: false, type: "textarea", name: "observation", count: 250},
    {colMd: "12", label: "Insights into the applicant’s academic strengths Or For non-academic recommenders, information about the applicant’s skills, and contributions.", required: false, type: "textarea", name: "academic", count: 250},
    {colMd: "12", label: "Any additional examples that illustrate the applicant’s strengths, achievements, or impact", required: false, type: "textarea", name: "strength", count: 250},

  ];
  const fieldValueReferee = {
    name: '',
    designation: '',
    relationship: '',
    duration: '',
    quality: '',
    observation: '',
    academic: '',
    strength: ''
  };
  const fieldValidReferee = {
    name: 'required|max:150||Name of the recommender',
    designation: 'required|max:150||Designation of the recommender',
    relationship: 'required|max:100||How have you known or your relationship with the applicant',
    duration: 'required|max:100||How long have you known the applicant',
    quality: 'required|max:250||Applicant’s personal qualities such as integrity, perseverance, leadership, and team skills etc.',
    observation: 'optional|max:250||Observations of how the applicant interacts with others and handles challenges',
    academic: 'optional|max:250||Insights into the applicant’s academic strengths...',
    strength: 'optional|max:250||Any additional examples that illustrate the applicant’s strengths, achievements, or impact',
  };
  
  const docTypeIds = {
    edu: 1,
    exp: 2,
    award: 3,
    sport: 4,
    lifeExp: 5,
    research: 6
  };
  // Glosco
  const fieldValidationGlosco = {
    session_id: 'required||Session',
    role_id: 'required||What was your role in this council?',
    rating: 'required||How interesting was this council?',
    learning: 'required|max:250||What new learning or skill or insight did you gain from this Council?',
  };
  
  const data = {
    formDescription,
    fieldNewEdu,
    fieldValuesNewEdu,
    fieldValidationNewEdu,
    fieldEditGen,
    fieldValuesGen,
    fieldValidationGen,
    fieldEditSchool,
    fieldValidationShl,
    fieldValuesSchool,
    fieldValidationHead,
    fieldValuesDesc,
    fieldPassion,
    fieldAchieve,
    fieldValidationDesc,
    fieldValuesNewExp,
    fieldNewExp,
    fieldValidationNewExp,
    fieldNewResearch,
    fieldValuesNewResearch,
    fieldValidationNewResearch,
    fieldValuesNewAward,
    fieldNewAward,
    fieldValidationNewAward,
    fieldValuesNewSport,
    fieldNewSport,
    fieldValidationNewSport,
    fieldValuesNewLifeExp,
    fieldNewLifeExp,
    fieldEditLifeExp,
    fieldValidationNewLifeExp,
    fieldValidationEditLifeExp,
    fieldValuesNewSocial,
    fieldNewSocial,
    fieldValidationNewSocial,
    fieldValuesRecom,
    fieldRecom,
    fieldValidationRecom,
    fieldReferee,
    fieldValueReferee,
    fieldValidReferee,
    docTypeIds,
    fieldValidationGlosco,
    fieldNewHeadline,
    fieldNewProfile,
    fieldNewAchieve
  };
  
  export default data;