const ChatbotIcon = () => {
  return (
    <div className="brand-logo">
      <img alt="AskEVA logo" src={require("../../assets/images/icon/askeva-logo.jpg")} />
      {/* <img alt="Logo" src={require("../../assets/images/icon/logo.png")} /> */}
    </div>
  );
};

export default ChatbotIcon;
