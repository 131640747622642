import {
  CART_COUNT,
  SOCIAL_IDS
//   DEVICE_DATA
} from "../action-types/state-update";
import {
    USER_INFO,
    // TOKEN
  } from "../action-types/auth";
import axios from 'axios';
import moment from 'moment';
const apiUrl = process.env.REACT_APP_API_URL;
const headers = {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : ""
    // 'Authorization': localStorage.getItem("token") ? `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWZlcmVuY2VfaWQiOjYsImlhdCI6MTY0MDUyNTU4MCwiZXhwIjoxNjQxMzg5NTgwfQ.mviLHnB0FBTHR40B5-DLI2bXs_UOMR5D8WhKY_pq9bY` : ""
  };
/*
 * Use to update Device Id
 */
// export const update_device_data = (payload) => async dispatch => {
export const update_device_data = async(payload) => {
    try {
        localStorage.setItem("device_id",  payload.device_id);
        // dispatch({ type: DEVICE_DATA, payload: {device_id: payload.device_id}});
        const deviceIDCheck = await axios.post(`${apiUrl}/check-device-id`, payload);
        const { data } = deviceIDCheck;
        if(data.status === 200){
            if(data.is_duplicate === 1){
                //Device Already Exists in DB, Set New Device ID
                // dispatch({ type: DEVICE_DATA, payload: {device_id: data.device_id}});
                localStorage.setItem("device_id",  data.device_id);
            }
            return {success: true};
        }else{
            return {success: false};
        }
    } catch (error) {
        return {success: false};
    }
};
/*
 * Use to Renew Logged In Token
 */
export const auth_token_update = (payload) => async dispatch => {
    try {
        const token = localStorage.getItem("token");
        const loggedInDateTime = localStorage.getItem("token_dt");
        if(token && loggedInDateTime){
            const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            const startDate = moment(loggedInDateTime, "YYYY-MM-DD HH:mm:ss");
            const endDate = moment(currentDateTime, "YYYY-MM-DD HH:mm:ss");
            const differenceHours = endDate.diff(startDate, 'hours');
            // if(differenceHours > process.env.REACT_APP_AUTH_CHECK_HOURS){
                //Renew Token
                const authCheck = await axios.post(`${apiUrl}/auth-check`,
                                                {auth_date: loggedInDateTime},
                                                {headers});
                const { data } = authCheck;
                if(data.status === 200){
                    if(data.token){
                        const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("token_dt", currentDateTime);
                    }
                    if(data.logout === 0 && data.user_data){
                        dispatch({ type: USER_INFO, payload: {
                                                        first_name: data.user_data.first_name,
                                                        last_name: data.user_data.last_name,
                                                        user_name: data.user_data.user_name,
                                                        is_verified: data.user_data.is_verified,
                                                        image: data.user_data.image,
                                                        // is_email_verified: '0'
                                                    }});
                    }
                }
                if((data.status === 200 
                    && data.logout === 1
                ) || (data.status === 403)){
                    localStorage.removeItem("token");
                    localStorage.removeItem("token_dt");
                    localStorage.removeItem("is_verified");
                    // dispatch({ type: USER_INFO, payload: {first_name: 'User',
                    //         is_verified: '0',
                    //         // is_email_verified: '0'
                    //     }});
                    dispatch({ type: USER_INFO, payload: null});
                    //Show login modal:Pending
                    window.location.reload(); 
                }
                return {status: data.status};
            // }
        }else{
            return {status: 200};
        }
    } catch (error) {
        return {status: 500};
    }
}
/*
 * Use to Update logged User Name and Image on Change
 */
export const update_user_info = (payload, cb) => async dispatch => {
    var response = {status : 500};
    try {
        dispatch({ type: USER_INFO, 
                    payload: {
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        user_name: payload.user_name,
                        is_verified: payload.is_verified,
                        image: payload.image,
                        // is_email_verified: '0'
                    }});
                
        response.status = 200;
        return cb(response);
    } catch (error) {
        return cb(response);
    }
}
/*
 * Use to social ids to show recetly views data
 */
export const update_social_ids = (payload, cb) => async dispatch => {
    var response = {status : 500};
    try {
        dispatch({ type: SOCIAL_IDS, 
                    payload});
                
        response.status = 200;
        return cb(response);
    } catch (error) {
        console.log(error)
        return cb(response);
    }
}