import React, {useState, useEffect} from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {SetMetaData} from './../../helper/page-title';
import './../../assets/js/cart';
import redirectionGif from './../../assets/images/redirect-loader.gif';

function PaymentStatus(){
    SetMetaData("Payment Confirmation", "", "");
    const { payment_status } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [refData, setRefData] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    //Redirect to Respective Page if error
    useEffect(() => {
      const time = redirectUrl === '/pay' ? 8000 : 10000;
        // const time = 8000;
      let timer = setTimeout(() => {
        if(redirectUrl !== ''){
          navigate(redirectUrl, { replace: true });
        }
      }, time);
      return () => {
        clearTimeout(timer);
      };
    }, [redirectUrl]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Product Filter API
    useEffect(() => {
      //Get Order Status
      const urlQuryString = location.search;
      const urlParam = new URLSearchParams(urlQuryString);
      const referenceId = urlParam.get('ref_id');
      setRefData(referenceId);
    //   const orderStatusParam = urlParam.get('status');
        const orderStatusParam = payment_status;
      if(orderStatusParam){
        setOrderStatus(orderStatusParam);
        try{
          if(orderStatusParam === 'success'){
            const redirectTo = (urlParam.get('serv_id') && urlParam.get('serv_id') === '2')
                                ? '/social-startup/my'
                                : '/port';
            // setRedirectUrl(`/port`);
            setRedirectUrl(redirectTo);
          }else{
        //   (orderStatusParam === 'failed'){
            setRedirectUrl('/pay');
          }
        //   else{
        //     navigate('/pay', { replace: true });
        //   }
        }catch(e){
          navigate('/pay', { replace: true });
        }
      }else{
        navigate('/pay', { replace: true });
      }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
    return (
        <main id="main">
        <section className="mt-60 pt-4">
            <div className="container-fluid container-lg">
                <div className="section-header section-header-1">
                    <h1 className="title">Payment Confirmation</h1>
                </div>       
                <div className="row justify-content-center">
                    {orderStatus !== '' && (
                    <div className="col-md-8 col-lg-8 col-12 col-sm-12">
                    <div className="card pt-4 pb-4 ps-5 pe-5">
                        {orderStatus === 'success'
                        ?
                        <div className="alert alert-success text-center">
                            <p className="fs-4"><b>Hurray! You have been successfully subscribed to IFSPD Services.</b></p>
                            <p><b>Thank You.</b></p>
                        </div>
                        :
                          <>
                            <div className="alert alert-danger text-center">
                                <p className="fs-4"><b>Sorry Payment Failed! Please Subscribe again.</b></p>
                                <p>Note: In case the money has been deducted from your account, it will be refunded within 1 to 5 working days.</p>
                                {refData && (
                                    <p>Reference Id: <b>#{refData}</b></p>
                                )}
                            </div>
                          </>
                        }
                        
                        <p className="text-center">You will be redirected shortly</p>
                        <img src={redirectionGif} alt="Redirecting"/>
                    </div>
                    </div>
                    )}
                </div>
            </div>
        </section>
      </main>
    )    
}

export default PaymentStatus;