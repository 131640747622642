import moment from "moment";
import { useRef } from "react";
// import { alertResponseMessage } from "../../actions/action-creators/response";

const ChatbotForm = ({ sendMessage, setChatHistory, isInpDisabled, isLoggedIn}) => {
  const inputRef = useRef();

  const handleFormSubmit = (e) => {
    try{
      e.preventDefault();
      const userMessage = inputRef.current.value.trim();
      if (!userMessage) return;
      inputRef.current.value = "";
      sendMessage(userMessage);
    }catch(error){
      // alertResponseMessage({alert_type: 2, message: "Error Connecting! Please talk to AskEVA after some times"});
      setChatHistory((history) => [...history, 
                                  { role: "model",
                                    text: "Oh no, I’ve gone on a mini adventure! Don’t worry, I’ll be back before you even miss me!",
                                    isError: true,
                                    time: moment().format('hh:mm A')
                                  }]);
    }


  //   // Update chat history with the user's message
  //   setChatHistory((history) => [...history, { role: "user", text: userMessage }]);

  //   // Delay 600 ms before showing "Thinking..." and generating response
  //   setTimeout(() => {
  //     // Add a "Thinking..." placeholder for the bot's response
  //     setChatHistory((history) => [...history, { role: "model", text: "Thinking..." }]);

  //     // Call the function to generate the bot's response
  //     generateBotResponse([...chatHistory, { role: "user", text: `Using the details provided above, please address this query: ${userMessage}` }]);
  //   }, 600);
  };

  return (
    <form onSubmit={(e) => handleFormSubmit(e, inputRef.current.value.trim())} className="chat-form">
      <input ref={inputRef} placeholder={isLoggedIn ? "What's on your mind? 💭....." : "Log in to Port to start talking..." } className="message-input" required readOnly={isInpDisabled} />
      <button type="submit" id="send-message">
        <img alt="Send" src={require("./../../assets/images/icon/send.svg").default} className="align-baseline" />
      </button>
    </form>
  );
};

export default ChatbotForm;
