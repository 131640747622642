import ChatbotIcon from "./chatbot-icon";
const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

const ChatbotMessage = ({ chat }) => {
  //Get Message
  const formatMsg = (msg) => {
    try{
      const textArr = msg.split('GMT -');
      msg = textArr.length > 1 ? textArr[1] : textArr[0];
      // var apiResponseText = data.response.Message;
      var bold = /\*\*(.*?)\*\*/gm;
      msg = msg.replace(bold, '<strong>$1</strong>');
      return msg.trim();
    }catch(error){
      return '';
    }
  }
  //Get Local Time
  const localDateTime = (date) => {
    // let dateLocal = new Date(date);
    // let newDate = new Date(
    //   dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
    // );
    const today = new Date();
    const d = new Date(date);
    let hour = d.getHours();
    const min = addZero(d.getMinutes());
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? addZero(hour) : 12; 
    const msgDate = d.getDate();
    const msgMonth = month[d.getMonth()];
    const nowDate = today.getDate();
    const nowMonth = month[today.getMonth()];
    // console.log(date, hour, 'hour', msgDate);
    if(msgDate === nowDate && msgMonth === nowMonth){
      return `${hour}:${min} ${ampm}`;
    }else{
      return `${msgDate} ${msgMonth}, ${hour}:${min} ${ampm}`;
    }
  };
  const addZero = (i) => {
    if (i < 10) {i = "0" + i}
    return i;
  }

  return (
    !chat.hideInChat && (
      <div className={`message ${chat.speaker === "EVA" ? "bot" : "user"}-message ${chat.isError ? "error" : ""}`}>
        {chat.speaker === "EVA" && <ChatbotIcon />}
        <div className="message-text">
          <div dangerouslySetInnerHTML={ { __html: formatMsg(chat.text)}}></div>
          <span className="chat-time">{localDateTime(chat.createdAt)}</span>
        </div>
      </div>
    )
  );
};

export default ChatbotMessage;
