// Welcome messages:
const welMsgEva = [
    "Hey there 👋! I’m your friend, here to help with majors, colleges, or just to chat. You would be surprised what I can do for you!",
    "Hey 👋! Got questions or just need to talk? I’m here for you like a true friend. Let’s chat!",
    "Hi 👋! I’m your buddy who’s here to make life easier—whether it’s school stuff or just a little vent session.",
    "Hello there 👋! I’m your go-to friend for advice, support, or just a judgment-free chat. Let’s talk!",
    "Hey 👋! Whether you need help or just want to share your thoughts, I’m here for you as a good friend.",
    "Hi 👋! I am your friend who’s always ready to listen and help you figure things out.",
    "Hey 👋! I’m here to help you with your plans or just be a friend who listens—no pressure, no judgment.",
    "Hey 👋! I’m your friend who’s always here to guide, support, or just listen. Let’s do this together!",
    "Hey there 👋! I’m your buddy for all things in school—or just here to listen if you need it.",
    "Hi 👋! I’m like a friend you can always count on—ready to help with decisions or just be there for you.",
    "Hey 👋! Whether it’s advice or just a friend to talk to, I’m always here for you.",
    "Hi there 👋! I’m here to help, listen, and cheer you on—like the friend you can always turn to.",
    "Hey 👋! I’m your friend who’s here to make everything a little easier. What’s on your mind?",
];
// Error Messages:
const errorMsgEva = [
    "Oh no, I stepped out for a quick coffee break! Try me again in a bit—I’ll be back soon!",
    "Yikes! I popped out for some fresh air. I’ll be back before you even miss me!",
    "I had to step away for a moment—to grab a snack. Try me again soon!",
    "BRB! I’m off on a top-secret mission (okay, maybe just a quick breather). See you shortly!",
    "I’m out recharging my friendly vibes. Give me a moment and try again soon!",
    "I had to step out for a sec—maybe to save the world, or maybe just my Wi-Fi. Check back soon!",
    "Oops, I’m out chasing squirrels. Be back in a jiffy!",
    "BRB! I stepped out for a little stroll in the digital park. Back in a flash!",
    "Oh no, I’ve gone on a mini adventure! Don’t worry, I’ll be back before you even miss me!",
    "Be right back! I stepped out to recharge my friendly batteries—see you soon!"
];
// Guest User:
const guestMsgEva = [
    "Hi 👋! Don’t keep me waiting—log in so we can figure this out together, like besties do!",
    "Hey 👋! Let’s make this all about you. Log in, and we’ll explore majors, colleges, or just talk if you need it.",
    "Hiya 👋! Wanna start sorting out life stuff? Log in and let’s chat—I’ve got your back!",
    "Hey 👋! Log in so we can get started on the fun stuff—big dreams or just a friendly chat.",
    "Hi 👋! Don’t leave me hanging—log in and let’s figure out your next steps, together like friends!",
    "Hey there 👋! Let’s not waste time. Log in and we’ll tackle all the cool stuff, stress-free!",
    "Yo 👋! I’m here for you. Log in, and let’s sort out colleges, scholarships, or whatever’s on your mind.",
    "Hi there 👋! Let’s get this rolling. Log in, and I’ll be right here to help with whatever you need.",
    "Hi, friend 👋! Log in so we can get started on your future, one fun step at a time.",
    "Hey 👋! Let’s do this together. Log in and I’ll help with all the tricky stuff—or we can just talk.",
    "Hi 👋! Log in, so I can be the friend who’s got your back with school stuff and more.",
    "Yo 👋! Log in so we can chat, plan, or just figure things out like friends do. I’m here for you!",
    "Hi there 👋! I’m ready when you are. Log in and let’s turn all those plans into something real!"
];
// User To Fill in Sections:
const inactiveMsgEva = [
    "Hey 👋! The more I know about you, the better help I can give. Fill out a few more sections, and let’s make magic happen!",
    "Hey 👋! Trust me, the more you share, the better I get. Fill in your portfolio, and let me blow your mind!",
    "Hi 👋! Add a bit more to your portfolio, and I’ll give you help you didn’t even know you needed!",
    "Hey 👋! The more I know, the better I can help. Fill in your portfolio, and watch the magic unfold!",
    "Hey 👋! Trust me, the more you share, the better it gets. Fill in more sections and get ready for some surprises!",
    "Hey there 👋! I’d love to give you personalized advice, but I need to know you better first. Fill in more sections to get started!",
    "Hi 👋! Think of your portfolio as a puzzle—every piece you add helps me see the bigger picture. Let’s fill in some gaps!",
    "Hi 👋! The more you share, the smarter I get. Add some details to your portfolio, and I’ll do my thing!",
    "Hey 👋! I’m here to help, but first, I need some clues about you. Fill in more sections, and we’re good to go!",
    "Hey 👋! Your portfolio is looking good, but let’s make it great. Add a few more details, and I’ll handle the rest!",
    "Hi 👋! I’m ready to help with majors, colleges, and more, but first, fill me in. Add more to your portfolio so I can be my best!",
    "Hi 👋! Your portfolio’s coming along, but I know we can make it even better. Fill in a few more sections—let’s do this!",
    "Hey 👋! I’m all ears for your plans, but first, I need to know more about you. Update your portfolio so I can help!",
    "Hey, friend 👋! I want to give you advice that’s just right for you. Add more details, and I’ll get to work!",
    "Hi 👋! Your portfolio’s the key to unlocking my best advice. Let’s fill in a little more to get the ball rolling!",
];

export {
    welMsgEva,
    errorMsgEva,
    guestMsgEva,
    inactiveMsgEva,
};