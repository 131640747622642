import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/team.css';

function PrivacyPolicy(){
    SetMetaData("Privacy Policy", "", "");

    return (
        <main id="main">
            <section className="team-sec mb-5 pt-0">
            <div className="container-fluid container-lg pe-lg-5 ps-lg-5">
                <div className="section-header section-header-1">
                    <h1 className="title">Privacy Policy</h1>
                </div>
    
                <div className="row justify-content-center team-detail privacy-detail m-1">
                    <div className="col-md-10 col-12 col-sm-12 card">
                        <div className="p-2 mt-3">
                            <div className="mb-3">
                                <p>IFSPD.EU and LeadCap Knowledge Solutions Pvt Ltd (hereinafter referred to as “Company”, “us”, “we” or “our”) mission is to enable students to engage in higher order continuous capacity enhancement programs, track and showcase their growth, achievements and skills and gain access to exclusive international avenues. We also enable institutions like schools to track and analyze data of their students who use our Services. Central to this mission is our commitment to be transparent about the data we collect about users, how it is used and with whom it is shared.</p>
                                <p>This Privacy Policy applies when you use our Services (described below). We offer our users choices about the data we collect, use and share as described in this Privacy Policy, Cookie Policy, Settings and our Help Center.</p>
                            </div>
                            <div>
                                <ol>
                                    <li>
                                        <h2>Introduction</h2>
                                        <p>We enable students to engage in higher order continuous capacity enhancement programs, track and showcase their growth, achievements and skills and gain access to exclusive international avenues – through our online and offline services. Our Privacy Policy applies to any Member or Visitor to our Services.</p>
                                        <p>Our registered users (“Members”) share their identities, engage with their network, showcase their growth, achievements and skills, exchange knowledge and insights, post and view relevant content, learn and develop skills, and find academic and career opportunities. Content and data on some of our Services is viewable to non-Members (“Visitors”).</p>
                                        <h3>Services</h3>
                                        <p>This Privacy Policy, including our Cookie Policy applies to your use of our Services.<br/>This Privacy Policy applies to <a href="https://ifspd.eu">IFSPD.eu</a>,  <a href="https://ifspd.eu/port">IFSPD.eu/PORT</a>,  <a href="https://ifspd.eu/hub">IFSPD.eu/HUB</a>,  <a href="https://ifspd.eu/ss">IFSPD.eu/SS</a>, and other IFSPD branded sites, apps, communications and services offered by IFSPD (“Services”), including off-site Services, such as our Global Sustainability Councils (Glosco).</p>
                                        <h3>Data Controllers and Contracting Parties</h3>
                                        <p>The Company will be the controller of (or business responsible for) your personal data provided to, or collected by or for, or processed in connection with our Services.<br/>
                                        As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.</p>
                                        <h3>Change</h3>
                                        <p>Changes to the Privacy Policy apply to your use of our Services after the “effective date.”
                                        We can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.<br/>
                                        You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.</p>
                                    </li>
                                    <li>
                                        <ol>
                                            <li><h2>Data We Collect</h2>
                                                <ol>
                                                    <li value={1.1}>
                                                        <h3>Data You Provide To Us</h3>
                                                        <h3>Ownership of User-Provided Data</h3>
                                                        <p>By using our platform, you acknowledge and agree that the data you provide, including but not limited to demographic information, registration details, profile content, education history, work experience, unique skills, projects, competition participation, life-altering experiences, Glosco participation, awards, photographs, letters of recommendation, AI counselling services, and optional verifications (such as recommendation verifications or Glosco attendance records), becomes the property of the Company.</p>
                                                        <p>We retain ownership of this data to improve and provide our services, conduct analytics, and enhance user experiences, while maintaining compliance with applicable data protection laws. Please note that your data will be processed in accordance with this Privacy Policy and used responsibly to protect your privacy and rights.</p>
                                                        <h3>Registration</h3>
                                                        <p>To create an account you need to provide data including your name, school, grade, email address and/or mobile number, general location (e.g., city), password etc. If you register for a premium Service, you will need to provide payment (e.g., credit card) and billing information.<br/>
                                                        You create your Port profile (a complete profile helps you get the most from our Services).</p>
                                                        <h3>PORT Profile</h3>
                                                        <p>You have choices about the information on your profile, such as your education, work experience, unique skills, projects, competition participation, life altering experiences, Glosco participation, awards, photo, letters of recommendation, AI counselling services, and optional verifications of information on your profile (such as verifications of your recommendations, Glosco attendance etc). You don’t have to provide additional information on your profile; however, profile information helps you to get more from our Services, including helping colleges, and opportunities find you. It’s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available.</p>
                                                        <h3>Posting and Uploading</h3>
                                                        <p>We collect personal data from you when you provide, post or upload it to our Services, such as when you fill out a form, (e.g., with demographic data or life altering services or competition entries), respond to a survey, or submit a resume or fill out a competition application on our Services.<br/>
                                                        You don’t have to post or upload personal data; though if you don’t, it may limit your ability to grow and engage with opportunities offered by our Services.</p>
                                                    </li>
                                                    <li value={1.2}>
                                                        <h3>Data From Others</h3>
                                                        <h3>Content and News</h3>
                                                        <p>You and others may post content that includes information about you (as part of articles, posts, comments, videos) on our Services. We also may collect public information about you, such as news and accomplishments, and make it available as part of our Services, including, in notifications to others of mentions in the news.</p>
                                                        <h3>Partners</h3>
                                                        <p>We receive personal data about you when you use the services of our customers and affiliates, such as competition providers, agencies or prospective colleges and applicant tracking systems providing us college application data.</p>
                                                        <h3>Related Companies and Other Services</h3>
                                                        <p>We receive data about you when you use some of the other services provided by us or our affiliates.</p>
                                                    </li>
                                                    <li value={1.3}><h3>Service Use</h3>
                                                    <p>We log usage data when you visit or otherwise use our Services, including our sites, app and platform technology, such as when you view or click on content (e.g., AI counsellor) or ads, perform a search, install or update one of our mobile apps, share articles or apply for opportunities. We use log-ins, cookies, device information and internet protocol (“IP”) addresses to identify you and log your use.</p>
                                                    </li>
                                                    <li value={1.4}><h3>Cookies and Similar Technologies</h3>
                                                    <p>We use cookies and similar technologies to collect data to recognize you and your device(s) on, off and across different services and devices where you have engaged with our Services. We also allow some others to use cookies as described in our Cookie Policy. We also collect (or rely on others who collect) information about your device where you have not engaged with our Services (e.g., ad ID, IP address, operating system and browser information) so we can provide our Members with relevant ads and better understand their effectiveness.</p>
                                                    </li>
                                                    <li value={1.5}><h3>Your Device and Location</h3>
                                                    <p>When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings.</p>
                                                    </li>
                                                    <li value={1.6}><h3>Communications</h3>
                                                    <p>We collect information about you when you communicate with others through our Services (e.g., when you send, receive, or engage with messages, events, or connection requests, including our marketing communications). This may include information that indicates who you are communicating with and when. We also use automated systems to support and protect our site. For example, we use such systems to suggest possible responses to messages and to manage or block content that violates our User Agreement or Professional Community Policies.</p>
                                                    </li>
                                                    <li value={1.7}><h3>School Provided Information</h3>
                                                    <p>When your organization (e.g., institution or school) buys a premium Service for you to use, they give us data about you.<br/>
                                                    Others buying our Services for your use, such as your institution or your school, provide us with personal data about you and your eligibility to use the Services that they purchase for use by their students or alumni. For example, we will get contact information, and for authorizing users of our premium Services, such as our analytics or learning products.</p>
                                                    </li>
                                                    <li value={1.8}><h3>Sites and Services of Others</h3>
                                                    <p>We get data when you visit sites that include our ads, cookies or plugins or when you log-in to others’ services with your PORT account.<br/>
                                                    We receive information about your visits and interaction with services provided by others when you log-in with PORT or visit others’ services that include some of our plugins or our ads, cookies or similar technologies.</p>
                                                    </li>
                                                    <li value={1.9}><h3>Other</h3>
                                                    <p>We are improving our Services, which means we get new data and create new ways to use data.
                                                    Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will modify this Privacy Policy.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <b>Key Terms</b>
                                    <li>
                                        <ol>
                                            <li value={2}>
                                                <h2>How We Use Your Data</h2>
                                                <p>How we use your personal data will depend on which Services you use, how you use those Services. We may use your personal data to improve, develop, and provide products and Services, develop and train artificial intelligence (AI) models, develop, provide, and personalize our Services, and gain insights with the help of AI, automated systems, and inferences, so that our Services can be more relevant and useful to you and others.</p>
                                                <ol>
                                                    <li value={2.1}>
                                                        <h3>Services</h3>
                                                        <p>We use your data to authorize access to our Services and honor your settings.</p>
                                                        <h3>Stay Connected</h3>
                                                        <p>Our Services allow you to stay in touch and up to date with colleagues, students and contacts. To do so, you can “connect” with the other students who you choose, and who also wish to “connect” with you. When you connect with other Members, you might be able to search each other’s’ connections in order to exchange professional opportunities.
                                                        We use data about you (such as your profile, profiles you have viewed or data provided) to help others find your profile, suggest connections for you and others (e.g. Members who share your contacts or experiences) and enable you to invite others to become a Member and connect with you.</p> 
                                                        <h3>Stay Informed</h3>
                                                        <p>Our Services allow you to stay informed about news, events, channels and ideas regarding topics you care about, and from professionals you respect. Our Services also allow you to improve your skills, or learn new ones or explore new opportunities. We use the data we have about you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you, such as by recommending or ranking relevant content and conversations on our Services. We also use the data we have about you to suggest skills you could add to your profile and skills that you might need to pursue your next opportunity. So, if you let us know that you are interested in a new skill, we will use this information to personalize content in your feed, suggest that you follow certain Members on our site, or suggest related channels or learning content to help you towards that new skill. We use your content, activity and other data, including your name and photo, to provide notices to your network and others. For example, we may notify others that you have updated your profile, posted content, took a social action, followed a channel, used a feature, made new connections or were mentioned in the news.</p>
                                                        <h3>Opportunities</h3>
                                                        <p>Our Services allow you to explore and evaluate educational opportunities, and seek out, and be found for, career opportunities. Your profile can be found by those looking to get right people for an opportunity. We will use your data to recommend opportunities and show you and others relevant contacts. You can signal that you are interested in an opportunity and fill their application forms to be considered for them. We will use your data to recommend opportunities to you and you to opportunities. We may use automated systems to provide content and recommendations to help make our Services more relevant to our Members, Visitors and customers. Keeping your profile accurate and up-to-date may help you better connect to others and to opportunities through our Services.</p>
                                                        <h3>Productivity</h3>
                                                        <p>Our Services allow you to collaborate with colleagues, search for partners and others to share opportunities with. Our Services allow you to communicate with other Members and schedule and meet with them.</p>
                                                    </li>
                                                    <li value={2.2}>
                                                        <h3>Premium Services</h3>
                                                        <p>Our premium Services help paying users to search for an analyse Members through our Services, such as searching for and contacting candidates, leads, manage talent and promote content.<br/>
                                                        We sell premium Services that provide our customers and subscribers with customized-search functionality and tools as part of our solutions. Customers can export limited information from your profile, such as name, headline, school, grade, location etc, such as to manage opportunities and talent.</p>
                                                    </li>
                                                    <li value={2.3}>
                                                        <h3>Communications</h3>
                                                        <p>We contact you and enable communications between Members. We will contact you through email, mobile phone, notices posted on our websites or apps or channels, messages to your inbox, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, opportunity suggestions and promotional messages from us and our partners. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.<br/>
                                                        We also enable communications between you and others through our Services, including for example invitations, channels and messages between connections.</p>
                                                    </li>
                                                    <li value={2.4}>
                                                        <h3>Advertising</h3>
                                                        <p className="mb-0">We serve you tailored ads both on and off our Services. We target (and measure the performance of) ads to Members, Visitors and others both on and off our Services directly or through a variety of partners, using the following data, whether separately or combined:</p>
                                                        <ul>
                                                            <li>Data collected by advertising technologies on and off our Services</li>
                                                            <li>Member-provided information (e.g., profile, contact information, title and school etc);</li>
                                                            <li>Data from your use of our Services (e.g., search history, feed, content you read, who you follow or is following you, connections, channels participation, page visits, clicking on an ad, etc.), including as described in Section 1.3;</li>
                                                            <li>Information from advertising partners, vendors and publishers; and</li>
                                                            <li>Information inferred from data described above (e.g., using titles from a profile to infer interests; using graduation dates to infer age or using first names or pronoun usage to infer gender; using your feed activity to infer your interests; or using device data to recognize you as a Member).</li>
                                                            <li>We will show you ads called sponsored content which look similar to non-sponsored content, except that they are labelled as advertising (e.g., as “ad” or “sponsored”). If you take a social action (such as like, comment or share) on these ads, your action is associated with your name and viewable by others, including the advertiser.</li>
                                                        </ul><br/>
                                                        <h3>Info to Ad Providers</h3>
                                                        <p>We do not share your personal data with any third-party advertisers or ad networks except for: (i) hashed IDs or device identifiers (to the extent they are personal data in some countries); (ii) with your separate permission (e.g., in a lead generation form) or (iii) data already visible to any users of the Services (e.g., profile). However, if you view or click on an ad on or off our Services, the ad provider will get a signal that someone visited the page that displayed the ad, and they may, through the use of mechanisms such as cookies, determine it is you. Advertising partners can associate personal data collected by the advertiser directly from you with hashed IDs or device identifiers received from us.</p>
                                                    </li>
                                                    <li value={2.5}>
                                                        <h3>Marketing</h3>
                                                        <p>In addition to advertising our Services, we use Members’ data and content for invitations and communications promoting membership and network growth, engagement and our Services, such as by showing your connections that you have used a feature on our Services.</p>
                                                    </li>
                                                    <li value={2.6}>
                                                        <h3>Developing Services and Research</h3>
                                                        <h3>Service Development</h3>
                                                        <p>We us edata, including public feedback, to conduct research and development for our Services in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth and engagement on our Services, and help connect professionals to each other and to economic opportunity.</p>
                                                        <h3>Other Research</h3>
                                                        <p>We seek to create opportunities for Members and to help them be more productive and successful. We use the personal data available to us to research social, economic and workplace trends, such as jobs availability and skills needed for these jobs and policies that help bridge the gap in various industries and geographic areas. In some cases, we work with trusted third parties to perform this research, under controls that are designed to protect your privacy. We may also make public data available to researchers to enable assessment of the safety and legal compliance of our Services. We publish or allow others to publish economic insights, presented as aggregated data rather than personal data.</p>
                                                        <h3>Surveys</h3>
                                                        <p>Polls and surveys are conducted by us and others through our Services. You are not obligated to respond to polls or surveys, and you have choices about the information you provide. You may opt-out of survey invitations.</p>
                                                    </li>
                                                    <li value={2.7}>
                                                        <h3>Customer Support</h3>
                                                        <p>We use data to help you and fix problems.<br/>
                                                        We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</p>
                                                    </li>
                                                    <li value={2.8}>
                                                        <h3>Insights That Do Not Identify You</h3>
                                                        <p>We use your data to perform analytics to produce and share insights that do not identify you. For example, we may use your data to generate statistics about our Members, their interests, competencies, to show Members' information about engagement with a post or channels, to publish visitor demographics for a Service or create demographic workforce insights, or to understand usage of our services.</p>
                                                    </li>
                                                    <li value={2.9}>
                                                        <h3>Security and Investigations</h3>
                                                        <p>We use data for security, fraud prevention and investigations.<br/>
                                                        We and our affiliates, may use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of the law, our User Agreement and/or attempts to harm our Members, Visitors, company, Affiliates, or others.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <b>Key Terms</b>
                                    <li>
                                        <ol>
                                            <li value={3}>
                                                <h2>How We Share Information</h2>
                                                <ol>
                                                    <li value={3.1}>
                                                        <h3>Our Services</h3>
                                                        <p>Any data that you include on your profile and any content you post or social action (e.g., likes, follows, comments, shares) you take on our Services will be seen by others, consistent with your settings.</p>
                                                        <h3>Profile</h3>
                                                        <p>Your profile is fully visible to all Members and customers of our Services. Subject to your settings, it can also be visible to others on or off of our Services (e.g., Visitors to our Services or users of third-party search tools).</p>
                                                        <h3>Posts, Likes, Follows, Comments, Messages</h3>
                                                        <p>Our Services allow viewing and sharing information including through channels, posts, likes, follows and comments.</p>
                                                        <ul>
                                                            <li>When you share an article or a post (e.g., an update, image, video or article) publicly it can be viewed by everyone and re-shared anywhere (subject to your settings). Members, Visitors and others will be able to find and see your publicly-shared content, including your name (and photo if you have provided one).</li>
                                                            <li>In a channel, posts are visible to others according to channel type. For example, posts in private channel are visible to others in the group and posts in public channels are visible publicly. Your membership in groups is public and part of your profile.</li>
                                                            <li>When you like or re-share or comment on another’s content (including ads), others will be able to view these “social actions” and associate it with you (e.g., your name, profile and photo if you provided it).</li>
                                                            <li>Your school can see how you use Services and related information.</li>
                                                        </ul><br/>
                                                        <h3>Data Access and Usage by Schools and Educational Institutions</h3>
                                                        <p>When students enroll in our platform through their schools or educational institutions, their profile data, including any content they create or upload, will be accessible to these institutions. Schools and educational institutions may view, download, and analyze this data for purposes such as monitoring academic progress, providing guidance, and tailoring educational support. By using this platform, students and their guardians consent to such access and usage by the enrolling institution. Schools and educational institutions are obligated to comply with this Privacy Policy and applicable data protection laws in handling student data.</p>
                                                    </li>
                                                    <li value={3.2}>
                                                        <h3>3.2 Communication Archival</h3>
                                                        <p>Regulated Members may need to store communications outside of our Service.<br/>
                                                        Some Members (or their institutions) need, for legal or professional compliance, to archive their communications and social media activity, and will use services of others to provide these archival services. We enable archiving of messages by and to those Members outside of our Services.</p>
                                                    </li>
                                                    <li value={3.3}>
                                                        <h3>Others’ Services</h3>
                                                        <p className="mb-0">You may link your account with others’ services so that they can look up your contacts’ profiles, post your shares on such platforms, or enable you to start conversations with your connections on such platforms. Excerpts from your profile will also appear on the services of others.</p>
                                                        <p className="mb-0">When you opt to link your account with other services, personal data (e.g., your name, school, and grade) will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. Third-party services have their own privacy policies, and you may be giving them permission to use your data in ways we would not. You may revoke the link with such accounts.</p>
                                                        <p>The information you make available to others in our Services (e.g., information from your profile, your posts, your engagement with the posts, or message to Pages) may be available to them on other services. For example, search tools, competition applications, may show a user limited profile data, and social media management tools or other platforms may display your posts. The information retained on these services may not reflect updates you make on PORT.</p>
                                                    </li>
                                                    <li value={3.4}>
                                                        <h3>Related Services</h3>
                                                        <p>We will share your personal data with our Affiliates to provide and develop our Services. For example, we may refer a query, such as where you'd benefit from a more up to date response in a chat experience. Where allowed, we may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to you and others. For example, we may personalize your feed or opportunity recommendations based on your learning history.</p>
                                                    </li>
                                                    <li value={3.5}>
                                                        <h3>Service Providers</h3>
                                                        <p>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, customer support, marketing and development). They will have access to your information (e.g., the contents of a customer support request) as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes. If you purchase a Service from us, we may use a payments service provider who may separately collect information about you (e.g., for fraud prevention or to comply with legal obligations).</p>
                                                    </li>
                                                    <li value={3.6}>
                                                        <h3>Legal Disclosures</h3>
                                                        <p>It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services or the products or services of our Affiliates (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of our Company, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand. To learn more see our Data Request Guidelines and Transparency Report.</p>
                                                    </li>
                                                    <li value={3.7}>
                                                        <h3>Change in Control or Sale</h3>
                                                        <p className="mb-0">We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.</p>
                                                        <p>We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <ol>
                                            <li value={4}>
                                                <h2>Your Choices & Obligations</h2>
                                                <ol>
                                                    <li value={4.1}>
                                                        <h3>Data Retention</h3>
                                                        <p className="mb-0">We keep most of your personal data for as long as your account is open.</p>
                                                        <p>We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when looking for a new opportunity every few years, we will retain your information and keep your profile open, unless you close your account. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.</p>
                                                    </li>
                                                    <li value={4.2}>
                                                        <h3>Rights to Access and Control Your Personal Data</h3>
                                                        <p className="mb-0">We may provide many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your posts to advertising opt-outs and communication controls.</p>
                                                        <p className="mb-0">For personal data that we have about you, you can:</p>
                                                        <ul>
                                                            <li>Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).</li>
                                                            <li>Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.</li>
                                                            <li>Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.</li>
                                                        </ul><br/>
                                                    </li>
                                                    <li value={4.3}>
                                                        <h3>Account Closure</h3>
                                                        <p className="mb-0">We keep some of your data even after you close your account.</p>
                                                        If you choose to close your PORT account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.
                                                        <p className="mb-0">We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our User Agreement, or fulfill your request to "unsubscribe" from further messages from us. We will retain de-personalized information after your account has been closed.</p>
                                                        <p>Information you have shared with others will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members have copied out of our Services. Groups content and ratings or review content associated with closed accounts will show an unknown user as the source. Your profile may continue to be displayed in the services of others (e.g., search tools) until they refresh their cache.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <ol>
                                            <li value={5}>
                                                <h2>Other Important Information</h2>
                                                <ol>
                                                    <li value={5.1}>
                                                        <h3>Security</h3>
                                                        <p>We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>
                                                    </li>
                                                    <li value={5.2}>
                                                        <h3>Cross-Border Data Transfers</h3>
                                                        <p>We may process data both inside and outside of your country and rely on legally-provided mechanisms to lawfully transfer data across borders.</p>
                                                    </li>
                                                    <li value={5.3}>
                                                        <h3>Contact Information</h3>
                                                        <p className="mb-0">You can contact us or use other options to resolve any complaints.</p>
                                                        <p>If you have questions or complaints regarding this Policy, please first contact us through <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} title="Director Email Address">{process.env.REACT_APP_SUPPORT_EMAIL}</a></p>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </main>
    )    
}

export default PrivacyPolicy;