import { combineReducers } from "redux";
import auth from "./auth";
import stateUpdate from "./state-update";

const rootReducer = combineReducers({
  auth,
  stateUpdate
});

export default rootReducer;


// const appReducer = (state, action) => {
//   // if (action.type === 'SIGNOUT_REQUEST') {
//     console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj', state)
//   //   localStorage.removeItem('persist:root')
//   //   // state = undefined
//   //   // delete state.auth.user_info;
//   //   // state.auth.user_info = null;
//   //   state.auth.user_info = undefined
//   //   state.auth = undefined
//   //   return rootReducer(state, action);
//   // }
//   const stateCopy = action.type === "SIGNOUT_REQUEST" ? undefined : { ...state }
//   return rootReducer(stateCopy, action)
// }