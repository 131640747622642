/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
// import React , {useState, useEffect} from 'react';
import { useSelector} from 'react-redux';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';
// import logo from '../assets/images/logo.png';
// const winWidth = window.innerWidth;

function Header() {
    // const [userReduxData, setUserReduxData] = useState(null);
    const windowPath = window.location.pathname.split('/')[1];
    var userReduxData = useSelector(state => state.auth);
    userReduxData = userReduxData && userReduxData.user_info ? JSON.parse(userReduxData.user_info) : null;
    // var userReduxDataGet = useSelector(state => state.auth);
    // userReduxDataGet = userReduxDataGet && userReduxDataGet.user_info ? JSON.parse(userReduxDataGet.user_info) : null;
    // let oldScrollY = 0;
    // const cartReduxData = useSelector(state => state.stateUpdate);
    // const isAuth = localStorage.getItem("token") ? true : false;
    // //Query Default
    // var defaultKeyword = '';
    // var defaultSelect = 'med';
    // // const urlQuryString = window.location.href;
    // const urlPath = window.location.pathname;
    // const urlQuryString = window.location.search
    // const urlParam = new URLSearchParams(urlQuryString);
    // const urlTypeQuery = urlParam.get('keyword');
    // const searchType = urlParam.get('search_type');
    // if(searchType !== null){
    //     defaultSelect = searchType;
    // }else{
    //     const urlPathArray = urlPath.split('/');
    //     if(urlPathArray.length > 1 &&
    //         urlPathArray[1] === 'book-lab-test'
    //     ){
    //         defaultSelect = 'test';
    //     }
    // }
    // console.log(defaultSelect, 'defaultSelect')
    
    // if(urlTypeQuery && urlTypeQuery !== ''){
    //     defaultKeyword = urlTypeQuery;
    // }
    // const [keyword, setKeyword] = useState(defaultKeyword);
    // const [searchSelect, setSearchSelect] = useState(defaultSelect);
    // const [showLogo, setShowLogo] = useState(true);
    // const [dropdownToggle, setDropdownToggle] = useState(false);
    // const updateInput = (e, type) => {
    //     if(type === 'input'){
    //         setKeyword(e.target.value);
    //     }else if(type === 'select'){
    //         setSearchSelect(e.target.value);
    //     }
    // }
    // const showDropdown = (e)=>{
    //     setDropdownToggle(!dropdownToggle);
    // }
    // const hideDropdown = e => {
    //     setDropdownToggle(false);
    // }  
	// const controlHeader = () => {        
    //     oldScrollY = window.scrollY;
    // };

    // const toggleHeaderSty = () => {
    //     const scrolled = document.documentElement.scrollTop;
    //     let selectHeader = document.querySelector('#header');
    //     if(selectHeader){
    //             if (scrolled > 100){
    //                 selectHeader.classList.add('header-scrolled');
    //                 if(winWidth < 400){
    //                     if(window.scrollY > oldScrollY) {
    //                         setShowLogo(false);
    //                     } else {
    //                         setShowLogo(true);
    //                     }
    //                 }
    //             }
    //             // else if (scrolled <= 100){
    //             //     setShowLogo(true);
    //             //     selectHeader.classList.remove('header-scrolled');
    //             // }
    //             else{
    //                 setShowLogo(true);
    //                 selectHeader.classList.remove('header-scrolled');
    //             }
    //     }
        
    // };
    // Toggle Mobile NavBar
    const toggleMobNav = () => {
        try{
            // const selectNavBar = document.querySelector('#navbar');
            // const selectMobIcon = document.querySelector('.mobile-nav-toggle');
            // selectNavBar.classList.toggle('navbar-mobile');
            // selectMobIcon.classList.toggle('bi-list');
            // selectMobIcon.classList.toggle('bi-x');
            const selectNavBar = document.querySelector('.header-bar');
            const selectMobMenu = document.querySelector('.menu');
            selectNavBar.classList.toggle('close');
            selectMobMenu.classList.toggle('open');
        }catch(e){
            // console.log('error opening mob navbar');
        }
    };
    //Activate Mobile Dropdown
    const activateMobDrop = (evt) => {
        try{
            evt.preventDefault();
            // if (evt.target !== this) return;
            const selectNavBarId = document.querySelector('#scrollbar');
            if(selectNavBarId
                && selectNavBarId.classList.contains('mobile-menu-area-inner')
            ){
                
                // const prvSib = evt.target.previousElementSibling;
                // if(prvSib){
                //     nextSib.classList.toggle('open');
                // }
                const targetTag = evt.target.tagName.toLowerCase();
                    // const prevSib = evt.target.previousElementSibling;
                    // // const nextSib = prevSib.nextElementSibling;
                    // prevSib.classList.toggle('dropdown-active');
                    // evt.target.parentElement.find('ul').classList.toggle('dropdown-active');
                if(targetTag === 'i' 
                    || targetTag === 'span'
                ){
                    const nxtUl = evt.target.parentElement.nextElementSibling;
                    nxtUl.classList.toggle('dropdown-active');
                }else if(targetTag === 'a'){
                    const nextSib = evt.target.nextElementSibling;
                    nextSib.classList.toggle('dropdown-active');
                }else{
                    evt.preventDefault();
                }
                // if(evt.target.classList.contains('dropdown')){
            }
        }catch(e){
            console.log(e);
        }
    }
    useEffect(() => {
        // window.addEventListener('scroll', toggleHeaderSty);
        // window.addEventListener('scroll', controlHeader);
        const selectMobDrop = document.querySelectorAll('#scrollbar .dropdown > a');
        if(selectMobDrop.length){
            for (var i = 0 ; i < selectMobDrop.length; i++) {
                selectMobDrop[i].addEventListener('click', activateMobDrop ) ; 
            }
        }

        return () => {
            // window.removeEventListener('scroll', toggleHeaderSty);
            // window.removeEventListener('scroll', controlHeader);
            if(selectMobDrop.length){
                for (var i = 0 ; i < selectMobDrop.length; i++) {
                    selectMobDrop[i].removeEventListener('click', activateMobDrop ) ; 
                }
            }
        }
    }, []);
    
    // useEffect(() => {
    //     // const modifyUserData = userReduxDataGet = userReduxDataGet && userReduxDataGet.user_info ? JSON.parse(userReduxDataGet.user_info) : null;
    //     console.log('userReduxDataGet', userReduxDataGet)

    //     setUserReduxData(userReduxDataGet);
    // }, [userReduxDataGet])
    //Display Dot In the Name after some characters
    const displayUserName = (name) => {
        try{
            return name = name.length > 11 ? `${name.substring(0, 11)}...`: name;
        }catch(e){

        }
    } 
  return (
    <>
        {/* <!-- Header --> */}
        {/* <!-- mobile-nav section start here --> */}
        <div className="mobile-menu">
        <nav className="mobile-header primary-menu d-lg-none">
            <div className="header-logo">
            <a href="/" className="logo">
                {/* <!-- <img src="/assets/images/logo.png" alt="IFSPD"> --> */}
                <p className="tagline">IFSPD</p>
            </a>
            </div>
            <div className="header-bar" id="open-button" onClick={() => toggleMobNav()}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
        {/* Mobile Menu */}
        <nav className="menu">
            <div className="mobile-menu-area d-lg-none">
            <div className="mobile-menu-area-inner" id="scrollbar">
                <ul className="m-menu">
                    <li className={`dropdown ${!(localStorage.getItem("token") && userReduxData) ? 'd-none' : ''}`}><a href="#" className=""><span>My Account</span> <i className="fas fa-chevron-down"></i></a>
                        <ul>
                            <li><a className="" href={`/port/${userReduxData && userReduxData.user_name ? userReduxData.user_name : ''}`}>Portfolio</a></li>
                            {/* <li><a className="" href="/start">Social Startup</a></li> */}
                            <li><a className="" href="/logout">Logout</a></li>
                        </ul>
                    </li>
                {/* {(localStorage.getItem("token") && userReduxData)
                 ?
                    <li className="dropdown"><a href="#" className=""><span>My Account</span> <i className="fas fa-chevron-down"></i></a>
                        <span>
                            {userReduxData.image ? 
                                <img className="p-0 m-0 me-1" src={userReduxData.image} alt={userReduxData.first_name} loading="lazy" />
                            : 
                                <img className="p-0 m-0 me-1" src={require("../assets/images/no-pic.png")} alt={userReduxData.first_name} loading="lazy" />
                            }
                        </span>
                        <span>{displayUserName(userReduxData.first_name)}</span> <i className="fas fa-chevron-down"></i>
                        <ul>
                            <li><a className="" href={`/port/${userReduxData.user_name||''}`}>Portfolio</a></li>
                            <li><a className="" href="/start">Social Startup</a></li>
                            <li><a className="" href="/port/edit">Edit Portfolio</a></li>
                            <li className="text-start pt-2">
                                <span>Social Startup</span>
                                <div className="ms-3 mb-2 drop-custom">
                                    <a href={`/social-startup/create`}>Initiate a Social Startup</a>
                                    <a className="p-1" href={`/social-startup/my`}>My Social Startup</a>
                                </div>
                            </li>
                            <li><a className="" href="/logout">Logout</a></li>
                        </ul>
                    </li>
                : */}

                {!(localStorage.getItem("token") && userReduxData) && (
                    <>
                    <li>
                        <a href="/login">Login</a>
                    </li>
                    <li>
                        <a href="/register">Register</a>
                    </li>
                    </>
                )}
                <li><a className="" href="/">Home</a></li>
                <li className="dropdown"><a href="#" className=""><span>About</span> <i className="fas fa-chevron-down"></i></a>
                    <ul>
                    <li><a className="" href="/history">History</a></li>
                    <li><a className="" href="/founders">Founders</a></li>
                    <li><a className="" href="/principles-and-objectives">Principles and Objectives</a></li>
                    </ul>
                </li>
                <li className="dropdown"><a href="#" className=""><span>Organization</span> <i className="fas fa-chevron-down"></i></a>
                    <ul>
                    <li><a className="" href="/presidential-committee">Presidential Committee</a></li>
                    {/* <li><a className="" href="/legal-entity">Legal Entity</a></li> */}
                    <li><a className="" href="/our-team">Our Team</a></li>
                    {/* <li><a className="" href="/our-partners">Our Partners</a></li> */}
                    </ul>
                </li>
                <li><a className="" href="/board-meetings">Events</a></li>
                <li><a className="" href="/gallery">Gallery</a></li>
                <li className="dropdown"><a href="#" className=""><span>Academy</span> <i className="fas fa-chevron-down"></i></a>
                    <ul>
                        {!(localStorage.getItem("token") && userReduxData) && (
                            <>
                            <li><a className="" href="/port">Portfolio</a></li>
                            {/* <li><a className="" href="/start">Social Startup</a></li> */}
                            </>
                        )} 
                        <li><a href="/hub">Hub</a></li>
                        {/* <li><a className="" href="/fellowship">About</a></li> */}
                        {/* <li><a href="/global-sustainability-certification">Global Sustainability Certification</a></li> */}
                        {/* <li><a href="/young-leader-certification">Young Leader Certification</a></li> */}
                        {/* <li><a className="" href="/fp">Presidential Fellowships</a></li> */}
                        <li><a className="" href="/fellow-profiles">Fellows</a></li>
                        {/* <li><a className="" href="/apply" target="_blank" rel="noopener noreferrer">Apply</a></li> */}
                        {/* <li><a className="" href="#">Apply</a></li> */}
                    </ul>
                </li>
                <li><a className="" href="/contact">Contact</a></li>
                </ul>
                {/* <!-- <ul className="social-link-list d-flex flex-wrap">
                <li><a href="https://www.facebook.com//" className="facebook" target="_blank"><i className=" fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com//" target="_blank" className="instagram-sm"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://in.linkedin.com/in/" target="_blank" className="linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                </ul> --> */}
            </div>
            </div>
        </nav>
        </div>
        {/* <!-- mobile-nav section ending here --> */}

        {/* <!-- header section start here --> */}
        <header className="header-section d-none d-lg-block">
        {/* <!--  <div className="header-top">
            <div className="container">
            <div className="htop-area row">
                <div className="htop-left">
                <ul className="htop-information">
                    <li><a href="mailto:secretariat@ifspd.org" title=""><i className="far fa-envelope"></i> secretariat@ifspd.org</a></li>
                    <li><a href="tel:+35924440033"><i className="fas fa-phone-volume"></i> +35924440033</a></li>
                </ul>
                </div>
                <div className="htop-right">
                <ul>
                    <li><a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://in.linkedin.com/in/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                </ul>
                </div>
            </div>
            </div>
        </div> --> */}
        <div className="header-bottom" style={{background: 'white'}}>
            <div className="container">
            <div className="row">
                <nav className="primary-menu">
                <div className="menu-area">
                    <div className="row justify-content-center align-items-center">
                    {/* <!-- <a href="/" className="logo">
                        <img src="/assets/images/logo.png" alt="IFSPD">
                        <p className="tagline">IFSPD</p>
                    </a> --> */}
                    <div className="main-menu-area align-items-center">
                        <ul className="main-menu d-flex align-items-center">
                        <li><a className={`${windowPath === '' ? 'active' : ''}`} href="/">Home</a></li>
                        <li className="dropdown"><a href="#" className={`${(windowPath === 'history' || windowPath === 'founders' || windowPath === 'principles-and-objectives') ? 'active' : ''}`}><span>About</span> <i className="fas fa-chevron-down"></i></a>
                            <ul>
                            <li><a className="" href="/history">History</a></li>
                            <li><a className="" href="/founders">Founders</a></li>
                            <li><a className="" href="/principles-and-objectives">Principles and Objectives</a></li>
                            </ul>
                        </li>
                        <li className="dropdown"><a href="#" className={`${(windowPath === 'presidential-committee' || windowPath === 'legal-entity' || windowPath === 'our-team' || windowPath === 'our-partners') ? 'active' : ''}`}><span>Organization</span> <i className="fas fa-chevron-down"></i></a>
                            <ul>
                            <li><a className="" href="/presidential-committee">Presidential Committee</a></li>
                            {/* <li><a className="" href="/legal-entity">Legal Entity</a></li> */}
                            <li><a className="" href="/our-team">Our Team</a></li>
                            {/* <li><a className="" href="/our-partners">Our Partners</a></li> */}
                            </ul>
                        </li>
                        <li><a className={`${windowPath === 'board-meetings' ? 'active' : ''}`} href="/board-meetings">Events</a></li>
                        <li><a className={`${windowPath === 'gallery' ? 'active' : ''}`} href="/gallery">Gallery</a></li>
                        <li className="dropdown"><a href="#" className={`${(windowPath === 'global-sustainability-certification' || windowPath === 'fp' || windowPath === 'fellow-profiles' || windowPath === 'hub' || windowPath === 'young-leader-certification') ? 'active' : ''}`}><span>Academy</span> <i className="fas fa-chevron-down"></i></a>
                            <ul>
                                {!(localStorage.getItem("token") && userReduxData) && (
                                    <>
                                    <li><a className="" href="/port">Portfolio</a></li>
                                    {/* <li><a className="" href="/start">Social Startup</a></li> */}
                                    </>
                                )}
                                <li><a href="/hub">Hub</a></li>
                                {/* <li><a className="" href="/fellowship">About</a></li> */}
                                {/* <li><a href="/global-sustainability-certification">Global Sustainability Certification</a></li> */}
                                {/* <li><a href="/young-leader-certification">Young Leader Certification</a></li> */}
                                {/* <li><a className="" href="/fp">Presidential Fellowships</a></li> */}
                                <li><a className="" href="/fellow-profiles">Fellows</a></li>
                                {/* <li><a className="" href="#">Apply</a></li> */}
                                {/* <li><a className="" href="/apply" target="_blank" rel="noopener noreferrer">Apply</a></li> */}
                            </ul>
                        </li>
                        <li><a className={`${windowPath === 'contact' ? 'active' : ''}`} href="/contact">Contact</a></li>
                        {(localStorage.getItem("token") && userReduxData)
                            ?
                            // <li className="dropdown"><a href="#" className={`${(windowPath === `portfolio/${userReduxData.user_name}` || windowPath === 'portfolio/edit' || windowPath === 'logout') ? 'active' : ''}`}>
                            <li className="dropdown"><a href="#" className={`${(windowPath === 'port' || windowPath === 'social-startup' || windowPath === 'logout') ? 'active' : ''}`}>
                                <span>
                                    {userReduxData.image ? 
                                        <img className="p-0 m-0 me-1" src={userReduxData.image} alt={userReduxData.first_name} loading="lazy" />
                                    : 
                                        <img className="p-0 m-0 me-1" src={require("../assets/images/no-pic.png")} alt={userReduxData.first_name} loading="lazy" />
                                    }
                                </span>
                                <span>{displayUserName(userReduxData.first_name)}</span> <i className="fas fa-chevron-down"></i></a>
                                <ul>
                                    <li><a className="" href={`/port/${userReduxData.user_name}`}>Portfolio</a></li>
                                    {/* <li><a className="" href="/start">Social Startup</a></li> */}
                                    {/* <li className="text-start pt-2">
                                        <span>Social Startup</span>
                                        <div className="ms-3 mb-2 drop-custom">
                                            <a href={`/social-startup/create`}>Initiate a Social Startup</a>
                                            <a className="p-1" href={`/social-startup/my`}>My Social Startup</a>
                                        </div>
                                    </li> */}
                                    {/* <li><a className="" href="/port/edit">Edit Portfolio</a></li> */}
                                    <li><a className="" href="/logout">Logout</a></li>
                                </ul>
                            </li>
                            :
                            <>
                            <li>
                                <a href="/login">Login</a>
                            </li>
                            <li>
                                <a href="/register">Register</a>
                            </li>
                            </>
                        }
                        </ul>
                    </div>
                    </div>
                </div>
                </nav>
            </div>
            </div>
        </div>
        </header>
        {/* <!-- header section ending here --> */}
        {/* <section>
        <div className="container">
            <div className="row mt-4 mb-5">
            <div className="col-md-3 col-12 col-sm-12">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
            </div>
            <div className="col-md-9 col-12 col-sm-12">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../assets/images/main/logo/img-ifslogan-eng.png')} title="International Foundation for Sustainable Peace and Development" />
            </div>
            </div>
        </div>
        </section> */}
        {/* <a className="screen-reader-text skip-link" href="#content" title="Skip to content">Skip to content</a>			
        <nav id="secondary-navigation" className="secondary-navigation has-top-bar" itemType="https://schema.org/SiteNavigationElement" itemScope="itemscope">
            <div className="inside-navigation grid-container grid-parent">
                <div className="top-bar">
                    <div className="inside-top-bar">
                    <aside id="text-10" className="widget inner-padding widget_text">
                        <div className="textwidget">
                            <p>secretariat@ifspd.butasbureau.nl &nbsp; +35924440033</p>
                        </div>
                    </aside>
                    </div>
                </div>
                <button className="menu-toggle secondary-menu-toggle">
                <span className="screen-reader-text">Menu</span>					</button>
                <div className="main-nav">
                    <ul id="menu-top-menu-en" className=" secondary-menu sf-menu">
                    <li id="menu-item-6465" className="pll-parent-menu-item menu-item menu-item-type-custom menu-item-object-custom current-menu-parent menu-item-has-children menu-item-6465">
                        <a href="#pll_switcher">Eng<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li id="menu-item-6465-en" className="lang-item lang-item-2 lang-item-en current-lang lang-item-first menu-item menu-item-type-custom menu-item-object-custom current_page_item menu-item-home menu-item-6465-en"><a href="index" hrefLang="en-GB" lang="en-GB">Eng</a></li>
                            <li id="menu-item-6465-ru" className="lang-item lang-item-5 lang-item-ru menu-item menu-item-type-custom menu-item-object-custom menu-item-6465-ru"><a href="ru/index" hrefLang="ru-RU" lang="ru-RU">Рус</a></li>
                        </ul>
                    </li>
                    </ul>
                </div>
            </div>
        </nav>
        <header className="site-header grid-container grid-parent" id="masthead" aria-label="Site"  itemType="https://schema.org/WPHeader" itemScope>
            <div className="inside-header grid-container grid-parent">
                <div className="site-logo">
                    <a href="index" title="International Foundation for Sustainable Peace and Development" rel="home">
                    <img  className="header-image is-logo-image" alt="International Foundation for Sustainable Peace and Development" src="wp-content/uploads/2018/12/ifspd_logo_m.png" title="International Foundation for Sustainable Peace and Development" srcSet="http://ifspd.butasbureau.nl/wp-content/uploads/2018/12/ifspd_logo_m.png 1x, http://ifspd.butasbureau.nl/wp-content/uploads/2018/12/ifspd_logo_m.png 2x" width="800" height="470" />
                    </a>
                </div>
            </div>
        </header> */}
        {/* <nav id="mobile-header" itemType="https://schema.org/SiteNavigationElement" itemScope className="main-navigation mobile-header-navigation has-branding has-sticky-branding">
            <div className="inside-navigation grid-container grid-parent">
                <div className="site-logo mobile-header-logo">
                    <a href="index" title="International Foundation for Sustainable Peace and Development" rel="home">
                    <img src="wp-content/uploads/2018/12/ifspd_logo_m.png" alt="International Foundation for Sustainable Peace and Development" className="is-logo-image" width="800" height="470" />
                    </a>
                </div>
                <button className="menu-toggle" aria-controls="mobile-menu" aria-expanded="false">
                <span className="mobile-menu">Menu</span>					</button>
                <div id="mobile-menu" className="main-nav">
                    <ul id="menu-main-menu-en" className=" menu sf-menu">
                    <li id="menu-item-83" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-83"><a href="index" aria-current="page">Home</a></li>
                    <li id="menu-item-51" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-51">
                        <a href="history/index">About<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li id="menu-item-65" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-65"><a href="history/index">History</a></li>
                            <li id="menu-item-1524" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1524"><a href="founders/index">Founders</a></li>
                            <li id="menu-item-1525" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1525"><a href="principles-and-objectives/index">Principles and Objectives</a></li>
                        </ul>
                    </li>
                    <li id="menu-item-52" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-52">
                        <a href="#">Organization<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li id="menu-item-1569" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569"><a href="presidential-committee/index">Presidential Committee</a></li>
                            <li id="menu-item-1584" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1584"><a href="board-members-and-vice-presidents/index">Board Members &#038; Vice-Presidents</a></li>
                            <li id="menu-item-1588" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1588"><a href="honorary-members-of-the-board/index">Honorary Members of the Board</a></li>
                            <li id="menu-item-2779" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2779"><a href="fully-fledged-and-associate-members/index">Fully-fledged &#038; Associate Members</a></li>
                            <li id="menu-item-1593" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1593"><a href="legal-entity/index">Legal Entity</a></li>
                            <li id="menu-item-1597" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1597"><a href="our-team/index">Our Team</a></li>
                            <li id="menu-item-2791" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2791"><a href="our-partners/index">Our Partners</a></li>
                        </ul>
                    </li>
                    <li id="menu-item-478" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-478"><a href="list/board-meetings/index">Events</a></li>
                    <li id="menu-item-480" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-480"><a href="list/projects/index">Projects</a></li>
                    <li id="menu-item-6862" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-6862">
                        <a href="statute/index">Membership<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li id="menu-item-6863" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6863"><a href="statute/index">Statute</a></li>
                            <li id="menu-item-6864" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6864"><a href="membership-applications/index">Membership Applications</a></li>
                        </ul>
                    </li>
                    <li id="menu-item-2615" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2615"><a href="gallery-board-meetings/index">Gallery</a></li>
                    <li id="menu-item-1756" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1756"><a href="contact/index">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav> */}
        {/* <nav className="main-navigation sub-menu-right" id="site-navigation" aria-label="Primary"  itemType="https://schema.org/SiteNavigationElement" itemScope>
            <div className="inside-navigation grid-container grid-parent">
                <button className="menu-toggle" aria-controls="primary-menu" aria-expanded="false">
                <span className="mobile-menu">Menu</span>				</button>
                <div id="primary-menu" className="main-nav">
                    <ul id="menu-main-menu-en-1" className=" menu sf-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-83"><a href="index" aria-current="page">Home</a></li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-51">
                        <a href="history/index">About<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-65"><a href="history/index">History</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1524"><a href="founders/index">Founders</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1525"><a href="principles-and-objectives/index">Principles and Objectives</a></li>
                        </ul>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-52">
                        <a href="#">Organization<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569"><a href="presidential-committee/index">Presidential Committee</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1584"><a href="board-members-and-vice-presidents/index">Board Members &#038; Vice-Presidents</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1588"><a href="honorary-members-of-the-board/index">Honorary Members of the Board</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2779"><a href="fully-fledged-and-associate-members/index">Fully-fledged &#038; Associate Members</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1593"><a href="legal-entity/index">Legal Entity</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1597"><a href="our-team/index">Our Team</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2791"><a href="our-partners/index">Our Partners</a></li>
                        </ul>
                    </li>
                    <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-478"><a href="list/board-meetings/index">Events</a></li>
                    <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-480"><a href="list/projects/index">Projects</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-6862">
                        <a href="statute/index">Membership<span role="presentation" className="dropdown-menu-toggle"></span></a>
                        <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6863"><a href="statute/index">Statute</a></li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6864"><a href="membership-applications/index">Membership Applications</a></li>
                        </ul>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2615"><a href="gallery-board-meetings/index">Gallery</a></li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1756"><a href="contact/index">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav> */}
    </>
  );
}

export default Header;