import React, {useEffect, useState} from "react";
import moment from "moment";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { useParams } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import NotFound from "../404";
import {LoaderMedium} from './../../components/loader';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import {linkHttp} from '../../helper/custom-function';
import FormDataPortfolio from "../../data/formData/portfolio";
import HubListScrollComp from "../../components/hub/list-scroll";
import ShareIconsComp from "../../components/form/share";
import MetaDecorator from "../../components/common/meta";
import { PortPublicBtn } from "../../components/portfolio/button";
const {docTypeIds} = FormDataPortfolio;

function PortfolioDetail(){
    const { profile_id } = useParams();
    SetMetaData("Portfolio", "", "");
    // let params = useParams();
    // const productIdParam = params.id || '';
    // var pageTitle = params.url;
	// pageTitle = urlToString(pageTitle);
	// SetMetaData(pageTitle, "", "");
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [pageLoading, setPageLoader] = useState(true);
    const [isPortPublic, setIsPortPublic] = useState(false);
    //Main Product Data
    const [mPortfolioData, setPortfolioData] = useState({});
    // const [portfolioLogo, setPortfolioLogo] = useState("");
    SetMetaData(typeof mPortfolioData.first_name !== "undefined" ? `${mPortfolioData.first_name} ${mPortfolioData.last_name ? mPortfolioData.last_name : ""}` : "", "", "");
    //Loader
    // const [tabKey, setTabKey] = useState("compo");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);

	//Get Portfolio Details
    const fetchPortfolioData = async() => {
        try{
            // console.log(profile_id, 'profile_id')
            // const portfolioApiData = await post_api_call(`portfolio/${profile_id}`, {});
            const portfolioApiData = await post_api_call(`portfolio/user/${profile_id}`, {fetch_type: 'profile'});
            console.log(portfolioApiData, 'portfolioApiDataportfolioApiDataportfolioApiData')
            if(portfolioApiData.status === 200){                    
                setPortfolioData(portfolioApiData.data);
                setIsPortPublic(portfolioApiData.data.visibility === 0 ? false : true);
                // if(portfolioApiData.data.logo){
                    // console.log(portfolioApiData.data.logo, 'portfolioApiData.data.logo')
                    // setPortfolioLogo(portfolioApiData.data.image);
                    // try{
                       //let blobt = await fetch(portfolioApiData.data.logo, { mode: 'no-cors',}).then(r => r.blob());
                        // console.log('blob', blobt)
                        // // setPortfolioLogo(blob);
                        // // let fileName = imageUrl.substring(blobt.lastIndexOf("/") + 1);
                        //                             let file = new File([blobt], "fileName", { type: blobt.type });
                        //                             setPortfolioLogo(URL.createObjectURL(file));
                        // const base64Logo = await fetch(portfolioApiData.data.logo, {
                        //                             mode: 'no-cors',
                        //                         })
                        //                         .then(response => response.blob())
                        //                         .then(blob => {
                        //                             const reader = new FileReader();
                        //                             reader.readAsDataURL(blob);
                        //                             return new Promise((res) => {
                        //                                 reader.onloadend = () => {
                        //                                     console.log(reader.result);
                        //                                     res(reader.result);
                        //                                 }
                        //                             });
                        //                         });
                        // setPortfolioLogo(base64Logo);
                        // console.log(base64Logo, 'base64Logobase64Logo')
                    // }catch(e){
                    //     console.log(e);
                    // }
                // }
                // SetMetaData(`${portfolioApiData.data.first_name} ${portfolioApiData.data.last_name}`, "", "");
            }else if(portfolioApiData.status === 404){
                setShowNotFoundPage(true);
            }
            setPageLoader(false);
        }catch(e){
            console.log(e);
            alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
            error_api_call({error: `portfolio/detail|fetchPortfolioData|${e}`});
        }
    }
	//Intial Fetching
	useEffect(() => {
        fetchPortfolioData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Download Portfolio
    const downloadPortfolio = async(e) => {
        try{
            e.preventDefault();
            // e.target.disabled = true;
            setGeneratingPdf(true);
            // await get_api_call('download/portfolio', {});
            // setGeneratingPdf(false);
            // return;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/download/portfolio`, {
                method: 'GET',
                type: 'no-cors',
                headers: {
                    Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
                    Accept: 'application/pdf'
                },
                responseType: 'blob'
            });
            if(response.status === 200){
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${mPortfolioData.first_name}${mPortfolioData.last_name ? `-${mPortfolioData.last_name}` : ""}.pdf`;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }else{
                alertResponseMessage({alert_type: 2, message: "Error downloading Portfolio! Please try after some times."});
            }
            setGeneratingPdf(false);
            // e.target.disabled = false;
            return;
            // setGeneratingPdf(true);
            // const pdfSection = document.getElementById('portfolio');
            // html2canvas(pdfSection, {useCORS: true}, {
            //     scrollY: -window.scrollY
            //   })
            // .then((canvas) => {
            //     const imgData = canvas.toDataURL('image/jpeg');
            //     const pdf = new jsPDF();
            //     pdf.setFontSize(16);
            //     pdf.setTextColor(80, 77, 78);
            //     let width = pdf.internal.pageSize.getWidth();
            //     let height = 0;
            //     let x = 0;
            //     const { clientHeight: h, clientWidth: w } = pdfSection;
            //     if(h > (w * 1.414)) {
            //         height = pdf.internal.pageSize.getHeight();
            //         x = (width - (width*(w/h))) / 4;
            //         width = (width - (x * 2));
            //     }
            //     pdf.addImage(imgData, 'png', x, 0, width, height);
            //     pdf.save("portfolio.pdf");
            //     setGeneratingPdf(false);
            // }).catch(err => {
            //     // console.log("Error in generating pdf: ", err);
            //     setGeneratingPdf(false);
            // });

            // var doc = new jsPDF('p', 'pt','a4',true);
            // doc.setFontSize(16);
            // doc.setTextColor(80, 77, 78);
            // html2canvas(pdfSection, {
            //     useCORS : true,
            //     onrendered: function(canvas) {
            //        var imgData = canvas.toDataURL('image/jpeg');
            
            //        doc.addImage(imgData, 'JPEG', 15, 0, 34, 37);
            //        console.log(imgData);
            //        pdfSection.append(canvas);
            //        doc.save('portfolio.pdf');
            //     }
            // });
        }catch(e){
            console.log(e)
            // e.target.disabled = false;
        }
    }

    return (
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    {/* Opportunities List */}
                    <HubListScrollComp />
                    
                    <div className="section-header section-header-1">
                        <h1 className="title">Portfolio</h1>
                    </div>

                    <div className="row justify-content-center team-detail m-1">
                        {/* Show Loader */}
                        {pageLoading 
                            ?
                                <div className="text-center mt-5 mb-5">
                                        <LoaderMedium size={45} />
                                </div>
                            :
                                <>
                                 {mPortfolioData && typeof mPortfolioData.first_name !== "undefined" && mPortfolioData.first_name !== '' && (
                                    <>
                                     <MetaDecorator
                                        description={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`}
                                        title={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`}
                                        imageUrl={mPortfolioData.logo}
                                        imageAlt={mPortfolioData.logo}
                                    />
                                    {mPortfolioData.is_owner && (
                                        <>
                                            {isPortPublic && (
                                                <div className="col-md-9 col-12 col-sm-12 pe-0">
                                                    <p className="float-end">
                                                        Share Portfolio:&nbsp;
                                                        <ShareIconsComp
                                                            title={`🌟 Proud to share my PORTfolio! 🌟\n📂 Check it out: https://${window.location.hostname}/port/${mPortfolioData.user_name}\nDiscover my journey of achievements, experiences, and growth. Explore how PORT helps students stand out!`}
                                                            // \uD83C\uDF1F
                                                            link={`${window.location.hostname}/port/${mPortfolioData.user_name}`}
                                                            subject={`Check out my Portfolio: ${mPortfolioData.first_name} ${mPortfolioData.last_name ? mPortfolioData.last_name: ''}`}
                                                            showAsItIs={true}
                                                        />
                                                    </p>
                                                </div>
                                            )}

                                            <div className="col-lg-4 col-md-9 col-12 col-sm-12 ps-0">
                                                <div className="float-end float-lg-start toggle-button pb-3 pt-md-1">
                                                    <PortPublicBtn
                                                        isPublic={isPortPublic}
                                                        updateVisibility={(state) => setIsPortPublic(state)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-9 col-12 col-sm-12 pe-0 pb-3">
                                                <div className="text-end mb-0">
                                                    <a href="/port/edit" rel="noreferrer"><button className="btn btn-primary bg-color-blue me-sm-3 mb-1"><i className="fa fa-edit"></i> Edit Portfolio</button></a>
                                                    <button className="btn btn-primary bg-color-blue mb-1 ms-2" disabled={generatingPdf ? true : false} onClick={(e) => downloadPortfolio(e)}><i className="fa fa-download"></i> Download Portfolio</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-md-9 col-12 col-sm-12 card">
                                        <div id="portfolio">
                                        <div className="d-flex m-2 mt-3">
                                            <div className="p-0 portfolio-img">
                                                <img src={mPortfolioData.logo} className="img-thumbnail" alt={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`} />
                                                {/* <img src={portfolioLogo} className="img-thumbnail" alt={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`} /> */}
                                            </div>
                                            <div className="ps-0 mt-1">
                                                <h2 className="ms-3 mb-1">
                                                {/* <a href="/portfolio-profile/Anam-Zahra.html" target="_blank" rel="noopener noreferrer"> */}
                                                    <strong style={{fontSize: 21}}>{mPortfolioData.first_name} {mPortfolioData.last_name}</strong>
                                                {/* </a> */}
                                                <p className="position">{mPortfolioData.position}</p>
                                                {/* {mPortfolioData.designation} */}
                                                <p className="country">{mPortfolioData.country}</p>
                                                {/* {mPortfolioData.gender}, */}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            {(mPortfolioData.headline || mPortfolioData.achievement || mPortfolioData.passion)
                                                &&
                                                <div className="mb-4">
                                                    <h3>About:</h3>
                                                    <div>
                                                        {mPortfolioData.headline} {mPortfolioData.achievement} {mPortfolioData.passion}
                                                    </div>
                                                </div>
                                            }
                                            {/* Education */}
                                                {(mPortfolioData.education_details && mPortfolioData.education_details.length > 0) && (
                                                <div className="mb-4">
                                                    <h3>Education:</h3>
                                                    <ol>
                                                    {mPortfolioData.education_details.map((edu_d, edu_index) => (
                                                        <li key={`education${edu_index}`}>
                                                            {/* <b>{edu_d.institute_name}</b>, {edu_d.program_name}{edu_d.achievement ? ` (${edu_d.achievement})` : ''}.
                                                            {(edu_d.start_year !== null)
                                                                && <> {edu_d.start_year} - {edu_d.end_year === null ? 'Present' : edu_d.end_year}</>
                                                            } */}
                                                            <p className="mb-2">
                                                                <span>
                                                                    <b>{edu_d.program_name}</b>&nbsp;{edu_d.institute_name !== 
                                                                '' && 'from'}&nbsp;<b>{edu_d.institute_name}</b>
                                                                </span>
                                                                <span className="t-light d-flex">{edu_d.achievement}</span>
                                                                <span className="d-flex t-light">{edu_d.start_year} - {edu_d.end_year === null ? 'Present' : edu_d.end_year}</span>
                                                                {mPortfolioData.is_owner && edu_d.doc_data.length > 0 && (
                                                                    <span className="d-flex">
                                                                        {edu_d.doc_data.map((doc_d, doc_indx) => (
                                                                            <a key={`link_${doc_indx}`} href={`${mPortfolioData.doc_url}doc/${docTypeIds.edu}/${doc_d.name}`} download className="bg-color-blue btn btn-primary delete fs-12p pb-1 pe-2 ps-1 ps-2 pt-1 me-1" target="_blank" rel="noreferrer">
                                                                            <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                                        </a>
                                                                        ))}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </li>
                                                    ))}
                                                    </ol>
                                                </div>
                                                )}
                                            {/* Experience */}
                                            {(mPortfolioData.experience_details && mPortfolioData.experience_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Experience:</h3>
                                                <ol>
                                                {mPortfolioData.experience_details.map((exp_d, index) => (
                                                    <li key={`experience_${index}`}>
                                                        {/* {exp_d.designation} - <b>{exp_d.organization}</b>{exp_d.achievement ? ` (${exp_d.achievement})` : ''}.
                                                        {(exp_d.start_date !== null)
                                                            && <> {moment(exp_d.start_date).format("MMM YYYY")} - {exp_d.end_date === null ? 'Present' : moment(exp_d.end_date).format("MMM YYYY")}</>
                                                        } */}
                                                        <p className="mb-2">
                                                            <span>
                                                                <b>{exp_d.designation}</b>&nbsp;{exp_d.organization !== 
                                                            '' && 'at'}&nbsp;<b>{exp_d.organization}</b>
                                                            </span>
                                                            <span className="t-light d-flex">{exp_d.achievement}</span>
                                                            <span className="d-flex t-light">{moment(exp_d.start_date).format("MMM YYYY")} - {exp_d.end_date === null ? 'Present' : moment(exp_d.end_date).format("MMM YYYY")}</span>
                                                            {mPortfolioData.is_owner && exp_d.doc_data.length > 0 && (
                                                                <span className="d-flex">
                                                                    {exp_d.doc_data.map((doc_d, doc_indx) => (
                                                                        <a key={`link_${doc_indx}`} href={`${mPortfolioData.doc_url}doc/${docTypeIds.exp}/${doc_d.name}`} download className="bg-color-blue btn btn-primary delete fs-12p pb-1 pe-2 ps-1 ps-2 pt-1 me-1" target="_blank" rel="noreferrer">
                                                                        <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                                    </a>
                                                                    ))}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                        {/* StartUp Projects */}
                                        {(mPortfolioData.social_details && mPortfolioData.social_details.length > 0) && (
                                                <div className="mb-4">
                                                <h3 className="mb-2">Social Start-ups:</h3>
                                                <ol>
                                                    {mPortfolioData.social_details.map((social_d, social_index) => (
                                                        <li key={`social_${social_index}`}>
                                                            <p className="mb-0">
                                                                <span><b>{social_d.name}</b></span>
                                                                <span className="t-light d-flex"><b>Problem</b>: {social_d.kpi}</span>
                                                                <span className="t-light d-flex"><b>Achievement</b>: {social_d.achievement}</span>
                                                                <span className="t-light d-flex"><b>Learnings</b>: {social_d.learning}</span>
                                                                <span className="t-light d-flex">{ moment(social_d.end_date).format("MMM YYYY")}</span>
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ol>
                                                </div>
                                            )}
                                            {/* Research */}
                                            {(mPortfolioData.research_details && mPortfolioData.research_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Projects & Papers:</h3>
                                                <ol>
                                                    {mPortfolioData.research_details.map((research_d, research_index) => (
                                                        <li key={`research_${research_index}`}>
                                                            <p className="mb-2">
                                                            <span>
                                                                <b>{research_d.title}</b>&nbsp;{research_d.domain !== 
                                                            '' && 'published at'}&nbsp;<b>{research_d.domain}</b>
                                                            </span>
                                                            {research_d.achievement && (
                                                                <span className="t-light d-flex"><b>Achievement</b>: {research_d.achievement}</span>
                                                            )}
                                                            {research_d.link && (
                                                                <span className="t-light d-flex"><b>Link&nbsp;</b>: <a href={linkHttp(research_d.link)}>{linkHttp(research_d.link)}</a></span>
                                                            )}
                                                            <span className="d-flex t-light">{research_d.year}</span>
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ol>

                                                {/* <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-13vw">Project Title</th>
                                                            <th className="w-13vw">Where was it published</th>
                                                            <th>Link</th>
                                                            <th>Achievement or accolade</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mPortfolioData.research_details.map((research_d, research_index) => (
                                                        <tr key={`research${research_index}`}>
                                                            <td>{research_d.title}</td>
                                                            <td>{research_d.domain}</td>
                                                            <td>{research_d.link ? <a href={linkHttp(research_d.link)} target="_blank" rel="noreferrer">{linkHttp(research_d.link)}</a>: ''}</td>
                                                            <td>{research_d.achievement}</td>
                                                            <td>{research_d.year}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                            )}
                                            {/* Sport */}
                                            {(mPortfolioData.sport_details && mPortfolioData.sport_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Sports & Competitions:</h3>
                                                <ol>
                                                    {mPortfolioData.sport_details.map((sport_d, sport_index) => (
                                                        <li key={`sport_${sport_index}`}>
                                                            <p className="mb-0">
                                                            <span>
                                                                <b>{sport_d.name}</b>&nbsp;{sport_d.venue !== 
                                                            '' && 'participated at'}&nbsp;<b>{sport_d.venue}</b>
                                                            </span>
                                                            <span className="t-light d-flex"><b>Achievement</b>: {sport_d.achievement}</span>
                                                            <span className="d-flex t-light">{sport_d.start_year} - {sport_d.end_year === null ? 'Present' : sport_d.end_year}</span>
                                                            {mPortfolioData.is_owner && sport_d.doc_data.length > 0 && (
                                                                <span className="d-flex">
                                                                    {sport_d.doc_data.map((doc_d, doc_indx) => (
                                                                        <a key={`link_${doc_indx}`} href={`${mPortfolioData.doc_url}doc/${docTypeIds.sport}/${doc_d.name}`} download className="bg-color-blue btn btn-primary delete fs-12p pb-1 pe-2 ps-1 ps-2 pt-1 me-1" target="_blank" rel="noreferrer">
                                                                        <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                                    </a>
                                                                    ))}
                                                                </span>
                                                            )}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ol>

                                                {/* <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th className="w-13vw">Competition Name</th>
                                                            <th className="w-13vw">Where did you participate</th>
                                                            <th>Achievement or accolade</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.sport_details.map((sport_d, sport_index) => (
                                                            <tr key={`sport${sport_index}`}>
                                                                <td>{sport_d.name}</td>
                                                                <td>{sport_d.venue}</td>
                                                                <td>{sport_d.achievement}</td>
                                                                <td>
                                                                    {(sport_d.start_year !== null)
                                                                        && <> {sport_d.start_year} - {sport_d.end_year === null ? 'Present' : sport_d.end_year}</>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                            )}
                                            {/* Honours and Awards */}
                                            {(mPortfolioData.award_details && mPortfolioData.award_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Leadership & Awards:</h3>
                                                <ol>
                                                    {mPortfolioData.award_details.map((award_d, award_index) => (
                                                        <li key={`award_${award_index}`}>
                                                            <p className="mb-0">
                                                                <span>
                                                                    <b>{award_d.award_name}</b>&nbsp;{award_d.institute_name !== 
                                                                '' && 'awarded by'}&nbsp;<b>{award_d.institute_name}</b>
                                                                </span>
                                                                <span className="t-light d-flex">{award_d.why_awarded}</span>
                                                                <span className="t-light d-flex">{award_d.year}</span>
                                                                {mPortfolioData.is_owner && award_d.doc_data.length > 0 && (
                                                                    <span className="d-flex">
                                                                        {award_d.doc_data.map((doc_d, doc_indx) => (
                                                                            <a key={`link_${doc_indx}`} href={`${mPortfolioData.doc_url}doc/${docTypeIds.award}/${doc_d.name}`} download className="bg-color-blue btn btn-primary delete fs-12p pb-1 pe-2 ps-1 ps-2 pt-1 me-1" target="_blank" rel="noreferrer">
                                                                            <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                                        </a>
                                                                        ))}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ol>
                                                {/* <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-13vw">Institution Name</th>
                                                                <th className="w-13vw">Award Name</th>
                                                                <th>Why was this awarded to you?</th>
                                                                <th className="last-col">Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.award_details.map((award_d, award_index) => (
                                                            <tr key={`award${award_index}`}>
                                                                <td>{award_d.institute_name}</td>
                                                                <td>{award_d.award_name}</td>
                                                                <td>{award_d.why_awarded}</td>
                                                                <td>{award_d.year}</td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                            )}
                                            {/* IFSPD Europe Global Sustainability Council Participation and Achievements */}
                                            {(mPortfolioData.glosco_data && mPortfolioData.glosco_data.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Global Sustainability Councils:</h3>
                                                <ol>
                                                {mPortfolioData.glosco_data.map((achievement_d, achieve_index) => (
                                                    <li key={`achieve${achieve_index}`}>
                                                        <p className="mb-0">
                                                            <span><b>{achievement_d.level}</b></span>
                                                            {achievement_d.indi_award.length > 0 && (
                                                            <span className="t-light d-flex">
                                                                <b>Individual Awards:&nbsp;</b>
                                                                {achievement_d.indi_award.map((indi_d, indi_index) => (
                                                                    <span key={`indi_award${indi_index}`}>
                                                                        {indi_d.award_name}
                                                                        {indi_index !== achievement_d.indi_award.length - 1 && (<>, &nbsp;</>)}
                                                                    </span>
                                                                ))}
                                                            </span>
                                                            )}
                                                            {achievement_d.team_award.length > 0 && (
                                                                <span className="t-light d-flex">
                                                                <b>Team Awards:&nbsp;</b>
                                                                    {achievement_d.team_award.map((team_d, team_index) => (
                                                                        <span key={`team_award${team_index}`}>
                                                                            {team_d.award_name}
                                                                            {team_index !== achievement_d.team_award.length - 1 && (<>, &nbsp;</>)}
                                                                        </span>
                                                                    ))}
                                                                </span>
                                                                )}
                                                            <span className="d-flex t-light">{achievement_d.year}</span>
                                                        </p>
                                                    </li>
                                                ))}
                                                </ol>

                                                {/* <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-13vw">Glosco Level Completed</th>
                                                            <th className="w-13vw">Individual Awards</th>
                                                            <th>Team Awards</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mPortfolioData.glosco_data.map((achievement_d, achieve_index) => (
                                                        <tr key={`achieve${achieve_index}`}>
                                                            <td>{achievement_d.level}</td>
                                                            <td>
                                                                {achievement_d.indi_award.map((indi_d, indi_index) => (
                                                                    <li key={`indi_award${indi_index}`}>
                                                                        {indi_index !== achievement_d.indi_award.length - 1 ? `${indi_d.award_name},` : indi_d.award_name}
                                                                    </li>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                {achievement_d.team_award.map((team_d, team_index) => (
                                                                    <li key={`team_award${team_index}`}>
                                                                        {team_index !== achievement_d.team_award.length - 1 ? `${team_d.award_name},` : team_d.award_name}
                                                                    </li>
                                                                ))}
                                                            </td>
                                                            <td>{achievement_d.year}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                            )}
                                            {/* Unique Skills */}
                                            {(mPortfolioData.unique_skills && mPortfolioData.unique_skills.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Unique Competencies and Career Mapping:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-13vw">Skill</th>
                                                                {/* <th>Defining Attributes</th> */}
                                                                <th>Unique Abilities</th>
                                                                {/* <th>Main Motivation</th>
                                                                <th>Potential Skills</th>
                                                                <th>Suitable Role</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.unique_skills.map((passion_d, passion_index) => (
                                                            <tr key={`passion${passion_index}`}>
                                                                <td><b>{passion_d.title}</b></td>
                                                                {/* <td>{passion_d.defining_attribute}</td> */}
                                                                <td>{passion_d.unique_ability}</td>
                                                                {/* <td>{passion_d.main_motivation}</td>
                                                                <td>{passion_d.potential_skill}</td>
                                                                <td>{passion_d.suitable_role}</td> */}
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* Career Skills */}
                                            {(mPortfolioData.carrer_skill_details && mPortfolioData.carrer_skill_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Career Skills:</h3>
                                                <ul className="d-flex">
                                                    {/* <li> */}
                                                        {mPortfolioData.carrer_skill_details.map((c_skill_d, career_index) => (
                                                        <li key={`career_skill${career_index}`} className="me-4 me-md-5">
                                                            {/* {career_index === 0 ? c_skill_d.skill_name : `, ${c_skill_d.skill_name}`} */}
                                                            {c_skill_d.skill_name}
                                                        </li>
                                                        ))}
                                                    {/* </li> */}
                                                </ul>
                                            </div>
                                            )}
                                            {/* Personal Skills */}
                                            {(mPortfolioData.personal_skill_details && mPortfolioData.personal_skill_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Personal Skills:</h3>
                                                <ul className="d-flex">
                                                    {/* <li> */}
                                                        {mPortfolioData.personal_skill_details.map((p_skill_d, personal_index) => (
                                                        <li key={`personal_skill${personal_index}`} className="me-4 me-md-5">
                                                            {/* {personal_index === 0 ? p_skill_d.skill_name : `, ${p_skill_d.skill_name}`} */}
                                                            {p_skill_d.skill_name}
                                                        </li>
                                                        ))}
                                                    {/* </li> */}
                                                </ul>
                                            </div>
                                            )}
                                            {/* Life Exp */}
                                            {(mPortfolioData.life_exp_details && mPortfolioData.life_exp_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Life Altering Moments:</h3>
                                                <ol>
                                                    {mPortfolioData.life_exp_details.map((life_exp, life_index) => (
                                                        <li key={`life_${life_index}`}>
                                                            {/* <p className="mb-0"> */}
                                                                <p className="mb-1"><b>Experience</b>: {life_exp.experience}</p>
                                                                <p className="mb-1 t-light"><b>Impact on Life</b>: {life_exp.impact}</p>
                                                                {life_exp.learning && (<p className="mb-1 t-light"><b>Learnings</b>: {life_exp.learning}</p>)}
                                                                {life_exp.theme_data.length > 0 && (
                                                                    <span className="d-flex t-light">
                                                                        <b>Experience Themes:&nbsp;</b>
                                                                        {life_exp.theme_data.map((theme_d, theme_index) => (
                                                                            <span key={`theme_${theme_index}`}>
                                                                                {theme_d.label}
                                                                                {theme_index !== life_exp.theme_data.length - 1 && (<>,&nbsp;</>)}
                                                                            </span>
                                                                        ))}    
                                                                    </span>
                                                                )}
                                                                {life_exp.emotion_data.length > 0 && (
                                                                    <span className="d-flex t-light">
                                                                        <b>Emotions:&nbsp;</b>
                                                                        {life_exp.emotion_data.map((emo_d, emo_index) => (
                                                                            <span key={`emo_${emo_index}`}>
                                                                                {emo_d.label}
                                                                                {emo_index !== life_exp.emotion_data.length - 1 && (<>,&nbsp;</>)}
                                                                            </span>
                                                                        ))}    
                                                                    </span>
                                                                )}
                                                                {mPortfolioData.is_owner && life_exp.doc_data.length > 0 && (
                                                                    <span className="d-flex">
                                                                        {life_exp.doc_data.map((doc_d, doc_indx) => (
                                                                            <a key={`link_${doc_indx}`} href={`${mPortfolioData.doc_url}doc/${docTypeIds.lifeExp}/${doc_d.name}`} download className="bg-color-blue btn btn-primary delete fs-12p pb-1 pe-2 ps-1 ps-2 pt-1 me-1" target="_blank" rel="noreferrer">
                                                                            <span className="fw-bold color-white"><i className="fa fa-download"></i></span>
                                                                        </a>
                                                                        ))}
                                                                    </span>
                                                                )}
                                                            {/* </p> */}
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Languages */}
                                            {(mPortfolioData.language) && (
                                            <div className="mb-4">
                                                <h3>Languages Known: <span>{mPortfolioData.language}</span></h3>
                                            </div>
                                            )}
                                            {/* Recommend */}
                                            {(mPortfolioData.recommend_details && mPortfolioData.recommend_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3 className="mb-2">Letter of Recommendation:</h3>
                                                <ol>
                                                    {mPortfolioData.recommend_details.map((recom_d, recom_index) => (
                                                        <span key={`recom_${recom_index}`}>
                                                        {recom_d.quality && (
                                                        <li>
                                                            {/* <p className="mb-0"> */}
                                                                <p className="mb-1">
                                                                    <b>Applicant’s personal qualities:</b>&nbsp;{recom_d.quality}
                                                                </p>
                                                                {recom_d.observation && (
                                                                    <p className="mb-1">
                                                                        <b>Insights on interactions and challenges handling:</b>&nbsp;{recom_d.observation}
                                                                    </p>
                                                                )}
                                                                {recom_d.academic && (
                                                                    <p className="mb-1">
                                                                        <b>Academic and non-academic strengths:</b>&nbsp;{recom_d.academic} 
                                                                    </p>
                                                                )}
                                                                {recom_d.strength && (
                                                                    <p className="mb-1">
                                                                        <b>Any additional info:</b>&nbsp;{recom_d.strength}
                                                                    </p>
                                                                )}
                                                                <p className="mb-1">
                                                                <span className="t-light d-flex"><b>Recommended by</b>- {recom_d.name}, {recom_d.designation}</span>
                                                                </p>
                                                            {/* </p> */}
                                                        </li>
                                                        )}
                                                        </span>
                                                    ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Email */}
                                            {(mPortfolioData.is_owner && mPortfolioData.email) && (
                                            <div className="mb-4">
                                                <h3>Email: <span>{mPortfolioData.email}</span></h3>
                                            </div>
                                            )}
                                            {/* Mobile Number */}
                                            {(mPortfolioData.is_owner && mPortfolioData.mobile_number) && (
                                            <div className="mb-4">
                                                <h3>Mobile Number: <span className="text-black">+ {mPortfolioData.phone_code} {mPortfolioData.mobile_number}</span></h3>
                                            </div>
                                            )}
                                        </div>
                                        {/* <div className="p-2" dangerouslySetInnerHTML={ { __html: portfolioData[profile_id].detail}}> */}
                                            {/* {portfolioData[profile_id].detail} */}
                                        {/* </div> */}
                                        </div>
                                    </div>
                                    </>
                                )}
                                {showNotFoundPage && (
                                    <NotFound type="portfolio" />
                                )}
                                </>
                        }
                    </div>
                </div>
            </section>
        </main>
    )    
}

export default PortfolioDetail;