import axios from "axios";
// import {errorResponse} from "./response";
const apiBaseUrl = process.env.REACT_APP_ASKEVA_URL;
const headers = {
  'Content-Type': 'application/json',
};
/*
 * Fetch Details
 */
export const eva_post_api_call = async(api, token, payload) => {
  try{
    headers['Authorization'] = `Bearer ${token}`;
    const data = await axios.post(`${apiBaseUrl}/${api}`, payload, {headers});
    return {status: 200, data};
  }catch(error){
    return {status: 500};
  }
};
/*
 * Fetch Details by Get Method
 */
export const eva_get_api_call = async (api, token, payload) => {
  var response = {status : 500, data: {}};
  try{
    headers['Authorization'] = `Bearer ${token}`;
    const data = await axios.get(`${apiBaseUrl}/${api}`,
                                  {params: payload,
                                  headers});
    // return data.data;
    return {status: 200, data};
  }catch(error){
    return response;
  }
};