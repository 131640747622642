import { useEffect, useRef, useState, useMemo, useCallback} from "react";
// import moment from "moment";
import ChatbotIcon from "./chatbot-icon";
import ChatbotForm from "./chatbot-form";
import ChatbotMessage from "./chatbot-message";
import AuthCheck from "../hoc/auth";
// import { companyInfo } from "./companyInfo";
import './../../assets/css/chat.css';
// import { alertResponseMessage } from "../../actions/action-creators/response";
import { ChatLoader } from "../loader";
import { post_api_call , error_api_call} from "../../actions/action-creators/common";
import { eva_get_api_call, eva_post_api_call } from "../../actions/action-creators/askeva";
import { welMsgEva, errorMsgEva, guestMsgEva, inactiveMsgEva} from "../../data/formData/askEva";
import { getRandomInteger } from "../../helper/custom-function";
// const errorMsg = "Error Connecting! Please talk to me after some times";
// const noDetailsMsg = "The more we know about your unique strengths, education, experiences, and achievements, the better advice we can give. Fill in these PORT sections to unlock accurate, personalized guidance!";

const Chatbot = (props) => {
  const chatBodyRef = useRef();
  const [showChatbot, setShowChatbot] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [unreadMsgCount, setUnreadCount] = useState(1);
  const [aEvaToken, setaEvaToken] = useState('');
  const [tokenExpiryTime, setExpiryTime] = useState('');
  const [tokenStorageTime, setStorageTime] = useState('');
  const [chatSession, setChatSession] = useState('');
  const [chatHistory, setChatHistory] = useState([
    // {
    //   hideInChat: true,
    //   speaker: "EVA",
    //   text: termsCondition,
    // },
  ]);
  const [userInfo, setUserInfo] = useState(null);
  const [opportunityData, setOpportunityData] = useState([]);
  const [expandChatbot, setExpandChatbot] = useState(false);
  //UI-to Resize
  // const [chatBoxwidth, setBoxWidth] = useState(375);
  // const [initialPos,   setInitialPos] = useState(null);
  // const [initialSize, setInitialSize] = useState(null);
  // const channel = new BroadcastChannel("eva-message");
  const evaBroadcast = useMemo(() => new BroadcastChannel('eva-message'), []);
  //Call API directly on "Ask EVA" button click
  useEffect(() => {
    if(props.chat_user_data?.call_direct_api > 0){
        userAuthCheck("");
    }
  }, [props.chat_user_data?.call_direct_api]);
  //Fetch User Info
  useEffect(() => {
    if(props.is_logged){
      setisLoading(true);
      var isLoadCustomMsg = false;
      if(userInfo === null
          && props.window_path !== "/port/edit"
      ){
          const fetchPortfolioData = async() => {
              try{
                  const portfolioApiData = await post_api_call(`portfolio-details`, {fetch_type: 'edit'});
                  if(portfolioApiData.status === 200){
                      //AskEVA
                      setUserInfo(portfolioApiData.data);
                      isLoadCustomMsg = true;
                  }
                  setisLoading(false);
              }catch(error){
                  console.error(error);
                  // alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
                  error_api_call({error: `component/askeva/chatbot|fetchPortfolioData|${error}`});
                  setisLoading(false);
              }
          }
          fetchPortfolioData();
      }else{
        setisLoading(false);
      }
      //Fetch Opportunity List
      if(opportunityData.length === 0){
          const fetchOppoData = async() => {
              try{
                  const oppApiData = await post_api_call(`opportunities`, {});
                  if(oppApiData.status === 200){
                    setOpportunityData(oppApiData.data);
                  }
              }catch(error){
                  console.error(error);
                  // alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
                  error_api_call({error: `component/askeva/chatbot|fetchOppoData|${error}`});
              }
          }
          fetchOppoData();
      }
      //Fetch Previous Chat
      //Get Session Id and Token from localstorage
      const aeSessId = localStorage.getItem("ae_session");
      var token = localStorage.getItem("ae_token");
      const tokenExpTime = localStorage.getItem("ae_token_dt");
      const tokenCreatedTime = localStorage.getItem("ae_init_dt");
      const currentTime = Math.floor(Date.now() / 1000);//milliseconds
      //Check if it is expired
      if(token
        && aeSessId
        && tokenExpTime
        && tokenCreatedTime
        && (currentTime - tokenCreatedTime) <= 7200//2 hours
        // && tokenExpTime > currentTime
      ){
        //Load History
        const loadChatHistory = async() => {
          try{
            setisLoading(true);
            if(tokenExpTime < currentTime){//Expired
              //Generate Token
              const fetchToken = await generateToken();
              if(fetchToken.token){
                token = fetchToken.token;
              }
            }else{
              setaEvaToken(token);
              setExpiryTime(tokenExpTime);
            }
            setIsNew(false);
            setChatSession(aeSessId);
            setStorageTime(tokenCreatedTime);
            if(token){
              const apiData = await eva_get_api_call(`user/messages/${aeSessId}`, token, {});
              if(apiData.status === 200
                && apiData.data.data.length
              ){
                // setChatHistory(apiData.data.data.reverse());
                initialChatMsg(apiData.data.data.reverse());
              }else{
                //Custom Message
                // initialChatMsg();
              }
            }else{
              //Custom Message
              // initialChatMsg();
            }
            setisLoading(false);
          }catch(error){
              console.error(error);
              setisLoading(false);
              error_api_call({error: `component/askeva/chatbot|loadChatHistory|${error}`});
          }
        }
        loadChatHistory();
      }else{
        // isLoadCustomMsg = true;
        setisLoading(false);
        localStorage.removeItem("ae_token");
        localStorage.removeItem("ae_token_dt");
        localStorage.removeItem("ae_session");
        localStorage.removeItem("ae_init_dt");
      }
      //Custom Message
      if(isLoadCustomMsg){
        initialChatMsg();
      }
    }
  }, [props.is_logged]);
  //Send Message to opend tab
  useEffect(() => {
    // evaBroadcast.addEventListener("message", e => {
    //   console.log(e.data);
    //   console.log('tet')
    // });
    const handleBroadMsg = (e) => {
      if(e.currentTarget.name === 'eva-message'){
        // setChatHistory([...chatHistory, {...e.data}]);
        setChatHistory((history) => [...history, {...e.data}]);
      }
    }
  
    evaBroadcast.addEventListener("message", handleBroadMsg);
    return () => evaBroadcast.removeEventListener("message", handleBroadMsg);
  }, []);
  //Messaged if logged Out
  useEffect(() => {
    // if(!chatHistory.length){
      initialChatMsg();
    // }
  }, [!props.is_logged]);
  //Initial Message in box
  const initialChatMsg = (data=[]) => {
    try{
       // setChatHistory((history) => [...history, { speaker: "EVA", text: `Hey ${props.logged_user_info?.first_name} 👋,\n${noDetailsMsg}`, time: timeNow }]);
      var initMsg = '';
      if(props.is_logged){
        // console.log('logged', props.is_logged)
        // console.log(userInfo, 'userInfo')
          // console.log(props, 'props.completed_sec')
        if((props.window_path === '/port/edit' && removeSections(props.completed_sec).length === 0)
          || (userInfo && removeSections(userInfo.form_details.section_ids, 'string').length === 0)
        ){
          initMsg = getStaticMsg('inactive');
          // initMsg += `<br/><br/><a href="/port/edit"><b>Click Edit Portfolio</b> and start filling your details</a><br/>`;
          initMsg += `<br/><br/><b>Click Edit Portfolio and start filling your details</b><br/>`;
        }else{
          initMsg = getStaticMsg('welcome');
        }
      }else{
        initMsg = getStaticMsg('guest');
        initMsg += `<br/>
                <a href="/login">Click here to <b>Login</b></a><br/>
                <a href="/register">Click here to <b>Register</b></a>`;
      }
      if(initMsg){
        if(data.length){
          setChatHistory([...data, { speaker: "EVA", text: initMsg, createdAt: getNowTimestamp()}]);
        }else{
          setChatHistory([{ speaker: "EVA", text: initMsg, createdAt: getNowTimestamp()}]);
        }
      }else if(data.length){
        
      }
    }catch(error){
      console.error(error);
    }
  }
  //Submit Bot Form
  const userAuthCheck = async(message) => {
    try{
        if(!showChatbot){
          setShowChatbot(true);
        }
        var isUserMsg = true;
        // Update chat history with the user's message
        // const timeNow = moment().format('hh:mm A');
        // const timeNow = Math.floor(Date.now() / 1000);
        const timeNow = getNowTimestamp();
        if(message){
          updateChatHistory({ speaker: "USER", text: message, createdAt: timeNow });
          // return;
        }else if(props.window_path === '/port/edit'){
          if(removeSections(props.completed_sec).length === 0){
            const inActiveMessage = getStaticMsg('inactive');
            updateChatHistory({ speaker: "EVA", text: inActiveMessage, createdAt: timeNow });
            // setChatHistory((history) => [...history, { speaker: "EVA", text: `Hey ${props.logged_user_info?.first_name} 👋,\n${noDetailsMsg}`, time: timeNow }]);
            if(!showChatbot){
              setUnreadCount(unreadMsgCount+1);
            }
            props.activateEvaBtn();
            return;
          }else if(props.chat_user_data.context.currentContext.currentlyViewing){
            message = `EVA, Can you assist me in filling out **'${props.chat_user_data.context.currentContext.currentlyViewing}'**`;
            // updateChatHistory({ speaker: "USER", text: `EVA, Can you assist me in filling out <b>'${props.chat_user_data.context.currentContext.currentlyViewing}'</b>`, createdAt: timeNow });
            updateChatHistory({ speaker: "USER", text: message, createdAt: timeNow });
            isUserMsg = false;
          }
        }
        setisLoading(true);
        var token = '';
        const currentTime = Math.floor(Date.now() / 1000);
        //Check if token present and not Expired
        if(aEvaToken){
            if (tokenExpiryTime && tokenExpiryTime > currentTime) {//Valid
                token = aEvaToken;
            } 
        }
        //If token not present or expired
        if(!token){
            //Generate Token
            const fetchToken = await generateToken();
            if(fetchToken.token){
              token = fetchToken.token;
              if(!tokenStorageTime){
                localStorage.setItem("ae_init_dt",  currentTime);
                setStorageTime();
              }
            }
        }
        // setisLoading(false);//delete
        // props.activateEvaBtn();//delete
        // return;//delete
        //Call Send Message API
        if(token){
          await sendMessage(message, token, isUserMsg);
        }else{
          throw new Error("Something went wrong!");
        }
        setisLoading(false);
    }catch(error){
        console.error(error);
        setisLoading(false);
        // alertResponseMessage({alert_type: 2, message: "Error Connecting! Please talk to AskEVA after some times"});
        handleErrorMessage();
    }
    // // Delay 600 ms before showing "Thinking..." and generating response
    // setTimeout(() => {
    //   // Add a "Thinking..." placeholder for the bot's response
    //   setChatHistory((history) => [...history, { speaker: "EVA", text: "Thinking..." }]);

    //   // Call the function to generate the bot's response
    //   generateBotResponse([...chatHistory, { speaker: "USER", text: `Using the details provided above, please address this query: ${userMessage}` }]);
    // }, 600);
  };
  //Send Message
  const sendMessage = async(message, token, isUserMsg=true) => {
    try{
        var chatPayload = {
            message,
            isNew,
            session: chatSession,
            userInformation: props.chat_user_data?.userInformation || userInfo,
            context: {...props.chat_user_data?.context, 
                      opportunities: opportunityData
                    },
        };
        const apiData = await eva_post_api_call('chat', token, chatPayload);
        if(apiData.status === 200){
          if(isNew){
              setIsNew(false);
              setChatSession(apiData.data.data.session);
              localStorage.setItem("ae_session",  apiData.data.data.session);
          }
          if(!isUserMsg){
              props.activateEvaBtn();
          }
          generateBotResponse(apiData.data.data);
          // if(!message && !showChatbot){
          if(!showChatbot){
              setUnreadCount(unreadMsgCount+1);
          }
          return {success: true, data: apiData.data.data};
        }else{
            throw new Error("Something went wrong!")
        }
    }catch(error){
        console.error(error)
        handleErrorMessage();
        error_api_call({error: `components|askeva|chatbot|sendMessage|${error}`});
        return {success: false};
    }
  }
  //Generate Token
  const generateToken = async() => {
    try{
        const apiData = await post_api_call('generate-token/askeva', {});
        var token = '';
        if(apiData.status === 200){
            token = apiData.token;
            setaEvaToken(apiData.token);
            setExpiryTime(apiData.expiry_time);
            localStorage.setItem("ae_token",  apiData.token);
            localStorage.setItem("ae_token_dt",  apiData.expiry_time);
        }else{
            // handleErrorMessage();
            throw new Error("Something went wrong!")
        }
        return {token};
    }catch(error){
        console.error(error, 'error')
        handleErrorMessage();
        error_api_call({error: `components|askeva|chatbot|generateToken|${error}`});
        return {token: ''};
    }
  }
  //Error Message
  const handleErrorMessage = async() => {
    try{
        const message = getStaticMsg('error');
        updateChatHistory( 
            { speaker: "EVA",
              // text: errorMsg,
              text: message,
              isError: true,
              // time: moment().format('hh:mm A')
              createdAt: getNowTimestamp()
            });
    }catch(error){
        error_api_call({error: `components|askeva|chatot|generateToken|${error}`});
        return;
    }
  }
  const generateBotResponse = async (data) => {
    // Helper function to update chat history
    // const updateHistory = (text, isError = false) => {
    //   setChatHistory((prev) => [...prev.filter((msg) => msg.text != "Thinking..."), { speaker: "EVA", text, isError }]);
        // setChatHistory((history) => [...history, { speaker: "EVA", text, time: moment().format('hh:mm A'), isError}]);
    // };
    try {
        // var apiResponseText = "Sure! Here are a few opportunities tailored to your profile:\n\n1. **Davos Worldwide - Critical Conversations**: It's an online event focusing on engaging young speakers aged 15 to 25 in discussions about global issues. The application deadline is January 10, 2025, and there's no participation cost.\n\n2. **KECTIL Global Youth Fellowship**: This prestigious fellowship is open to individuals aged 15 to 26, aimed at fostering global youth leadership. Make sure to apply by December 25, 2025.\n\n3. **SCM Youth Fellowship**: A premier fellowship open to enthusiastic youth between 10 and 25 years old, with an application deadline of December 25, 2024.\n\nEach of these offers a unique platform to express your passion for history and leadership. Let me know if you need help with the application process or more details about these opportunities!";
        // .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>").trim()
        var apiResponseText = data.response.Message;
        var bold = /\*\*(.*?)\*\*/gm;
        apiResponseText = apiResponseText.replace(bold, '<strong>$1</strong>');
        updateChatHistory({ speaker: "EVA", text: apiResponseText, createdAt: getNowTimestamp()});
        // updateHistory(apiResponseText);
    } catch (error) {
        console.error(error)
      // Update chat history with the error message
        handleErrorMessage();
    }
  };
  //Update Chat History State
  const updateChatHistory = (message_data) => {
    try{
      setChatHistory((history) => [...history, {...message_data}]);
      evaBroadcast.postMessage(message_data);
    }catch(error){
      console.error(error);
    }
  }
  // Auto-scroll whenever chat history updates
  useEffect(() => {
    chatBodyRef.current.scrollTo({ top: chatBodyRef.current.scrollHeight, behavior: "smooth" });
  }, [chatHistory]);
  //Remove Port Section to check if something entered
  const removeSections = (array, type="array") => {
    const itemToRemove = [1, 2];
    if(type === 'string'){
        array = array.split(",");
    }
    array = array.map(Number);
    return array.filter(v => { 
        return !itemToRemove.includes(v); 
    });
  }
  //Get Static Message
  const getStaticMsg = (type) => {
    try{
      var min = 0;
      var max = 9;//Error by default
      //Random array Key
      switch (type) {
        case 'welcome'://General Msg
            min = 0;
            max = 12;
            break;
        case 'guest'://not logged in 
            min = 0;
            max = 12;
            break;
        case 'inactive'://without section filled
            min = 0;
            max = 14;
            break;
        default: 
      }
      //Get Random Key
      const msgKey = getRandomInteger(min, max);
      var message = '';
      //Get Message
      switch (type) {
        case 'welcome'://General Msg
            message = welMsgEva[msgKey];
            break;
        case 'guest'://not logged in 
            message = guestMsgEva[msgKey];
            break;
        case 'inactive'://without section filled
            message = inactiveMsgEva[msgKey];
            break;
        case 'error'://error
            message = errorMsgEva[msgKey];
            break;
        default: 
          // message = errorMsgEva[msgKey];
      }
      if(!message){
        getDefaultMsg(type);
      }else{
        return message;
      }
    }catch(error){
      console.error(error);
      getDefaultMsg(type);
    }
  }
  //Get default Message
  const getDefaultMsg = (type) => {
    try{
      var message = '';
      //Get Message
      switch (type) {
        case 'welcome'://General Msg
            message = "Whether you're exploring majors, shortlisting colleges, applying for scholarships, or just worried and need someone to listen, we're here for you—judgment-free. Start talking to me now!";
            break;
        case 'guest'://not logged in 
            message = "Hey there 👋, Whether you're exploring majors, shortlisting colleges, applying for scholarships, or just worried and need someone to listen, we're here for you—judgment-free. Log in to Port to start talking now!";
            break;
        case 'inactive'://without section filled
            message = "Get Advice That’s Truly Yours! The more we know about your unique strengths, education, experiences, and achievements, the better advice we can give. Fill in these PORT sections to unlock accurate, personalized guidance!";
            break;
        case 'error'://not logged in 
            message = "Oh no, I stepped out for a quick coffee break! Try me again in a bit—I’ll be back soon!";
            break;
        default: 
      }
      return message;
    }catch(error){
      console.error(error);
      return '';
    }
  }
  //Get Current timestamp
  const getNowTimestamp = () => {
    return Date.now();
  }
  //Resize Chat Box
  // const dragHandler = useCallback(
  //   (e) => {
  //     let body = document.querySelector("body");

  //     // var offsetRight = container.width() - (e.clientX - container.offset().left);
  //     // var offsetRight = body.width() - (e.clientX - body.offset().left);
  //     // console.log(offsetRight, 'offsetRightoffsetRight')
  //     // document.body.getBoundingClientRect()
  //     // const currentPos = elementLeft / document.body.getBoundingClientRect().width * 100;
  //     const w =
  //       ((e.clientX -
  //         document.body.getBoundingClientRect()
  //           .left) /
  //         document.body.getBoundingClientRect()
  //           .width) *
  //       100;
  //     if (w > 0 && w !== chatBoxwidth) {
  //       setBoxWidth(w);
  //     }
  //     console.log(w, 'wwww')
  //   },
  //   [chatBoxwidth]
  // );
  // const initial = (e) => {
  //   let resizable = document.getElementById('bot_popup');
  //   setInitialPos(e.clientX);
  //   setInitialSize(resizable.offsetWidth);
  // }
  // const resize = (e) => {
  //   let resizable = document.getElementById('bot_popup');
  //   resizable.style.width = `${parseInt(initialSize) + parseInt(e.clientX - initialPos)}px`;
  // }
  return (
    <div className="ev-chat">
    <div className={`bot-container ${showChatbot ? "show-bot" : ""}`}>
      {!showChatbot && (
        <>
        {/* Button for Large Screen */}
        <div className="d-none d-md-flex">
        {/* <img src={require("./../../assets/images/icon/7.gif")} alt="Ask Icon" /> */}
        {/* <img src={require("./../../assets/images/icon/100.gif")} alt="Ask Icon" /> */}
          <button onClick={() => {setShowChatbot((prev) => !prev); setUnreadCount(0)}} className="bot-toggler">
          {/* <i class="fa-solid fa-messages-question"></i> */}
            {unreadMsgCount > 0 && (<span className="unread-badge">{unreadMsgCount}</span>)}
            <span>
              {/* <img src={require("./../../assets/images/icon/eva-icon.gif")} alt="Ask Icon" style={{width: '90%'}}/> */}
              <video width="85" height="85" autoPlay loop muted >
                <source src={require("./../../assets/images/icon/1.mp4")} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </span>
            {/* <span className="fa fa-close"></span> */}
            <span className="ev-label">
              {/* <img alt="AskEVA logo" src={require("../../assets/images/icon/askeva-logo.jpg")} /> */}
              Ask EVA</span>
            <span></span>
          </button>
        </div>
        {/* Button for Small Screen */}
        <div className="d-flex d-md-none">
          <button onClick={() => {setShowChatbot((prev) => !prev); setUnreadCount(0)}} className="bot-toggler-mob">
            {unreadMsgCount > 0 && (<span className="unread-badge">{unreadMsgCount}</span>)}
            <span className="ev-label"><img alt="AskEVA logo" src={require("../../assets/images/icon/askeva-logo.jpg")} /> Ask EVA</span>
            <span></span>
          </button>
        </div>
        </>
      )}
    <div>
    {/* <div id="handle_resize"
          draggable
          onDrag={dragHandler}
        ></div> */}
      <div
        className={`bot-popup ${expandChatbot ? "fullscreen" : ""}`}
        id="bot_popup"
        // style={{width: 100 - chatBoxwidth}}
        // draggable   = 'true'
        // onDragStart = {initial} 
        // onDrag      = {resize}
      >
        {/* Chatbot Header */}
        <div className="chat-header">
          <div className="header-info">
            <ChatbotIcon />
            <h2 className="logo-text">Ask EVA<br/><span>Your Friendly Counsellor</span></h2>
          </div>
          <div className="d-flex">
            <div className="me-3 ms-1 mb-2">
            <button onClick={() => setExpandChatbot((prev) => !prev)}>
              {expandChatbot
                ?
                  <i className="fa fa-compress"></i>
                :
                  <i className="fa fa-expand"></i>
              }
            </button>
            </div>
            <div className="">
            <button onClick={() => {setShowChatbot((prev) => !prev); setExpandChatbot(false)}}>
              <i className="fa fa-close"></i>
            </button>
            </div>
          </div>
        </div>

        {/* Chatbot Body */}
        <div ref={chatBodyRef} className="chat-body">
          {/* <div className="message bot-message">
            <ChatbotIcon />
            <p className="message-text">
            {props.is_logged
            ?   
                <>
                {(props.window_path === '/port/edit' && removeSections(props.completed_sec).length === 0)
                    || (userInfo && removeSections(userInfo.form_details.section_ids, 'string').length === 0)
                ?   
                    <> 
                        {getStaticMsg('inactive')}
                        {props.window_path !== '/port/edit' && (
                            <>
                            <br/><br/>
                            <a href="/port/edit">Click here to <b>Fill your Details</b></a><br/>
                            </>
                        )}
                    </>
                :
                    <>
                        {getStaticMsg('welcome')}
                    </>
                }
                </>
            :
                <>
                  {getStaticMsg('guest')} 
                  <br/>
                  <a href="/login">Click here to <b>Login</b></a><br/>
                  <a href="/register">Click here to <b>Register</b></a>
                </>
            }
            <span className="chat-time">{moment().format('hh:mm A')}</span>
            </p>
          </div> */}

          {/* Render the chat history dynamically */}
          {chatHistory.map((chat, index) => (
            <ChatbotMessage
              key={index}
              chat={chat}
            />
          ))}

          {/* Loader */}
          {isLoading && (
            <div className="message bot-message">
                <ChatbotIcon />
                <ChatLoader />
            </div>
          )}
        </div>

        {/* Chatbot Footer */}
        <div className="chat-footer">
          <ChatbotForm
            // chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            // generateBotResponse={generateBotResponse}
            sendMessage={(msg) => userAuthCheck(msg)}
            isInpDisabled={!props.is_logged
                            || isLoading
                            || (props.window_path === '/port/edit' && removeSections(props.completed_sec).length === 0)
                            || (userInfo && removeSections(userInfo.form_details.section_ids, 'string').length === 0)
                        }
            isLoggedIn={props.is_logged}
          />
          <p className="fs-12p m-0 pt-1 text-center"><span className="fw-normal">Powered by</span> <a href="https://www.askeva.ai" target="_blank" rel="noopener noreferrer"><i>askeva.ai</i></a></p>
        </div>
      </div>
      {/* Drag Bar */}
      {/* <div id = 'Draggable'
        draggable   = 'true'
        onDragStart = {initial} 
        onDrag      = {resize}
      /> */}
      </div>
    </div>
    </div>
  );
};
// export default Chatbot;
export default AuthCheck(Chatbot);

