import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import NotFound from "../404";
import {LoaderMedium} from './../../components/loader';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
// import {urlToString} from './../../helper/custom-function';
// const fellowData = {
//     'Issa-Conteh': {name: 'Issa Conteh', position: 'Head of Office, UN Resident Coordinator’s Office, Uganda', image: 'Issa-Conteh.png', detail: `<div className="mb-3">
//                         <h3>Experience:</h3>
//                         <ol>
//                         <li value="1">Senior Development Coordination Officer, Strategic Planning and Head of RCO. <b>United Nations</b>. Nov 2022 - Present. Kampala, Uganda.</li>
//                         <li>Planning Monitoring Evaluation and Learning Officer. <b>The Commonwealth Secretariat</b>. Sep 2019 - Mar 2020.</li>
//                         <li>Regional Coordination and Monitoring and Evaluation Specialist. <b>United Nations Sustainable Development Group.</b> Sep 2016 - Apr 2019.</li>
//                         <li>Deputy Team Leader and Result-based Management (RBM) Specialist. <b>UNDP</b>. Nov 2012 - Aug 2016.</li>
//                         <li>Assistant Director, Monitoring, Research and Planning. <b>National Revenue Authority</b>. May 2011 - Oct 2012.</li>
//                         </ol>
//                     </div>
//                     <div>
//                         <h3>Education:</h3>
//                         <ol>
//                         <li><b>Yokohama National University</b>, Masters in Economics, Public Policy and Taxation. 2009 - 2011.</li>
//                         <li><b>National Tax College</b>.Masters in Economics, Taxation. 2009 - 2010</li>
//                         </ol>
//                     </div>`},
//     'Anam-Zahra': {name: 'Anam Zahra', position: 'United People Global Sustainability Leader', image: 'Anam-Zahra.png', detail: `<div className="mb-3">
//                         <h3>Experience:</h3>
//                         <ol>
//                         <li>En-ROADS Climate Ambassador. <b>Climate Interactive, Washington DC.</b> Oct 2023 - Present.</li>
//                         <li>In-Sight Collaborative. <b>Summer Mentorship Program 2023.</b> Jul 2023 - Present.</li>
//                         <li>UPG Sustainability Leader 2023. <b>United People Global.</b> May 2023 - Present.</li>
//                         </ol>
//                     </div>
//                     <div>
//                         <h3>Education:</h3>
//                         <ol>
//                         <li><b>Karachi University.</b> Master of Arts - MA, International Relations and Affairs. </li>
//                         </ol>
//                     </div>`},
//     'Charles-Chimdiya-Asiegbu': {name: 'Charles Chimdiya Asiegbu', position: 'Research Lead | Fellow, Friedrich Ebert Stiftung OMYV | Bridge Fellow, Nigeria Economic Summit Group (NESG) | YIL Fellow', image: 'Charles-Chimdiya-Asiegbu.png', detail: `<div className="mb-3">
//                         <h3>Experience:</h3>
//                         <ol>
//                         <li>Team Lead, Research. <b>Aig-Imoukhuede Foundation.</b> Nov 2023 - Present.</li> 
//                         <li>Senior Policy Research Analyst. <b>The Nextier Group.</b> Aug 2022 - Jan 2024.</li>
//                         <li>Co-Founder. YouPaD. Nov 2020 - Present. </li>
//                         <li>Key Staff • Project Administration. <b>China Civil Engineering Construction Corporation.</b> Oct 2016 - Oct 2020</li>
//                         <li>Research And Development Analyst. <b>DevTrain Community and Entrepreneurship Development Initiative.</b> Sep 2019 - Present.</li>
//                         </ol>
//                     </div>
//                     <div >
//                         <h3>Education:</h3>
//                         <ol>
//                         <li><b>University of Abuja.</b> Master's degree, International Relations. May 2023.</li>
//                         <li><b>University of Abuja.</b> Bachelor's degree, Political science. Oct 2014</li>
//                         </ol>
//                     </div>`},
//     'Esther-Mundia': {name: 'Esther Mundia', position: 'Associate at Global Alliance', image: 'Esther-Mundia.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Junior Associate. <b>Global Alliance for Improved Nutrition (GAIN)·</b> Jan 2023 - Present.</li>
//                             <li>Monitoring & Evaluation Assistant. <b>Yatima Outreach.</b> Nov 2022 - Jan 2023</li>
//                             <li>Project Manager Trainee. <b>Elephant Healthcare.</b> Sep 2019 - Dec 2019 ·</li>
//                             </ol>
//                         </div>
//                         <div >
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Egerton University.</b> BSc, Community Development. 2014 - 2019.</li>
//                             <li><b>Egerton University.</b> Certificate, Statistical Packages for Social Science. 2017 - 2018</li>
//                             </ol>
//                         </div>`},
//     'Etape-Ngole': {name: 'Etape Ngole', position: 'Junior Senator of Cameroon for the United Nations', image: 'Etape-Ngole.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Team Member, <b>The Queen's Commonwealth Trust</b>. Jan 2023</li>
//                             <li>Head of Regional Audit. <b>Cameroon National Youth Council</b>. Dec 2022 - Present.</li> 
//                             <li>Peace Ambassador. <b>Institute for Economics & Peace.</b> Oct 2022 - Present4. . DirectorDirector</li>
//                             <li>Director. Cameroon Youth Initiative for Development. Oct 2022 - Present. </li>
//                             <li>Junior Senator of Cameroon for the United Nations. <b>World Federation of United Nations Associations (WFUNA)</b>. Jan 2022 - Present.</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>University of Buea.</b> Master's degree, Communication, Journalism, and Related Programs. Nov 2018 - Nov 2020.</li>
//                             <li><b>University of Buea.</b> BSc Journalism and mass communication, 2014 - 2017</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>CGolden Hand of Volunteer Award. Issued by Cameroon Ministry of Youth Affairs and Civic Education · Dec 2022
//                             </li>
//                             </ol>
//                         </div>`},
//     'Faisal-Al-Islam': {name: 'Faisal Al Islam', position: 'GDSC Team Lead', image: 'Faisal-Al-Islam.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Research Assistant. <b>Bangladesh University of Professionals (BUP). Dec 2022 - Present</li>
//                             <li>Team Lead. <b>Google Developer Student Club, Pennsylvania, United States.</b>  Aug 2023 - Present</li>
//                             <li>Junior Research Analyst. <b>The Material World.</b> May 2023 - Present </li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Bangladesh University of Professionals (BUP).</b> Bachelor of Arts and Social Sciences, Economics. Jan 2020.</li>
//                             <li><b>University of the People. </b>Business Administration in Science. May 2022.</li>
//                             </ol>
//                         </div>`},
//     'Hasibul-Hasan': {name: 'Hasibul Hasan', position: 'Youth Peacebuilder & Environmental Activist', image: 'Hasibul-Hasan.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Youth Speaker. <b>United Nations Alliance of Civilizations (UNAOC).</b> Jul 2021 - Present </li>
//                             <li>Youth Ambassador. <b>WSA.</b> Aug 2023 - Sep 2023.</li>
//                             <li>Millennium Fellow. <b>United Nations Academic Impact.</b> Aug 2022 - Oct 2022.</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>University of Chittagong.</b> BSS, Public Administration. 2020 - 2025</li>
//                             <li><b>Birshreshtha Noor Mohammad Public College.</b> HSC, Science. 2017 - 2019.</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>Global Legacy 25 Under25 Award. Issued by Common Purpose. May 2023</li>
//                             </ol>
//                         </div>`},
//     'Mohamed-Faraban-Diakite': {name: 'Mohamed Faraban Diakite', position: 'Doctor of Medicine from Gamal Abdel Nasser University', image: 'Mohamed-Faraban-Diakite.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Member. <b>Amnesty International.</b> Oct 2022 - Present. </li>
//                             <li>Ambassador. <b>United People Global.</b> Feb 2022 - Present.Oct 2021 - Feb 2022</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Université Laval.</b> Msc, Public Health. Sep 2023 - Sep 2026.</li>
//                             <li><b>Gamal Abdel Nasser University of Conakry.</b> Doctor of Medicine - MD, Oct 2013 - Feb 2022.</li>
//                             </ol>
//                         </div>`},
//     'Mohsin-Ali-Baig': {name: 'Mohsin Ali Baig', position: 'Researcher', image: 'Mohsin-Ali-Baig.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>WNR Writer. <b>Platform for Peace and Humanity.</b> Sep 2023 - Present.</li>
//                             <li>Research Intern. <b>Center for Critical Peace Studies (CCPS).</b> May 2022 - Sep 2023. </li>
//                             <li>Teacher Assistant. <b>University of Management and Technology.</b> Oct 2021 - Feb 2022</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>University of Management and Technology.</b> Bachelor's degree, International Relations and Affairs. Jan 2019 - Apr 2023. </li>
//                             </ol>
//                         </div>`},
//     'Nabila-Arab': {name: 'Nabila Arab', position: 'Advisory Board Member, UNDP', image: 'Nabila-Arab.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Youth Advisory Committee. <b>Netherlands Embassy in Lebanon.</b> Sep 2023 - Present.</li>
//                             <li>Youth Advisory Board Member. <b>UNDP.</b> May 2023 - Present.</li>
//                             <li>Fellow at Youth for Rights. <b>Euro-Med Human Rights Monitor.</b> Feb 2023 - Present.</li>
//                             <li>Lebanon-Beirut Hub Coordinator. <b>Al-Sharq Forum.</b> Feb 2023 to Present.</li>
//                             <li>Aspire Harvard Leadership Program. <b>Harvard Business School.</b> Feb 2023 to Present. </li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Institute on Migration Capacity Partnership for the Mediterranean, Euromed Migration EMM5 International Centre for Migration Policy Development.</b> Migration Governance for Young Professionals by European Commission. Sep 2023 - Sep 2023</li>
//                             <li><b>Lebanese American University.</b>Bachelor's degree, Social Work.2019 - 2022</li>
//                             </ol>
//                         </div>`},
//     'Nayab-Zahra': {name: 'Nayab Zahra', position: 'CEO. Entrepreneur', image: 'Nayab-Zahra.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>CEO, <b>S.D NEXUS, Geneva.</b> Jul 2020 - Present.</li>
//                             <li>Data Analyst. <b>Big Data Solutions.</b> May 2019 - Jan 2023.</li>
//                             <li>Artificial Intelligence Engineer. <b>Introvon, Singapore.</b> Feb 2018 - Dec 2022.</li>

//                             </ol>
//                         </div>
//                         <div >
//                             <h3>Education:</h3>
//                             <ol>
//                             Education:
//                             <li><b>Stanford University,</b> Management Science and Engineering. 2022</li>
//                             <li><b>University of Antwerp.</b> Electrical Engineering</li>
//                             <li><b>Harvard Graduate School of Education.</b> Ed.m, Learning Design, Innovation, and Technology.</li>
//                             <li><b>University of Glasgow.</b> Master of Computer Applications - MCA, Electrical and Electronics Engineering. Sep 2022</li>
//                             </ol>
//                         </div>`},
//     'Oluwaseun-Atoyebi': {name: 'Oluwaseun Atoyebi', position: 'Media Founder and Host', image: 'Oluwaseun-Atoyebi.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Founder and the host of <b>The Insight</b></li> 
//                             <li>Founder and host of <b>Save the African Child</b></li>
//                             <li>Content Writer. <b>Academia.edu.</b> Nov 2021</li>
//                             <li>Director. <b>Mapp foundation.</b> Mar 2020 - Present.</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Federal University Oye Ekiti. </b> Bsc, Political Science and Government</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>MAPP Best Volunteer of the Year </li>
//                             <li>Recognised in Liberia Youth for Education (LYE)</li>
//                             </ol>
//                         </div>`},
//     'Quadri-Olatunbosun-Taiwo': {name: 'Quadri Olatunbosun Taiwo', position: 'Geospatial Planner', image: 'Quadri-Olatunbosun-Taiwo.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Graduate Teaching Assistant. <b>Portland State University.</b> Sep 2023 - Present.</li>
//                             <li>Urban Planner and Analyst. <b>Civic Designs and Planning Variations Limited.</b> Nov 2019 - Sep 2023. </li>
//                             <li>Logistics Manager. <b>Wilsam Energy Nigeria Limited.</b> Sep 2018 - Sep 2019 </li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Portland State University.</b> MS, Geography. Sep 2023 - Mar 2026</li>
//                             <li><b>Ekiti State University Ado-Ekiti Nigeria.</b> Bachelor's in Geography and Planning Science. Sep 2012 - Mar 2018</li>

//                             </ol>
//                         </div>`},
//     'Sakib-U-Sourav': {name: 'Sakib U Sourav', position: 'PhD student at Concordia in Montreal', image: 'Sakib-U-Sourav.png', detail: ` <div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Research Collaborator. <b>STEM ResearchAI.</b> Sep 2022 - Present </li>
//                             <li>Collaborative Researcher and Founder. <b>STEM in Bangla.</b> Nov 2018 - Present ·</li>
//                             <li>Graduate Research Student. <b>Shandong University of Finance and Economics.</b> Sep 2020 - Jun 2023 · </li>
//                             <li>Research Assistant. <b>National Institute of Education, Singapore.</b> Aug 2022 - Dec 2022. </li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Concordia University.</b> PhD, Information and Systems Engineering. Sep 2023 - Aug 2027.</li>
//                             <li><b>Shandong University of Finance and Economics.</b> MS, Management Science and Engineering.</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>First Prize at Fifth "Kunpeng Cup" Shandong New Kinetic Energy. Software Innovation and Entrepreneurship Competition. Issued by Shandong Provincial Government · Dec 2021</li>
//                             <li>PEIYOU Scholarship (First Class)PEIYOU Scholarship (First Class). Issued by Shandong Provincial Government · Sep 2020</li>
//                             </ol>
//                         </div>`},
//     'SHAKEEL-AHMED': {name: 'SHAKEEL AHMED', position: 'Founder GREENV | Climate Activist | Entrepreneur', image: 'SHAKEEL-AHMED.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Founder and CEO. GREENV. Sep 2022 - Nov 2023 ·</li>
//                             <li>Export Supervisor. <b>Pakistan Tobacco Company Ltd. BAT.</b> Sep 2023 - Present ·</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Islamabad International Islamic University,</b> Bachelor's degree, Mechanical Engineering. Sep 2019 - Jun 2023. </li>
//                             <li><b>Government Postgraduate College,</b> Jhelum, Pre-EngineeringIntermediate /A-Level, Pre-Engineering 2017 - 2019</li>
//                             </ol>
//                         </div>`},
//     'Vamuyan-A-Sesay': {name: 'Vamuyan A. Sesay, FMVA', position: 'Office of the Vice President of the Republic of Liberia.Senior Economist', image: 'Vamuyan-A--Sesay.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li value="1">Admin & Finance, <b>Office of the Vice President of the Republic of Liberia.</b> Oct 2022 to present </li>
//                             <li>Senior Economist. <b>Ministry of Finance and Development Planning Liberia</b>. Jul 2020 - Present</li>
//                             <li>Implementation Committee (IC) Member. <b>TRANSCO CLSG.</b> August 2022 - Present</li>
//                             <li>Lecturer in Mathematics, Statistics and Economics. <b>Liberian Universities</b>. Jan 2018 - Present.</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Corporate Finance Institute (CFI)</b>. Financial Modeling and Valuation Analyst (FMVA). Apr 2022 - Sep 2022</li>
//                             <li><b>University of Gothenburg</b>. Postgraduate Certificate. Jun 2017. </li>
//                             <li><b>Karlstad University</b>. MS, Applied Economics/Econometrics. 2016 - 2017</li>
//                             <li><b>Cuttington University</b>. Bachelor of Science in Economics. 2009 - 2013 </li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>Swedish Institute Study Scholarship (SISS)</li>
//                             <li>Certificate of Appreciation, Issued by Ministry of Finance and Development Planning · May 2014</li>
//                             </ol>
//                         </div>`},
//     'Xolani-Sydwel-Mnisi': {name: 'Xolani Sydwel Mnisi', position: 'Policy Analyst', image: 'Xolani-Sydwel-Mnisi.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Macro Governance Policy Analyst. <b>Office of The Premier,Mpumalanga Provincial Government,</b> </li>
//                             <li>Education Assistant. <b>The Presidency of the Republic of South Africa.</b> Dec 2020 - Apr 2021. </li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>National School of Government.</b>Professional Development Certificates, Public Policy Analysis. 2018 - 2022.</li>
//                             <li><b>DRC Université Kongo-Human Rights Centre (Nelson Mandela International Summer School. </b>
//                             Professional Certificate, Law, Policy and Sustainable Development. 2021. </li>

//                             </ol>
//                         </div>`},
//     'Yash-Singh-Sisodiya': {name: 'Yash Singh Sisodiya', position: 'Social Scientist. Senior Researcher for IAS Officer Training.', image: 'Yash-Singh-Sisodiya.png', detail: ` <div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Senior Researcher. <b>Lal Bahadur Shastri National Academy Of Administration for IAS Officer Training.</b> Feb 2023 - Present. </li>
//                             <li>Senior Research Executive. <b>Devi Ahilya Vishwavidyalaya.</b> Sep 2022 - Present.</li>
//                             <li>Senior Research Fellow. <b>Epoch Elder Care.</b> Apr 2022 - Sep 2022. </li>
//                             <li>Research and Publication. <b>United People Global, Geneva.</b> Oct 2021 - May 2022. </li>
//                             <li>Research Intern. <b>The University of Western Australia.</b> Jul 2021 - Sep 2021.</li>

//                             </ol>
//                         </div>
//                         <div >
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>The University of Western Australia.</b> MA Sociology. Jul 2021 - Sep 2021.</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>International Diplomat's Conference 2023. Issued by Voice for Rights International (Canada). Jan 2023 </li>
//                             </ol>
//                         </div>`},
//     'Zahid-Hussain': {name: 'Zahid Hussain', position: 'National Executive G17 Global', image: 'Zahid-Hussain.png', detail: `<div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>National Executive. <b>G17 Global.</b> Apr 2022 - Present.  </li>
//                             <li>FXB Climate Advocates. <b>FXB International.</b> Jan 2023 - Apr 2023</li>
//                             <li>Associate Research Fellow. <b>Pixstory,</b> Washington DC. Oct 2022.</li>
//                             <li>Youth Ambassador. <b>World Literacy Foundation.</b> Jun 2022 - Aug 2022.</li>
//                             <li>Campus Director. <b>Hult Prize Foundation.</b> Jun 2021 - Apr 2022.</li>
//                             <li>Public Policy Fellow. <b>Learners' Republic.</b> Jan 2022 - Feb 2022.</li>
//                             </ol>
//                         </div>
//                         <div className="mb-3">
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Khadim Ali Shah Bukhari Institute of Technology.</b> Master of Science (MS) in Marketing , Marketing/Marketing Management, GeneralAug 2023 - Jan 2025.</li>
//                             <li><b>Shaheed Benazir Bhutto University,</b> BBA, Marketing/Digital Marketing. 2019 - 2023.</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Honors & Awards:</h3>
//                             <ol>
//                             <li>Research Grant 21000 Pkr. Issued by Islamic University of Indonesia · Aug 2022.</li>
//                             </ol>
//                         </div>`},
//     'Zuliehat-Abdullahi': {name: 'Zuliehat Abdullahi', position: 'Researcher at Youth of Enterprise Nigeria', image: 'Zuliehat-Abdullahi.png', detail: ` <div className="mb-3">
//                             <h3>Experience:</h3>
//                             <ol>
//                             <li>Researcher, <b>Youth of Enterprise Nigeria.</b> Jul 2023 - Present.</li>
//                             <li>Accountapreneur. <b>Accountability Lab.</b> Jan 2022 - Present</li>
//                             <li>Mathematics Teacher. <b>Betty Queen International School Kaduna State.</b> Jul 2021 - Oct 2023</li>
//                             <li>Project Assistant. <b>Rufi Link Nig. Limited.</b> May 2020 - Jun 2021</li>
//                             <li>Logistics officer. <b>Nigerian Defence Academy.</b> Mar 2019 - Mar 2020.</li>
//                             <li>System Analyst. <b>Ajaokuta Steel Company Limited.</b> Mar 2017 - Dec 2017</li>
//                             </ol>
//                         </div>
//                         <div>
//                             <h3>Education:</h3>
//                             <ol>
//                             <li><b>Nigerian Defence Academy of Post Graduate Studies.</b> Masters, Environmental Management. 2020 - 2022</li>
//                             <li><b>Federal University of Technology,</b> Minna. B-Tech, City, Urban and Community Planning. 2013 - 2018</li> 
//                             </ol>
//                         </div>`}
// };

function FellowDetail(){
    const { profile_id } = useParams();
    SetMetaData("Fellow Profile", "", "");
    // let params = useParams();
    // const productIdParam = params.id || '';
    // var pageTitle = params.url;
	// pageTitle = urlToString(pageTitle);
	// SetMetaData(pageTitle, "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Main Product Data
    const [mFellowData, setFellowData] = useState({});
    SetMetaData(typeof mFellowData.first_name !== "undefined" ? `${mFellowData.first_name} ${mFellowData.last_name}` : "", "", "");
    //Loader
    // const [tabKey, setTabKey] = useState("compo");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);

	//Get Fellow Details
    const fetchFellowData = async() => {
        try{
            const fellowApiData = await post_api_call(`fellow/${profile_id}`, {});
            if(fellowApiData.status === 200){                    
                setFellowData(fellowApiData.data);
                // SetMetaData(`${fellowApiData.data.first_name} ${fellowApiData.data.last_name}`, "", "");
            }else if(fellowApiData.status === 404){
                setShowNotFoundPage(true);
            }
            setPageLoader(false);
        }catch(e){
            console.log(e);
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            error_api_call({error: `fellow/detail|fetchFellowData|${e}`});
        }
    }
	//Intial Fetching
	useEffect(() => {
        fetchFellowData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <main id="main">
            <section className="team-sec pt-0">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Fellow Profile</h1>
                    </div>

                    <div className="row justify-content-center team-detail m-1">
                        <div className="col-md-9 col-12 col-sm-12 card">
                            {/* Show Loader */}
                            {pageLoading 
                                ?
                                <div className="text-center mt-5 mb-5">
                                        <LoaderMedium size={45} />
                                </div>
                                :
                                <>
                                    {mFellowData && typeof mFellowData.first_name !== "undefined" && mFellowData.first_name !== '' && (
                                        <>
                                        <div className="row m-2 mt-3">
                                            <div className="col-md-2 col-4 col-sm-3 p-0">
                                                <img src={mFellowData.logo} className="img-thumbnail" alt={`${mFellowData.first_name} ${mFellowData.last_name}`} />
                                            </div>
                                            <div className="col-md-10 col-8 col-sm-9">
                                                <h2>
                                                {/* <a href="/fellow-profile/Anam-Zahra.html" target="_blank" rel="noopener noreferrer"> */}
                                                    <strong>{mFellowData.first_name} {mFellowData.last_name}</strong>
                                                {/* </a> */}
                                                <p className="position">{mFellowData.position}</p>
                                                {/* {mFellowData.designation} */}
                                                <p className="country">{mFellowData.country}</p>
                                                {/* {mFellowData.gender}, */}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            <div className="mb-4">
                                                <h3>About:</h3>
                                                <div style={{fontSize: 14}}>
                                                    {mFellowData.headline} {mFellowData.experience} {mFellowData.education}
                                                </div>
                                            </div>
                                            {/* Experience */}
                                            {(mFellowData.experience_details && mFellowData.experience_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Experience:</h3>
                                                <ol>
                                                {mFellowData.experience_details.map((exp_d, index) => (
                                                    <li key={`experience_${index}`}>
                                                        {exp_d.designation} - <b>{exp_d.organization}</b>{exp_d.achievement ? ` (${exp_d.achievement})` : ''}.
                                                        {(exp_d.start_year !== null)
                                                            && <> {exp_d.start_year} - {exp_d.end_year === null ? 'Present' : exp_d.end_year}</>
                                                        }
                                                    </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Education */}
                                            {(mFellowData.education_details && mFellowData.education_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Education:</h3>
                                                <ol>
                                                {mFellowData.education_details.map((edu_d, edu_index) => (
                                                    <li key={`education${edu_index}`}>
                                                        <b>{edu_d.institute_name}</b>, {edu_d.program_name}{edu_d.achievement ? ` (${edu_d.achievement})` : ''}.
                                                        {(edu_d.start_year !== null)
                                                            && <> {edu_d.start_year} - {edu_d.end_year === null ? 'Present' : edu_d.end_year}</>
                                                        }
                                                    </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Honors and Awards */}
                                            {(mFellowData.award_details && mFellowData.award_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Honors and Awards:</h3>
                                                <ol>
                                                {mFellowData.award_details.map((award_d, award_index) => (
                                                <li key={`award${award_index}`}>
                                                    {award_d.institute_name}, <b>{award_d.award_name}</b>{award_d.why_awarded ? ` (${award_d.why_awarded})` : ''}{award_d.year ? ` - ${award_d.year}` : ''}
                                                </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Unique Skills */}
                                            {(mFellowData.unique_skills && mFellowData.unique_skills.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Unique Skills:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table">
                                                        <thead>
                                                            <tr>
                                                                <th>Skill</th>
                                                                <th>Defining Attributes</th>
                                                                <th>Unique Abilities</th>
                                                                <th>Main Motivation</th>
                                                                <th>Potential Skills</th>
                                                                <th>Suitable Role</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mFellowData.unique_skills.map((passion_d, passion_index) => (
                                                            <tr key={`passion${passion_index}`}>
                                                                <td><b>{passion_d.title}</b></td>
                                                                <td>{passion_d.defining_attribute}</td>
                                                                <td>{passion_d.unique_ability}</td>
                                                                <td>{passion_d.main_motivation}</td>
                                                                <td>{passion_d.potential_skill}</td>
                                                                <td>{passion_d.suitable_role}</td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                        </div>



                                        {/* <div className="p-2" dangerouslySetInnerHTML={ { __html: fellowData[profile_id].detail}}> */}
                                            {/* {fellowData[profile_id].detail} */}
                                        {/* </div> */}
                                        </>
                                    )}
                                    {showNotFoundPage && (
                                        <NotFound type="fellow" />
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )    
}

export default FellowDetail;