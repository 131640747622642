import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';
import {useLocation, Link} from 'react-router-dom';
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import '../../assets/css/pages/form.css';
// import { SpinnerRoundFilled } from 'spinners-react';
import {LoaderMedium} from './../../components/loader';
import ValidationHelper from '../../helper/validation';
//Axios Call
import {login_register} from '../../actions/action-creators/auth';
import {error_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';
//State
const inputDefaultValue = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  mobile: '',
  user_name: '',
  register_medium: 1//1=>Web
};

const Login = () => {
  SetMetaData("Login", "", "");
  const location = useLocation();
    const isMounted = useRef(null);
  // const [currentStep, setCurrentStep] = useState(2);//1=>Initial,2=>Login,3=>Register,4=>Verification
  //Store Data
  // const authReduxData = useSelector(state => state.auth);
  const dispatch = useDispatch()
  const [inputFields, setFields] = useState(inputDefaultValue);
  const [securePassword, setsecurePassword] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isLoginProcess, setIsLoginProcess] = useState(false);
  const [isError, setIsError] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  //Redirection Url
  useEffect(() => {
    const urlHandling = () => {
      try{
        // const urlQuryString = window.location.search;
        const urlQuryString = location.search;
        const urlParam = new URLSearchParams(urlQuryString);
        const urlRedirect = urlParam.get('redirect_uri');
        if(urlRedirect !== '' && urlRedirect !== null){
          setRedirectUri(urlRedirect);
        }
      }catch(e){
        error_api_call({error: `auth/login|urlHandling|${e}`});
      }
    }
    urlHandling();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Update Input Box
  const _onChangeText = (attrName, value) => {
    try{
      setIsError('');
      let fields = {...inputFields};
      value = value.replace(/ /g, '');
      fields[attrName] = value;
      setFields({...fields});
    }catch(e){
      error_api_call({error: `auth/login|_onChangeText|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Input Validation:Use to Toggle Button: Disable-true|false
  useEffect(()=>{
    //Validate Input
    const _validate = () => {
      // if (!didMount) return null;
      let fields = {...inputFields};
      try{
        var validators = {};
        validators['password'] = 'required|password|min:8|max:30';
        validators['email'] = 'required|email|min:7|max:100';
        const validation = ValidationHelper.validation(fields, validators).error;
        setSubmitDisabled(validation.length ? true : false);
        return validation.length ? false : true;
      }catch(e){
        error_api_call({error: `auth/login|_validate|${JSON.stringify(inputFields)}|${e}`});
      }
    };
    _validate();
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [inputFields]);// eslint-disable-line react-hooks/exhaustive-deps
  //Toggle Show-Hide Password
  const _showHidePass = () => {
    setsecurePassword(!securePassword)
  };
  //Login-Register User
  const _loginRegister = async() => {
    //Validation:Already done
    //Show Loader
    try{
      var formData = {...inputFields};
      setIsLoginProcess(true);
      setIsError('');
      dispatch(login_register('login/email', formData, cb_login => {
        // console.log(cb_login, 'cb_login')
        if (isMounted.current) {
          if(cb_login.status === 200){
            // if(cb_login.data.validity.success){
            //   //Get first hostname from redirection url
            //   const redirectUriArr = redirectUri.split('/');
            //   const redirectServ = redirectUriArr.length > 0 ? redirectUriArr[0] : '';
            //   if((cb_login.data.validity.port === 0 && cb_login.data.validity.ss === 0)
            //     || (redirectServ === 'port' && cb_login.data.validity.port === 0)
            //     || (redirectServ === 'social-startup' && cb_login.data.validity.ss === 0)
            //   ){//If both service expired
            //     window.location.href = '/pay';
            //   }else{
            //     window.location.href = redirectUri ? `${redirectUri}` : "/port";
            //   }
            // }else{
              window.location.href = redirectUri ? `${redirectUri}` : "/port";
            // }
              // console.log('port', redirectUri)
              // return;
              // window.location.href = '/port';
              // window.location.href = redirectUri ? `${redirectUri}` : "/port";
              // window.location.href = redirectUri ? `${redirectUri}` : "/";
            // if(cb_login.data.data.is_verified === 1
            //   || cb_login.data,data.is_verified === 3
            // ){
            //   // window.location.href = redirectUri ? `/${redirectUri}` : "/";
            // }else{
            //   // window.location.href = redirectUri ? `/verification?redirect_uri=${redirectUri}` : '/verification';
            //   // setCurrentStep(4);//Verification
            //   // window.location.href = 'port';
            // }
          }else{
            setIsError(cb_login.msg);
          }
          setIsLoginProcess(false);   
        }
      }));
    }catch(e){
      console.log(e)
    //   setIsError('Error Logging In/Registering! Please Try Again');
    setIsError('Error Logging In! Please Try Again');
      setIsLoginProcess(false);
      error_api_call({error: `auth/login|_loginRegister|${JSON.stringify(inputFields)}|${e}`});
    }
  }
  //Forget Password Redirect
  // const _forgotPassword = (e) => {
  //   e.preventDefault();
  //   if(ValidationHelper.validateNumber(inputFields.user_name)){
  //     window.location.href = `/reset-password?user_name=${inputFields.user_name}&redirect_uri=${redirectUri}`;
  //   }else{
  //     window.location.href = `/reset-password?redirect_uri=${redirectUri}`;
  //   }
  // }

  return (
    <main id="main">
    <div className="c-app c-default-layout align-items-center custom-form login-form">
      <Container style={{minHeight: '66vh'}}>
        <Row className="justify-content-center pt-5">
          <Col  md="7" lg="4">
            <CardGroup className="team-detail">
              <Card className="p-2" style={{margin: '55px 0px'}}>
                <Card.Body>
                <form name="login" id="login">
                    {/* <h5 className="text-center mb-5">
                      {formTitle[currentStep]}
                    </h5> */}
                    <div className="section-header section-header-1">
                        <h1 className="title">Login</h1>
                    </div>
                    {/* <p className="text-muted text-center">Sign In to your account</p> */}
                    <div className="mb-3">
                      <InputGroup>
                        <InputGroup.Text><i className="fa fa-envelope"></i></InputGroup.Text>
                        <Form.Control 
                          type="email" 
                          placeholder="Email" 
                          autoComplete="user_name" 
                          value={inputFields.email}
                          onChange={(event) => _onChangeText('email', event.target.value)}
                          maxLength={100}
                        //   readOnly={currentStep !== 1 || isLoginProcess ? true : false}
                            readOnly={isLoginProcess ? true : false}
                        />
                      </InputGroup>
                      {/* <p className="input-info">Space Not Allowed</p> */}
                    </div>
                    <>
                      <div className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><i className="fa fa-lock"></i></InputGroup.Text>
                          <Form.Control 
                            type={securePassword ? "password" : "text"}
                            placeholder="Password" 
                            autoComplete="current-password" 
                            value={inputFields.password}
                            onChange={(event) => _onChangeText('password', event.target.value)}
                            maxLength={30}
                            readOnly={isLoginProcess ? true : false}
                          />
                          {inputFields.password !== "" &&
                            <i className={`fa icon-eye ms-3 ${securePassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => _showHidePass()}></i>
                          }
                        </InputGroup>
                        {/* <p className="input-info">Minimum 6 Characters, Maximum 30 Characters</p> */}
                      </div>
                    </>

                    <Row className='mt-5'>
                      {isError &&
                        (<Col xs="12" className="text-center">
                            <div className="alert alert-danger" role="alert">
                                {isError}
                            </div>
                        </Col>)
                        }
                      <Col xs="12" className="text-center">
                        {isLoginProcess ? 
                          <Button
                            title="Submit"
                            disabled={true}
                            color={'primary'}
                            className="bg-color-blue"
                          >
                            {/* <SpinnerRoundFilled color="#fff" size={20} /> */}
                            <LoaderMedium size={20} />
                            &nbsp;Logging...
                          </Button>
                          : 
                          <Button
                            type="submit"
                            disabled={submitDisabled}
                            onClick={() => _loginRegister()}
                            color={"primary"}
                            className="px-4 bg-color-blue"
                          >Login</Button>
                        }
                      </Col>
                      {/* {currentStep !== 3 &&
                        <Col xs="12" className="text-right">
                          <p className="link">
                            <button
                              className='btn p-0 link-custom'
                              onClick={(e) => _forgotPassword(e)}
                              disabled={false}
                            >Forgot your password?</button>
                          </p>
                        </Col>
                      } */}
                      <Col xs="12" className="text-right">
                        <p className="link mb-0">
                          By Logging, I agree to the <a href="/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a> and <a href="/terms-and-conditions" target="_blank" rel="noreferrer">terms and conditions</a>
                        </p>
                      </Col>
                      <Col xs="12" className="text-center">
                        <Link to={`/register${redirectUri ? `?redirect_uri=${redirectUri}` : ''}`}>
                          <p className="link link-highlight">Don`t have acoount? Create one, click here</p>
                        </Link>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
    </main>
  )
}

export default Login
