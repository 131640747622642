import React, {useState, useEffect} from "react";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import {SetMetaData} from './../../helper/page-title';
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import picIcon from "./../../assets/images/user.png";
import { RSstandardStyle } from "../../config/react-select";
// const monthData = [
//     // {value : "", month: "Month"},
//     {id: 1, month: "January"},
//     {id: 2, month: "February"},
//     {id: 3, month: "March"},
//     {id: 4, month: "April"},
//     {id: 5, month: "May"},
//     {id: 6, month: "June"},
//     {id: 7, month: "July"},
//     {id: 8, month: "August"},
//     {id: 9, month: "September"},
//     {id: 10, month: "October"},
//     {id: 11, month: "November"},
//     {id: 12, month: "December"},
// ];
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = currentYear - 100;
// const genderData = [
//     {id: 'male', name: 'Male'},
//     {id: 'female', name: 'Female'},
//     {id: 'others', name: 'Others'},
// ];
// const initialFormData = {
//     first_name: 'Ritika',
//     last_name: '',
//     linkedin_url: '',
//     country_id: {id: 101, name: 'India', phone_code: '91'},
//     dob: '2024-02-14',
//     email: 'ririkasingh171@gmail.com',
//     phone_code_id: {id: 101, name: 'India', phone_code: '91'},
//     mobile_number: '9804018840',
//     gender: {id: 'female', name: 'Female'},
//     position: 'Peace Builder',
//     designation: 'Science Student',
//     headline: 'Jane Doe is a 10th grade student at the Links Global School.',
//     imp_education: 'She is an ace debater who has won prizes in several interschool competitions.',
//     imp_experience: 'She is passionate about sustainable development and has participated in primary research on the topic ‘Improving Sustainable Living in Indian Villages’.',
//     experience: [
//         {designation: 'Head, Strategy', organization: 'Sealand Corp', achievement: 'Enabled the organization to grow 100% year on year.', start_month: '', start_year : {id: 2017, year: 2017}, end_month: '', end_year: {id: 2020, year: 2020}, is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false}
//     ],
//     education: [
//         {institute_name: 'Yokohama University.', program_name: 'Masters in Economics.', achievement: 'Distinction. Among top 1 percentile', start_month: '', start_year : {id: 2022, year: 2022}, end_month: '', end_year: '', is_present: true},
//         {institute_name: 'Yokohama University 2', program_name: 'Masters in Economics. 2', achievement: '', start_month: '', start_year : {id: 2020, year: 2020}, end_month: '', end_year: {id: 2023, year: 2023}, is_present: false},
//         {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//     ],
//     award: [
//         {institute_name: 'Government of Libya.', award_name: 'Youth Ambassador. ', why_awarded: 'For upliftment and empowerment of youth.', year: {id: 2023, year: 2023}},
//         {institute_name: '', award_name: '', why_awarded: '', year: ''},
//         {institute_name: '', award_name: '', why_awarded: '', year: ''},
//     ],
//     passion: [
//         {passion_id: {id: 3, title: 'Creative'}},
//         {passion_id: {id: 1, title: 'Practical'}},
//         {passion_id: {id: 2, title: 'Analytical'}}
//     ],
//     ifspd_program_id: {id: 1, program_name: 'Presidential Fellowship Fast Track'},
//     ifspd_program_year: {id: 2023, year: 2023}
// };
const initialFormData = {
    first_name: '',
    last_name: '',
    linkedin_url: '',
    country_id: '',
    dob: '',
    email: '',
    phone_code_id: '',
    mobile_number: '',
    // gender: '',
    position: '',
    designation: '',
    headline: '',
    imp_education: '',
    imp_experience: '',
    experience: [
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false}
    ],
    education: [
        {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
    ],
    award: [
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
    ],
    passion: [
        {passion_id: ''},
        {passion_id: ''},
        {passion_id: ''}
    ],
    ifspd_program_id: '',
    ifspd_program_year: ''
};

function FellowProfileForm(){
    SetMetaData("Fellow Profile Form", "", "");
    const [passionData, setPassionData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [programYearData, setProgramYear] = useState([]);
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [formData, setFormData] = useState({...initialFormData});
    const [errorData, setErrorData] = useState([]);
    const [isProcess, setIsProcess] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    //Year Data
    // const yearList = (type) => {
    //     const yearItems = [];
    //     for (let i = currentYear; i >= startYear; i--) {
    //       yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
    //         // yearItems.push({value: i, year: i});
    //     }
    //     // setYearData(yearItems);
    //     return yearItems;
    // };
    const yearDataList = (type) => {
        const yearItems = [];
        const startingYear = type === 'program' 
                                ? 2020
                                : startYear;
        for (let i = currentYear; i >= startingYear; i--) {
        //   yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
            yearItems.push({id: i, year: i});
        }
        type === 'program' ? setProgramYear(yearItems) : setYearData(yearItems);
        // return yearItems;
    };
    //Get Data for Passion Scores
    const fetchSelectList = async(type) => {
        try{
			const selectApiData = type === 'program' 
                                    ? await get_api_call('ifspd-program', {})
                                    : await get_api_call('passion-scores', {});
			if(selectApiData.status === 200){
				type === 'program'
                    ? setProgramData(selectApiData.data)
                    : setPassionData(selectApiData.data);
			}
        }catch(e){
			error_api_call({error: `fellow|profile-form|fetchSelectList|${e}`});
        }
    }
    //Get Data for Country
    const fetchCountryList = async() => {
        try{
			const countryApiData = await get_api_call('countries', {});
			if(countryApiData.status === 200){
				setCountryData(countryApiData.data);
			}
        }catch(e){
			error_api_call({error: `fellow|profile-form|fetchCountryList|${e}`});
        }
    }
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
            // var selectedAttachFiles = {...this.state.selectedAttachFiles};
            // var lastAppendFileIndex = this.state.lastAppendFileIndex;
            // var error = 0;
            // // const documetAllowedExt = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
            const documetAllowedExt = ["png", "jpg", "jpeg"];
            // // fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2)
            // const imageExt = ["image/png", "image/jpg", "image/jpeg"];
            // const allowedSize = 50000;
            const allowedSize = 200000;
            const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                //Check for file size and extension
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    // title: '',
                                    // is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                }else{
                    setPreviewPicImg(null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            // }
            // this.fileInput.value = '';
            // this.setState({selectedAttachFiles, lastAppendFileIndex});
            // if(error){
            //     alert("Attachment allowed size is 20MB and Attachment must be images or pdf. The Attachment which do not met the Criteria will not uploaded");
            // }
            }
        }catch(e){
            setPreviewPicImg(null);
        }
    }
    useEffect(() => {
		//Initial API Data
        fetchCountryList();
        fetchSelectList('passion');
        fetchSelectList('program');
        yearDataList('year');
        yearDataList('program');
	}, []);
    //Update Input
    const updateInput = (input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            if(errorData.length){
                setErrorData([]);
            }

            if(field === 'mobile_number'){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }

            if(field_type === 'array'){
                const fieldData = {...formData};
                fieldData[form_field][index][field] = value;
                setFormData({...fieldData});
            }else{
                setFormData({
                    ...formData,
                    [field]: value
                });
            }
        }catch(e){

        }
    }
    //Add Fellow on Submit Click
    const addFellow = async(e) => {
        try{
            e.preventDefault();
            //Validation
            setErrorData([]);
            setIsProcess(true);
            var errorDetails = [];
            //Image
            if(previewPicImg === null){
                errorDetails.push('Photograph is required');
            }
            const fieldFormData = {...formData,
                                    country_id: formData.country_id.id,
                                    // gender: formData.gender.id,
                                    phone_code_id: formData.phone_code_id.id
                                };
            const validationRules = {
                first_name: 'required|max:100',
                last_name: 'optional|max:100',
                country_id: 'required||Country',
                dob: 'required||Date of Birth',
                email: 'required|email|max:150',
                phone_code_id: 'required||Mobile Country Code',
                mobile_number: 'required|digit:|min:6|max:15',
                // gender: 'required',
                // position: 'required|word:15',
                position: 'required|max:150||Who you are (Point 4)',
                // designation: 'optional|max:150||Your current designation (Point 4)',
                headline: 'required|max:250||A headline of who you are (Point 5)',
                imp_experience: 'optional|max:250||Most important work experience (Point 5)',
                imp_education: 'optional|max:250||Most important education (Point 5)'
            };
            // console.log(formData, 'formData');
            const validation = ValidationHelper.validation(fieldFormData, validationRules).error;
            if(validation.length){
                errorDetails = errorDetails.concat(validation);
            }
            var apiFormData = new FormData();
            // var expData = [];
            // Work Experience
            initialFormData.experience.forEach((exp, exp_indx) => {
                var expDetails = fieldFormData.experience[exp_indx];
                if(expDetails.designation || expDetails.organization || expDetails.achievement){
                    const expValidationRules = {
                        designation: `required|max:100||Work Experience ${exp_indx + 1}: Designation`,
                        organization: `required|max:100||Work Experience ${exp_indx + 1}: Organization Name`,
                        achievement: `optional|max:250||Work Experience ${exp_indx + 1}: Key Responsibilities or Achievements`,
                        start_year: `required||Work Experience ${exp_indx + 1}: Start Year`
                    };
                    const validationExp = ValidationHelper.validation(expDetails, expValidationRules).error;
                    if(validationExp.length){
                        errorDetails = errorDetails.concat(validationExp);
                    }
                    // if(!expDetails.designation){
                    //     errorDetails.push(`Work Experience ${exp_indx + 1}: Designation	is Required`);
                    // }
                    // if(!expDetails.organization){
                    //     errorDetails.push(`Work Experience ${exp_indx + 1}: Organization Name is Required`);
                    // }
                    // if(!expDetails.achievement){
                    //     errorDetails.push(`Work Experience ${exp_indx + 1}: Key Responsibilities or Achievements is Required`);
                    // }
                    // if(expDetails.achievement.length > 250){
                    //     errorDetails.push(`Work Experience ${exp_indx + 1}: Key Responsibilities or Achievements should be less than 250 characters`);
                    // }
                    // // if(expDetails.start_month === ''){
                    // //     errorDetails.push(`Work Experience ${exp_indx + 1}: Start Month is Required`);
                    // // }
                    // if(expDetails.start_year === ''){
                    //     errorDetails.push(`Work Experience ${exp_indx + 1}: Start Year is Required`);
                    // }
                    //End Data
                    if(expDetails.is_present === false){
                        // if(expDetails.end_month === ''){
                        //     errorDetails.push(`Work Experience ${exp_indx + 1}: End Month is Required`);
                        // }
                        if(expDetails.end_year === ''){
                            errorDetails.push(`Work Experience ${exp_indx + 1}: End Year is Required`);
                        }else if(expDetails.start_year !== '' && expDetails.start_year.id > expDetails.end_year.id){
                            errorDetails.push(`Work Experience ${exp_indx + 1}: Start Year cannot be greater than End Year`);
                        }
                    }
                    if(!errorDetails.length){
                        var expEndYear = expDetails.is_present === true ? null : expDetails.end_year.id;
                        apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
                        apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
                        apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
                        apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year.id);//parseInt(expDetails.start_year.id))
                        // apiFormData.append(`experience[${exp_indx}][end_year]`, expDetails.end_year.id);
                        apiFormData.append(`experience[${exp_indx}][end_year]`, expEndYear);
                        apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
                        // expData.push({...expDetails,
                        //             // start_month: expDetails.start_month.id,
                        //             start_year: expDetails.start_year.id,
                        //             // end_month: expDetails.end_month || expDetails.end_month.id,
                        //             end_year: expDetails.end_year || expDetails.end_year.id
                        //         });
                    }
                }
            });
            //Education
            var isEduData = false;
            // var eduData = [];
            initialFormData.education.forEach((edu, edu_indx) => {
                var eduDetails = fieldFormData.education[edu_indx];
                if(eduDetails.institute_name || eduDetails.program_name || eduDetails.achievement){
                    isEduData = true;
                    const eduValidationRules = {
                        institute_name: `required|max:150||Education ${edu_indx + 1}: College/Institution Name`,
                        program_name: `required|max:100||Education ${edu_indx + 1}: Name of the Program`,
                        achievement: `optional|max:250||Education ${edu_indx + 1}: Key Achievement or Grade`,
                        start_year: `required||Education ${edu_indx + 1}: Start Year`
                    };
                    const validationEdu = ValidationHelper.validation(eduDetails, eduValidationRules).error;
                    if(validationEdu.length){
                        errorDetails = errorDetails.concat(validationEdu);
                    }

                    // if(!eduDetails.institute_name){
                    //     errorDetails.push(`Education ${edu_indx + 1}: College/Institution Name is Required`);
                    // }
                    // if(!eduDetails.program_name){
                    //     errorDetails.push(`Education ${edu_indx + 1}: Name of the Program is Required`);
                    // }
                    // if(!eduDetails.achievement){
                    //     errorDetails.push(`Education ${edu_indx + 1}: Key Achievement or Grade is Required`);
                    // }
                    // if(eduDetails.achievement.length > 150){
                    //     errorDetails.push(`Education ${edu_indx + 1}: Key Achievement or Grade should be less than 150 characters`);
                    // }
                    // // if(eduDetails.start_month === ''){
                    // //     errorDetails.push(`Education ${edu_indx + 1}: Start Month is Required`);
                    // // }
                    // if(eduDetails.start_year === ''){
                    //     errorDetails.push(`Education ${edu_indx + 1}: Start Year is Required`);
                    // }
                    //End Data
                    if(eduDetails.is_present === false){
                        // if(eduDetails.end_month === ''){
                        //     errorDetails.push(`Education ${edu_indx + 1}: End Month is Required`);
                        // }
                        if(eduDetails.end_year === ''){
                            errorDetails.push(`Education ${edu_indx + 1}: End Year is Required`);
                        }else if (eduDetails.start_year !== '' && eduDetails.start_year.id > eduDetails.end_year.id){
                            errorDetails.push(`Education ${edu_indx + 1}: Start Year cannot be greater than End Year`);
                        }
                    }
                    if(!errorDetails.length){
                        var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
                        apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
                        apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
                        apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
                        apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
                        // apiFormData.append(`education[${edu_indx}][end_year]`, eduDetails.end_year.id);
                        apiFormData.append(`education[${edu_indx}][end_year]`, eduEndYear);
                        apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
                        // eduData.push({...eduDetails,
                        //             // start_month: eduDetails.start_month.id,
                        //             start_year: eduDetails.start_year.id,
                        //             // end_month: eduDetails.end_month || eduDetails.end_month.id,
                        //             end_year: eduDetails.end_year || eduDetails.end_year.id
                        //         });
                    }
                }
            });
            if(!isEduData){
                errorDetails.push(`Atleast 1 Education Detail Required`);
            }
            //Award
            // var awardData = [];
            initialFormData.award.forEach((award, award_indx) => {
                var awardDetails = fieldFormData.award[award_indx];
                if(awardDetails.institute_name || awardDetails.award_name || awardDetails.why_awarded){
                    const awardValidationRules = {
                        institute_name: `required|max:100||Honors and Awards ${award_indx + 1}: Institution Name`,
                        award_name: `required|max:100||Honors and Awards ${award_indx + 1}: Name of the Award`,
                        why_awarded: `optional|max:100||Honors and Awards ${award_indx + 1}: Why was this awarded to you?`,
                        year: `required||Honors and Awards ${award_indx + 1}: Year`
                    };
                    const validationAward = ValidationHelper.validation(awardDetails, awardValidationRules).error;
                    if(validationAward.length){
                        errorDetails = errorDetails.concat(validationAward);
                    }

                    // if(!awardDetails.institute_name){
                    //     errorDetails.push(`Honors and Awards ${award_indx + 1}: Institution Name is Required`);
                    // }
                    // if(!awardDetails.award_name){
                    //     errorDetails.push(`Honors and Awards ${award_indx + 1}: Name of the Award is Required`);
                    // }
                    // if(!awardDetails.why_awarded){
                    //     errorDetails.push(`Honors and Awards ${award_indx + 1}: Why was this awarded to you? is Required`);
                    // }
                    // if(awardDetails.why_awarded.length > 100){
                    //     errorDetails.push(`Honors and Awards ${award_indx + 1}: Why was this awarded to you? should be less than 100 characters`);
                    // }
                    // if(awardDetails.year === ''){
                    //     errorDetails.push(`Honors and Awards ${award_indx + 1}: Year is Required`);
                    // }
                    if(!errorDetails.length){
                        apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
                        apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
                        apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
                        apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
                        // awardData.push({...awardDetails,
                        //             year: awardDetails.year || awardDetails.year.id
                        //         });
                    }
                }
            });
            // Unique Skills
            var passionData = [];
            initialFormData.passion.forEach((passion, passion_indx) => {
                var passionDetails = fieldFormData.passion[passion_indx];
                if(passionDetails.passion_id !== ''){
                    if(passionData.indexOf(passionDetails.passion_id.id) !== -1){
                        errorDetails.push(`Unique Skills: 2 Passion Scores cannot be same`);
                    }
                    passionData.push(passionDetails.passion_id.id);
                    apiFormData.append(`passion[${passion_indx}]`, passionDetails.passion_id.id);
                }
            });
            // if(passionData.length > 0 && passionData.length < 3){
            //     errorDetails.push(`Unique Skills: All 3 Passion Scores Required`);
            // }
            // IFSPD Program and Year
            if(fieldFormData.ifspd_program_id === ''){
                errorDetails.push(`IFSPD Program is Required`);
            }else{
                apiFormData.append(`ifspd_program_id`, fieldFormData.ifspd_program_id.id);
            }
            if(fieldFormData.ifspd_program_year === ''){
                errorDetails.push(`IFSPD Program Year is Required`);
            }else{
                apiFormData.append(`ifspd_program_year`, fieldFormData.ifspd_program_year.id);
            }
            
            if(errorDetails.length > 0){
                setErrorData(errorDetails);
                setIsProcess(false);
            }else{
                //Send Data to Server
                delete fieldFormData.education;
                delete fieldFormData.experience;
                delete fieldFormData.award;
                delete fieldFormData.passion;
                delete fieldFormData.ifspd_program_id;
                delete fieldFormData.ifspd_program_year;
                apiFormData.append('profile_pic', previewPicImg.data, 'Profile Pic');
                // apiFormData.append('general_data', fieldFormData);
                for (var key in fieldFormData) {
                    if (fieldFormData.hasOwnProperty(key)) {
                        apiFormData.append(key, fieldFormData[key]);
                    }
                }
                // apiFormData.append('experience', JSON.stringify(expData));
                // apiFormData.append('education', eduData);
                // apiFormData.append('award', awardData);
                // apiFormData.append('passion', passionData);
                // const apiFormData = {...fieldFormData,
                //                     experience: expData,
                //                     education: eduData,
                //                     award: awardData,
                //                     passion: passionData
                //                 };
                const newFellow = await post_api_call('new-fellow', apiFormData);
				if(newFellow.status === 200){
                    setIsSuccess(true);
                    // setIsSuccess(false);//delete
                    // setIsProcess(false);//delete
                }else{
                    if(typeof newFellow.message !== "undefined"){
                        setErrorData([newFellow.message]);
                    }
                    setIsProcess(false);
                }
            }
        }catch(e){
            console.log(e);
            setErrorData(['Something Went Wrong, Please Try After Some Time']);
            setIsProcess(false);
        }
    }

    return (
        <main id="main">
            <section className="team-sec pt-0">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Fellow Profile Form</h1>
                    </div>
                    
                    <div className="row justify-content-center team-detail m-1">
                    {!isSuccess
                    ?
                        <div className="col-md-10 col-12 col-sm-12 card p-3">
                            <p>Your IFSPD profile page is not a listing of your bio data like LinkedIn. Rather it is a showcase of your biggest achievements to elevate your profile, so that you differentiate and stand out from the crowd. Fill up the following details with this context in mind.</p>
                            <p><b>PROFILE</b></p>
                            <form className="profile-add-form" autoComplete="off">
                            <ol>
                                <li className="pb-3">
                                    <b>Upload your Photograph<em>*</em></b> (max 200KB):<br/>
                                    <div className="upload-img text-center">
                                        <div className="m-3">
                                            {/* <img src={require("./../../assets/images/user.png")} alt="User Logo" className="img-thumbnail" /> */}
                                            <img src={previewPicImg !== null ? previewPicImg.src : picIcon} alt="User Logo" className="img-thumbnail" />
                                        </div>
                                        
                                        <div className="btn btn-light btn-outline-primary">
                                            <span>Upload Your photo</span>
                                            <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e)} accept="image/x-png,image/jpg,image/jpeg" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <b>Your Full Name:</b><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                        <tr>
                                            <td><input className="form-control" placeholder="First Name*" name="first_name" autoComplete="off" value={formData.first_name} onChange={(e) => updateInput('input', 'first_name', e.target.value)}/></td>
                                            <td><input className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" value={formData.last_name} onChange={(e) => updateInput('input', 'last_name', e.target.value)}/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>General Details:</b><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>LinkedIn Profile:</th>
                                                <td><input className="form-control" name="linkedin_url" value={formData.linkedin_url} onChange={(e) => updateInput('input', 'linkedin_url', e.target.value)} /></td>
                                            </tr>
                                            <tr>
                                                <th>Country<em>*</em>:</th>
                                                <td>
                                                    {/* <select className="form-select w-25 me-2" name="country" autoComplete="off">
                                                        <option value="">Select Country</option>
                                                        {countryData.map((country_1, count_1) => (
                                                            <option key={`country_gen_1_${count_1}`} value={country_1.id}>{country_1.name}</option>
                                                        ))}
                                                    </select> */}
                                                    <div className="w-200p">
                                                    <Select
                                                        options={countryData} 
                                                        defaultValue={formData.country_id}
                                                        label={"Select Country"}
                                                        onChange={(value) => updateInput('select-react', 'country_id', value)}
                                                        getOptionLabel ={(option) => option.name}
                                                        getOptionValue ={(option) => option.id}
                                                        styles={RSstandardStyle}
                                                        menuPlacement="auto"
                                                        menuPosition={'fixed'}
                                                        // menuPortalTarget={document.querySelector('body')}
                                                        // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                        // className="is-valid"
                                                    />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Date of Birth<em>*</em>:</th>
                                                <td><input className="form-control w-200p" type="date" name="dob" max={`${currentYear-10}-12-31`} value={formData.dob} onChange={(e) => updateInput('input', 'dob', e.target.value)} style={{color: formData.dob ? 'black' : '#808080'}}/></td>
                                            </tr>
                                            <tr>
                                                <th>Email<em>*</em>:</th>
                                                <td><input className="form-control" name="email" autoComplete="off" value={formData.email} onChange={(e) => updateInput('input', 'email', e.target.value)}/></td>
                                            </tr>
                                            <tr>
                                                <th>Mobile with Country Code<em>*</em>:</th>
                                                <td className="d-flex">

                                                    <div className="w-200p">
                                                    <Select
                                                        options={countryData} 
                                                        defaultValue={formData.phone_code_id}
                                                        label={"Select Country Code"}
                                                        onChange={(value) => updateInput('select-react', 'phone_code_id', value)}
                                                        getOptionLabel ={(option) => `+${option.phone_code}`}
                                                        getOptionValue ={(option) => option.id}
                                                        menuPlacement="auto"
                                                        menuPosition={'fixed'}
                                                        styles={RSstandardStyle}
                                                    />
                                                    </div>
                                                    {/* <select className="form-select w-50 me-2" name="country_code">
                                                        <option value="">Select Country Code</option>
                                                        {countryData.map((country_2, count_2) => (
                                                            <option key={`country_gen_2_${count_2}`} value={country_2.id}>+{country_2.phone_code}</option>
                                                        ))}
                                                    </select> */}
                                                    <input className="form-control ms-1" name="mobile_number" placeholder="Mobile Number" value={formData.mobile_number} onChange={(e) => updateInput('input', 'mobile_number', e.target.value)}/>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <th>Gender<em>*</em>:</th>
                                                <td>
                                                <div className="w-200p">
                                                    <Select
                                                        options={genderData} 
                                                        defaultValue={formData.gender}
                                                        label={"Select Gender"}
                                                        onChange={(value) => updateInput('select-react', 'gender', value)}
                                                        getOptionLabel ={(option) => option.name}
                                                        getOptionValue ={(option) => option.id}
                                                        menuPlacement="auto"
                                                        menuPosition={'fixed'}
                                                        styles={RSstandardStyle}
                                                    />
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    {/* <b>A Headline for your Profile:</b> Maximum 15 words, that will capture who you are and your current designation.<br/><br/> */}
                                    <b>A Headline for your Profile:</b> Maximum 15 words, that will capture who you are.<br/><br/>
                                    <span><i>Example 1: Social Scientist</i></span><br/>
                                    <span><i>Example 2: Peace Builder</i></span><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Who you are<em>*</em>:</th>
                                                <td><input className="form-control" name="position" value={formData.position} onChange={(e) => updateInput('input', 'position', e.target.value)}/></td>
                                            </tr>
                                            {/* <tr>
                                                <th>Your current designation, if any:</th>
                                                <td><input className="form-control" name="designation" value={formData.designation} onChange={(e) => updateInput('input', 'designation', e.target.value)}/></td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>A Short Profile of Yourself In 3 Sentences:</b><br/><br/>
                                    <span><i>Example 1: Jane Doe is a 10th grade student at the Links Global School. She is an ace debater who has won prizes in several interschool competitions. She is passionate about sustainable development and has participated in primary research on the topic ‘Improving Sustainable Living in Indian Villages’.</i></span><br/><br/>
                                    <span><i>Example 2: John Doe has 5 years of experience and currently a researcher in Economics at the University of Helsinki. Prior to this, he worked in Sealand Corp as the Executive Assistant to the CEO. He has done his masters in Economics from the University of Helsinki with a scholarship.</i></span><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>A headline of who you are<em>*</em>:</th>
                                                <td className="w-50"><textarea className="form-control" name="headline" value={formData.headline} onChange={(e) => updateInput('textarea', 'headline', e.target.value)}/></td>
                                            </tr>
                                            <tr>
                                                <th>A sentence about your most important work experience, if any:</th>
                                                <td><textarea className="form-control" name="imp_exp" value={formData.imp_experience} onChange={(e) => updateInput('textarea', 'imp_experience', e.target.value)}/></td>
                                            </tr>
                                            <tr>
                                                <th>A sentence about your most important education, if any:</th>
                                                <td><textarea className="form-control" name="imp_edu" value={formData.imp_education} onChange={(e) => updateInput('textarea', 'imp_education', e.target.value)}/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>Work Experience:</b> Unlike normal biodata or LinkedIn, which lists all experience, IFSPD showcases only your key and most important work experiences. So, select the most important and provide these details.<br/><br/>
                                    <span><i>Example 1: <b>Designation:</b> Head, Strategy. <b>Organisation Name:</b> Sealand Corp. <b>Key Responsibility/Achievement:</b> Enabled the organization to grow 100% year on year. <b>Date:</b> 2022 to Present.</i></span><br/><br/>
                                    <span><i>Example 2: <b>Designation:</b> Intern. <b>Organisation Name:</b> Sealand Corp. <b>Key Responsibility/Achievement:</b> Collected and analyzed data from 100 respondents. <b>Date:</b> 2024 to Present.</i></span><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered table">
                                        <thead className="text-center">
                                            <tr>
                                                <th rowSpan={2}></th>
                                                <th rowSpan={2}>Designation</th>
                                                <th rowSpan={2}>Organization Name</th>
                                                <th rowSpan={2}>1 or 2 Key Responsibilities or Achievements</th>
                                                <th colSpan={2}>Date</th>
                                            </tr>
                                            <tr>
                                                <th>Start</th>
                                                <th>End</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {initialFormData.experience.map((exp_d, index) => (
                                                <tr key={`experience_${index}`}>
                                                    <td><b>Work Experience {index+1}</b></td>
                                                    <td><textarea className="form-control" name={`exp_desig_${index}`} rows={4} value={formData.experience[index].designation} onChange={(e) => updateInput('textarea', 'designation', e.target.value, 'array', 'experience', index)}/></td>
                                                    <td><textarea className="form-control" name={`exp_name_${index}`} rows={4} value={formData.experience[index].organization} onChange={(e) => updateInput('textarea', 'organization', e.target.value, 'array', 'experience', index)}/></td>
                                                    <td><textarea className="form-control" name={`exp_achiev_${index}`} rows={4} value={formData.experience[index].achievement} onChange={(e) => updateInput('textarea', 'achievement', e.target.value, 'array', 'experience', index)}/></td>
                                                    <td>
                                                        <div>
                                                        {/* <div className="w-110p mb-2">
                                                            <Select
                                                                options={monthData} 
                                                                defaultValue={formData.experience[index].start_month}
                                                                label={"Select Month"}
                                                                onChange={(value) => updateInput('select-react', 'start_month', value, 'array', 'experience', index)}
                                                                getOptionLabel ={(option) => option.month}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div> */}
                                                        <div className="w-110p">
                                                            <Select
                                                                options={yearData} 
                                                                defaultValue={formData.experience[index].start_year}
                                                                label={"Select Year"}
                                                                onChange={(value) => updateInput('select-react', 'start_year', value, 'array', 'experience', index)}
                                                                getOptionLabel ={(option) => option.year}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div>
                                                            {/* <select className="form-select mb-2" name={`exp_start_month_${index}`} value={formData.experience[index].start_month} onChange={(e) => updateInput('select', 'start_month', e.target.value, 'array', 'experience', index)}>
                                                                <option value="" disabled hidden>Month</option>
                                                                {monthData.map((month, sm_index)=>(
                                                                    <option value={month.value} key={`exp_${index}_mont_start${sm_index}`}>{month.month}</option>
                                                                ))}
                                                            </select>
                                                            <select className="form-select" name={`exp_start_year_${index}`} value={formData.experience[index].start_year} onChange={(e) => updateInput('select', 'start_year', e.target.value, 'array', 'experience', index)}>
                                                                <option value="" disabled hidden>Year</option>
                                                                {yearList(`exp_start_year_${index}`)}
                                                            </select> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {!formData.experience[index].is_present && (
                                                            <>
                                                            {/* <div className="w-110p mb-2">
                                                                <Select
                                                                    options={monthData} 
                                                                    defaultValue={formData.experience[index].end_month}
                                                                    label={"Select Month"}
                                                                    onChange={(value) => updateInput('select-react', 'end_month', value, 'array', 'experience', index)}
                                                                    getOptionLabel ={(option) => option.month}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div> */}
                                                            <div className="w-110p">
                                                                <Select
                                                                    options={yearData} 
                                                                    defaultValue={formData.experience[index].end_year}
                                                                    label={"Select Year"}
                                                                    onChange={(value) => updateInput('select-react', 'end_year', value, 'array', 'experience', index)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div><br/>
                                                            {/* <select className="form-select mb-2" name={`exp_end_month_${index}`} value={formData.experience[index].end_month} onChange={(e) => updateInput('select', 'end_month', e.target.value, 'array', 'experience', index)}>
                                                                <option value="" disabled hidden>Month</option>
                                                                {monthData.map((month, em_index)=>(
                                                                    <option value={month.value} key={`exp_${index}_mont_end${em_index}`}>{month.month}</option>
                                                                ))}
                                                            </select>
                                                            <select className="form-select" name={`exp_end_year_${index}`} value={formData.experience[index].end_year} onChange={(e) => updateInput('select', 'end_year', e.target.value, 'array', 'experience', index)}>
                                                                <option value="" disabled hidden>Year</option>
                                                                {yearList(`exp_end_year_${index}`)}
                                                            </select> */}
                                                            </>
                                                        )}
                                                        <input className="form-check-input" type="checkbox" value="" id={`exp_present_${index}`} checked={formData.experience[index].is_present} onChange={(e) => updateInput('checkbox', 'is_present', e.target.checked, 'array', 'experience', index)}/>
                                                        <label className="form-check-label" htmlFor={`exp_present_${index}`}>&nbsp;Present</label>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                            {/* <tr>
                                                <td><b>Work Experience 2</b></td>
                                                <td><textarea className="form-control" name="exp_desig_2" rows={4} value={formData.experience[0].designation} onChange={(e) => updateInput('textarea', 'designation', e.target.value, 'array', 'experience', 0)}/></td>
                                                <td><textarea className="form-control" name="exp_name_2" rows={4} value={formData.experience[0].organization} onChange={(e) => updateInput('textarea', 'organization', e.target.value, 'array', 'experience', 0)}/></td>
                                                <td><textarea className="form-control" name="exp_achiev_2" rows={4} value={formData.experience[0].achievement} onChange={(e) => updateInput('textarea', 'achievement', e.target.value, 'array', 'experience', 0)}/></td>
                                                <td>
                                                    <div>
                                                        <select className="form-select mb-2" name="exp_start_month_2" value={formData.experience[0].start_month} onChange={(e) => updateInput('select', 'start_month', e.target.value, 'array', 'experience', 0)}>
                                                            <option value="" disabled hidden>Month</option>
                                                            {monthData.map((month, index)=>(
                                                                <option value={month.value} key={`exp_2_mont_start${index}`}>{month.month}</option>
                                                            ))}
                                                        </select>
                                                        <select className="form-select" name="exp_start_year_2" value={formData.experience[0].start_year} onChange={(e) => updateInput('select', 'start_year', e.target.value, 'array', 'experience', 0)}>
                                                            <option value="" disabled hidden>Year</option>
                                                            {yearList('exp_start_year_2')}
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    {!formData.experience[0].is_present && (
                                                        <>
                                                        <select className="form-select mb-2" name="exp_end_month_2" value={formData.experience[0].end_month} onChange={(e) => updateInput('select', 'end_month', e.target.value, 'array', 'experience', 0)}>
                                                            <option value="" disabled hidden>Month</option>
                                                            {monthData.map((month, index)=>(
                                                                <option value={month.value} key={`exp_2_mont_end${index}`}>{month.month}</option>
                                                            ))}
                                                        </select>
                                                        <select className="form-select" name="exp_end_year_2" value={formData.experience[0].end_year} onChange={(e) => updateInput('select', 'end_year', e.target.value, 'array', 'experience', 0)}>
                                                            <option value="" disabled hidden>Year</option>
                                                            {yearList('exp_end_year_2')}
                                                        </select>
                                                        </>
                                                    )}<br/>
                                                    <input className="form-check-input" type="checkbox" value="" id="exp_present_2" checked={formData.experience[0].is_present} onChange={(e) => updateInput('checkbox', 'is_present', e.target.checked, 'array', 'experience', 0)}/>
                                                    <label className="form-check-label" htmlFor="exp_present_2">&nbsp;Present</label>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>Education<em>*</em>:</b> Unlike normal biodata or LinkedIn, which lists all education, IFSPD showcases only your key and most important ones. So, select the most important and provide these details.<br/><br/>

                                    <span><i>Example 1: <b>College/Institution Name:</b> Yokohama University. <b>Name of the Program:</b> Masters in Economics. <b>Key Achievement or Grade:</b> Distinction. Among top 1 percentile. <b>Date:</b> 2022 to Present.</i></span><br/><br/>
                                    <span><i>Example 2: <b>College/Institution Name:</b> IFSPD. <b>Name of the Program:</b> Presidential Fellow. <b>Key Achievement or Grade:</b> Overall best performer in Cohort 10. <b>Date:</b> 2022.</i></span><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered table">
                                        <thead className="text-center">
                                            <tr>
                                                <th rowSpan={2} style={{width: "100px"}}></th>
                                                <th rowSpan={2}>College/Institution Name</th>
                                                <th rowSpan={2}>Name of the Program</th>
                                                <th rowSpan={2}>Key Achievement or Grade, if any</th>
                                                <th colSpan={2}>Date</th>
                                            </tr>
                                            <tr>
                                                <th>Start</th>
                                                <th>End</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {initialFormData.education.map((edu_d, edu_index) => (
                                            <tr key={`education${edu_index}`}>
                                                <td><b>Education {edu_index+1}</b></td>
                                                <td><input className="form-control" name={`edu_clg_${edu_index}`} value={formData.education[edu_index].institute_name} onChange={(e) => updateInput('input', 'institute_name', e.target.value, 'array', 'education', edu_index)}/></td>
                                                <td><input className="form-control" name={`edu_program_${edu_index}`} value={formData.education[edu_index].program_name} onChange={(e) => updateInput('input', 'program_name', e.target.value, 'array', 'education', edu_index)}/></td>
                                                <td><input className="form-control" name={`edu_achieve_${edu_index}`} value={formData.education[edu_index].achievement} onChange={(e) => updateInput('input', 'achievement', e.target.value, 'array', 'education', edu_index)}/></td>
                                                <td>
                                                    <div>
                                                    {/* <div className="w-110p mb-2">
                                                            <Select
                                                                options={monthData} 
                                                                defaultValue={formData.education[edu_index].start_month}
                                                                label={"Select Month"}
                                                                onChange={(value) => updateInput('select-react', 'start_month', value, 'array', 'education', edu_index)}
                                                                getOptionLabel ={(option) => option.month}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div> */}
                                                        <div className="w-110p">
                                                            <Select
                                                                options={yearData} 
                                                                defaultValue={formData.education[edu_index].start_year}
                                                                label={"Select Year"}
                                                                onChange={(value) => updateInput('select-react', 'start_year', value, 'array', 'education', edu_index)}
                                                                getOptionLabel ={(option) => option.year}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div>
                                                        {/* <select className="form-select mb-2" name={`edu_start_month_${edu_index}`} value={formData.education[edu_index].start_month} onChange={(e) => updateInput('select', 'start_month', e.target.value, 'array', 'education', edu_index)}>
                                                            <option value="" disabled hidden>Month</option>
                                                            {monthData.map((month, index)=>(
                                                                <option value={month.value} key={`edu_${edu_index}_mont_start${index}`}>{month.month}</option>
                                                            ))}
                                                        </select>
                                                        <select className="form-select" name={`edu_start_year_${edu_index}`} value={formData.education[edu_index].start_year} onChange={(e) => updateInput('select', 'start_year', e.target.value, 'array', 'education', edu_index)}>
                                                            <option value="" disabled hidden>Year</option>
                                                            {yearList(`edu_start_year_${edu_index}`)}
                                                        </select> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    {!formData.education[edu_index].is_present && (
                                                        <>
                                                        {/* <div className="w-110p mb-2">
                                                            <Select
                                                                options={monthData} 
                                                                defaultValue={formData.education[edu_index].end_month}
                                                                label={"Select Month"}
                                                                onChange={(value) => updateInput('select-react', 'end_month', value, 'array', 'education', edu_index)}
                                                                getOptionLabel ={(option) => option.month}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div> */}
                                                        <div className="w-110p">
                                                            <Select
                                                                options={yearData} 
                                                                defaultValue={formData.education[edu_index].end_year}
                                                                label={"Select Year"}
                                                                onChange={(value) => updateInput('select-react', 'end_year', value, 'array', 'education', edu_index)}
                                                                getOptionLabel ={(option) => option.year}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                        </div><br/>
                                                        {/* <select className="form-select mb-2" name={`edu_end_month_${edu_index}`} value={formData.education[edu_index].end_month} onChange={(e) => updateInput('select', 'end_month', e.target.value, 'array', 'education', edu_index)}>
                                                            <option value="" disabled hidden>Month</option>
                                                            {monthData.map((month, index)=>(
                                                                <option value={month.value} key={`edu_${edu_index}_mont_end${index}`}>{month.month}</option>
                                                            ))}
                                                        </select>
                                                        <select className="form-select" name={`edu_end_year_${edu_index}`} value={formData.education[edu_index].end_year} onChange={(e) => updateInput('select', 'end_year', e.target.value, 'array', 'education', edu_index)}>
                                                            <option value="" disabled hidden>Year</option>
                                                            {yearList(`edu_end_year_${edu_index}`)}
                                                        </select> */}
                                                        </>
                                                    )}
                                                    <input className="form-check-input" type="checkbox" id={`edu_present_${edu_index}`} value={formData.education[edu_index].is_present} checked={formData.education[edu_index].is_present} onChange={(e) => updateInput('checkbox', 'is_present', e.target.checked, 'array', 'education', edu_index)} />
                                                    <label className="form-check-label" htmlFor={`edu_present_${edu_index}`}>&nbsp;Present</label>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>Honors and Awards:</b> Unlike normal biodata or LinkedIn, which lists everything you have achieved, IFSPD showcases only your key and most important awards. So, select the most important and provide these details.<br/><br/>
                                    <span><i>Example 1: <b>Institution Name:</b> Government of Libya. <b>Name of the Award:</b> Youth Ambassador. <b>Why was this Awarded to You:</b> For upliftment and empowerment of youth. <b>Date:</b> 2023.</i></span><br/><br/>
                                    <span><i>Example 2: <b>Institution Name:</b> Yokohama University. <b>Name of the Award:</b> Best Outgoing Student. <b>Why was this Awarded to You:</b> Overall best performance. <b>Date:</b> 2023.</i></span><br/><br/> 
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Institution Name</th>
                                                <th>Name of the Award</th>
                                                <th>Why was this awarded to you?</th>
                                                <th>Date (Year)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {initialFormData.award.map((award_d, award_index) => (
                                            <tr key={`award${award_index}`}>
                                                <td><b>Honor or Award {award_index + 1}</b></td>
                                                <td><input className="form-control" name={`award_institute_${award_index}`} value={formData.award[award_index].institute_name} onChange={(e) => updateInput('input', 'institute_name', e.target.value, 'array', 'award', award_index)} /></td>
                                                <td><input className="form-control" name={`award_name${award_index}`} value={formData.award[award_index].award_name} onChange={(e) => updateInput('input', 'award_name', e.target.value, 'array', 'award', award_index)} /></td>
                                                <td><input className="form-control" name={`award_why${award_index}`} value={formData.award[award_index].why_awarded} onChange={(e) => updateInput('input', 'why_awarded', e.target.value, 'array', 'award', award_index)} /></td>
                                                <td>
                                                    <div className="w-110p">
                                                        <Select
                                                            options={yearData} 
                                                            defaultValue={formData.award[award_index].year}
                                                            label={"Select Year"}
                                                            onChange={(value) => updateInput('select-react', 'year', value, 'array', 'award', award_index)}
                                                            getOptionLabel ={(option) => option.year}
                                                            getOptionValue ={(option) => option.id}
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                    {/* <select className="form-select" name={`award_year${award_index}`} value={formData.award[award_index].year} onChange={(e) => updateInput('select', 'year', e.target.value, 'array', 'award', award_index)}>
                                                        <option value="" disabled hidden>Year</option>
                                                        {yearList(`award_year${award_index}`)}
                                                    </select> */}
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>Unique Skills:</b> Refer to your IFSPD Unique Skills & Competencies Report and select your top 3 passion scores.<br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                            {initialFormData.passion.map((passion_d, passion_index) => (
                                            <tr key={`passion${passion_index}`}>
                                                <th>Passion Score Top {passion_index + 1}</th>
                                                <td>
                                                    <div className="w-200p">
                                                        <Select 
                                                            options={passionData} 
                                                            defaultValue={formData.passion[passion_index].passion_id}
                                                            label={"Select Passion Score"}
                                                            onChange={(value) => updateInput('select-react', 'passion_id', value, 'array', 'passion', passion_index)}
                                                            getOptionLabel ={(option) => option.title}
                                                            getOptionValue ={(option) => option.id}
                                                            // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                            // className="is-valid"
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                    {/* <select className="form-select" name="passion_score_1">
                                                        <option value="">Select Passion Score</option>
                                                        {passionData.map((passion_1, ind_p_1) => (
                                                            <option key={`passion_1_${ind_p_1}`} value={passion_1.id}>{passion_1.title}</option>
                                                        ))}
                                                    </select> */}
                                                </td>
                                            </tr>
                                            ))}
                                            {/* <tr>
                                                <th>Passion Score Top 2</th>
                                                <td>
                                                    <div className="w-200p">
                                                    <Select 
                                                        options={passionData} 
                                                        defaultValue={""}
                                                        label={"Select Passion Scorer"}
                                                        // isMulti
                                                        // onChange={(value) => props.updateInput(formName, field.type, field.name, value)}
                                                        getOptionLabel ={(option) => option.title}
                                                        getOptionValue ={(option) => option.id}
                                                        // menuPortalTarget={document.querySelector('body')}
                                                        // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                        // className="is-valid"
                                                        menuPlacement="auto"
                                                        menuPosition={'fixed'}
                                                    />
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                                <li>
                                    <b>Which IFSPD program did you complete and in Which year?<em>*</em>:</b><br/><br/>
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="w-30vw">
                                                        <Select 
                                                            options={programData} 
                                                            defaultValue={formData.ifspd_program_id}
                                                            placeholder={"Select Program"}
                                                            onChange={(value) => updateInput('select-react-single', 'ifspd_program_id', value)}
                                                            getOptionLabel ={(option) => option.program_name}
                                                            getOptionValue ={(option) => option.id}
                                                            // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                            // className="is-valid"
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="w-30vw">
                                                        <Select 
                                                            options={programYearData} 
                                                            defaultValue={formData.ifspd_program_year}
                                                            placeholder={"Select Year"}
                                                            onChange={(value) => updateInput('select-react-single', 'ifspd_program_year', value)}
                                                            getOptionLabel ={(option) => option.year}
                                                            getOptionValue ={(option) => option.id}
                                                            // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                            // className="is-valid"
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </li>
                            </ol>
                            {/* Error */}
                            <div className="row justify-content-center">
                            <div className="col-md-8">
                            {errorData.length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>
                                    {errorData.map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                            </div>
                            {!isProcess
                                ?
                                    <p className="text-center"><button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => addFellow(e)}>Submit</button></p>
                                :
                                    <p className="text-center"><button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button></p>
                            }
                            </form>
                        </div>
                    :
                        <div className="col-md-10 col-12 col-sm-12 card p-3">
                            <div className="alert alert-success text-center mb-0">
                                Thank You Fellow ! Your details have been submitted successfully. Your profile will be Live soon.<br/>
                                <a href="/fellow-profiles"><button className="btn btn-primary bg-color-blue mt-3">Browse Other Fellows Profile</button></a>
                            </div>
                        </div>
                    }
                    </div>
                    {/* centered */}
                    <Modal show={isSuccess}>
                        <Modal.Header>
                            <Modal.Title>Thank You</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert alert-success text-center mb-0">
                                Thank You Fellow ! Your details have been submitted successfully. Your profile will be Live soon.
                                {/* <a href="/fellow-profiles" title="Fellow Profile"><button className="btn btn-primary bg-color-blue mt-3">Browse Other Fellows Profile</button></a> */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <a href="/fellow-profiles" title="Fellow Profile"><button className="btn btn-primary bg-color-blue mt-3">Browse Other Fellows Profile</button></a>
                        </Modal.Footer>
                    </Modal>
                    
                    {/* {isSuccess && (
                        <div
                            className="modal show"
                            style={{ display: 'block', position: 'initial' }}
                        >
                        <Modal.Dialog>
                          <Modal.Header closeButton>
                            <Modal.Title>Thank You</Modal.Title>
                          </Modal.Header>
                  
                          <Modal.Body>
                            <div className="alert alert-success text-center mb-0">
                                Thank You Fellow! Your details have been submitted successfully. Your profile will be Live soon.<br/>
                            </div>
                          </Modal.Body>
                  
                          <Modal.Footer>
                            <button className="btn btn-primary bg-color-blue mt-3">Browse Other Fellows Profile</button>
                          </Modal.Footer>
                        </Modal.Dialog>
                      </div>
                    )} */}
                </div>
            </section>
        </main>
    )    
}

export default FellowProfileForm;